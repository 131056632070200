import request from '@/utils/request';

export interface ResourceFeiShuDoc {
  documentId?: string;
  isPublished: number | boolean;
  isTop: number | boolean;
  id?: number;
  label?: string;
  isShowNew?: number | boolean;
  metaDesc?: string;
  metaKeyword?: string;
  productIds?: string; // 产品id(多个以英文';'隔开)
  resourceCategoryId: number;
  resourceTypeId: number;
  url?: string;
  resourceType?: number;
  documentCoverId?: number;
}

export function getClassifyList(data) {
  return request({
    url: `/api/resource/styleList`,
    method: 'post',
    data,
  });
}
export function getSearchParams(params) {
  return request({
    url: '/api/resource/getSearchParams',
    method: 'get',
    params,
  });
}
export function delClassify(data) {
  return request({
    url: `/api/resource/stylesDelete`,
    method: 'post',
    data,
  });
}
export function addClassify(data) {
  return request({
    url: `/api/resource/stylesAdd`,
    method: 'post',
    data,
  });
}
export function editClassify(data) {
  return request({
    url: `/api/resource/styleUpdate`,
    method: 'post',
    data,
  });
}
export function sortClassify(data) {
  return request(
    {
      url: `/api/resource/styleUpdateSort`,
      method: 'post',
      data,
    },
    true
  );
}
export function delType(data) {
  return request({
    url: `/api/resource/stylesTypeDelete`,
    method: 'post',
    data,
  });
}

//切换展示状态
export function changeStatus(data) {
  return request({
    url: `/api/resource/updateStyleStatus`,
    method: 'post',
    data,
  });
}

// 创建飞书云文档
export function createFeiShuDocument() {
  return request({
    url: `/api/resource/createFeiShuDocument`,
    method: 'post',
  });
}

// 保存飞书云文档组件编辑的文档资源信息
export function addFeiShuCloudDocumentResource(data: ResourceFeiShuDoc) {
  return request({
    url: `/api/resource/addFeiShuCloudDocumentResource`,
    method: 'post',
    data,
  });
}

// 编辑飞书云文档组件编辑的文档资源信息
export function editFeiShuCloudDocumentResource(data: ResourceFeiShuDoc) {
  return request({
    url: `/api/resource/editFeiShuCloudDocumentResource`,
    method: 'post',
    data,
  });
}

// pdf封面列表查询
export function getPdfCoverList() {
  return request({
    url: `/api/resource/cover/list`,
    method: 'get',
  });
}

// pdf封面批量更新
export function updatePdfCover(data) {
  return request({
    url: `/api/resource/cover/save`,
    method: 'post',
    data,
  });
}

// pdf预览
export function previewPdf(data) {
  return request({
    url: `/api/resource/document/pdfPreview`,
    method: 'post',
    data,
    responseType: 'blob',
  });
}
