const i18n = {
  'en-US': {
    'menu.exception': 'Exception page',
    'menu.exception.403': '403',
    'exception.result.403.description':
      'Access to this resource on the server is denied.',
    'exception.result.403.back': 'Back',
  },
  'zh-CN': {
    'menu.exception': '异常页',
    'menu.exception.403': '403',
    'exception.result.403.description': '对不起，您没有访问该资源的权限',
    'exception.result.403.back': '返回',
  },
};

export default i18n;
