export default [
  {
    title: '主多图',
    name: '主多图/视频 (Product Images/Videos)',
    componentName: 'ProductMedia',
    data: [],
    initValue: [],
    height: 1100,
    type: 14,
  },
  {
    title: '示例视频',
    name: '示例视频',
    componentName: 'DemonstrationVideo',
    data: [],
    initValue: [],
    height: 1100,
    type: 10,
  },
  {
    title: '产品特征',
    name: '产品特征',
    componentName: 'ProductLight',
    data: [],
    initValue: [],
    height: 620,
    type: 1,
  },
  {
    title: '产品描述',
    name: '产品描述',
    componentName: 'ProductVerb',
    data: { title: '', content: '' },
    initValue: { title: '', content: '' },
    height: 620,
    type: 2,
  },
  {
    title: '规格参数',
    name: '规格参数',
    componentName: 'ProductParameter',
    data: { list: [], weaken: { title: '', weakenList: [] }, remarks: '' },
    initValue: {
      list: [],
      weaken: { title: '', weakenList: [] },
      remarks: '',
    },
    height: 1160,
    type: 3,
  },
  {
    title: '产品亮点',
    name: '产品亮点',
    componentName: 'SoftwareLight',
    data: [],
    initValue: [],
    height: 620,
    type: 4,
  },
  {
    title: '产品质量检测',
    name: '产品质量检测 (Description)',
    componentName: 'SoftwareSpothights',
    data: [],
    initValue: [],
    height: 620,
    type: 5,
  },
  {
    title: '质量认证',
    name: '质量认证 (Quality Testing Program)',
    componentName: 'QualityCertify',
    data: [],
    initValue: [],
    height: 620,
    type: 6,
  },
  {
    title: '应用方案',
    name: '应用方案 (Connectivity Solutions)',
    componentName: 'ProductTree',
    data: [],
    initValue: [],
    height: 620,
    type: 7,
  },
  { title: '产品详情', name: '产品详情' },
  {
    title: '产品对比',
    name: '产品对比 (Product Comparison)',
    componentName: 'ProductContrast',
    data: [],
    initValue: [],
    height: 1560,
    type: 9,
  },
  // 详情对应的模版
  {
    title: 'FS01',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs01',
    moduleName: 'FS01',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS01_1',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs01_1',
    moduleName: 'FS01_1',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS02',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs02',
    moduleName: 'FS02',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS03',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs03',
    moduleName: 'FS03',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS03_1',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs03',
    moduleName: 'FS03_1',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS03_2',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs03',
    moduleName: 'FS03_2',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS05',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs05',
    moduleName: 'FS05',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS06',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs06',
    moduleName: 'FS06',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS07',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs07',
    moduleName: 'FS07',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS08',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs08',
    moduleName: 'FS08',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS09',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs09',
    moduleName: 'FS09',
    data: [],
    initValue: [],
    height: 756,
    type: 8,
  },
  {
    title: 'FS11',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs11',
    moduleName: 'FS11',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS011',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs011',
    moduleName: 'FS011',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS12_02',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs12_02',
    moduleName: 'FS12_02',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS13',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs13',
    moduleName: 'FS13',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS14',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs14',
    moduleName: 'FS14',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS15',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs15',
    moduleName: 'FS15',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS16',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs16',
    moduleName: 'FS16',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS19',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs19',
    moduleName: 'FS19',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS20',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs20',
    moduleName: 'FS20',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS21_01',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs21_01',
    moduleName: 'FS21_01',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS21_02',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs21_01',
    moduleName: 'FS21_02',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS22',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs22',
    moduleName: 'FS22',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS24',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs24',
    moduleName: 'FS24',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS24_01',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs24_01',
    moduleName: 'FS24_01',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS24_02',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs24_02',
    moduleName: 'FS24_02',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: 'FS25',
    name: '编辑 (Features)',
    componentName: 'FsTemplate/Fs25',
    moduleName: 'FS25',
    data: [],
    initValue: [],
    height: 620,
    type: 8,
  },
  {
    title: '文档资源',
    name: '文档资源',
    componentName: 'DocumentManage',
    data: [],
    initValue: [],
    height: 900,
    type: 15,
  },
];
