import React, { useState } from 'react';
import styles from './index.module.less';
import { Image } from '@arco-design/web-react';
import { IconDelete, IconSearch } from '@arco-design/web-react/icon';
type PropsType = {
  url: string;
  onDelete: () => void;
};

const CustomPreview: React.FC<PropsType> = ({ url, onDelete }) => {
  const [visible, setVisible] = useState(false);
  const preview = () => {
    setVisible(true);
  };
  return (
    <div className={styles['custom-view']}>
      <img src={url} alt="" />
      <div
        className={styles['custom-view-mask']}
        onClick={(event) => {
          // 阻止点击事件冒泡
          event.stopPropagation();
        }}
      >
        {/* 预览和删除图标 */}
        <IconSearch onClick={preview} />
        <IconDelete style={{ marginLeft: 16 }} onClick={onDelete} />
        <Image.Preview
          src={url}
          visible={visible}
          onVisibleChange={setVisible}
        />
      </div>
    </div>
  );
};

export default CustomPreview;
