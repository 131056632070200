/*
 * @Author: ajay
 * @Date: 2022-06-17 11:59:40
 * @LastEditors: ajay
 * @LastEditTime: 2022-09-09 10:29:01
 * @Description: 请填写简介
 */

import React, { useState, useRef, useContext } from 'react'
import styles from './style/index.module.less'
import { ProductsContext } from '../../index'
import classNames from 'classnames';



export default function ItemSpotlights(props: any) {
	const context = useContext(ProductsContext);
	const { data } = props;
	return (
		<div className={styles.products_attr_box} >
			{
				data ?(
					<div className={styles.item_spotlights_box} onClick={(e) => {
						e.stopPropagation(); context.openSiderBox({
							componentId: data.componentId,
							block: data.block || '',
							type: data.type,
						})
					}}>
						<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.item_spotlights_title])}>Product Highlights</div>
						{
							(data.data && data.data.contentList && data.data.contentList.length) ?
								(<div className={styles.item_spotlights_ctn}>
									{
										data.data.contentList.map((item, index) => {
											return <p className={styles.spotlights_item} key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
										})
									}
								</div>) : (
									<div className={styles.item_spotlights_empty}>
										产品特征内容区域
									</div>
								)
						}
					</div>
				) : null
			}

		</div>

	)
}

