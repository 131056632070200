class IndexDB {
  request: IDBOpenDBRequest;
  db: any;
  static data = {
    name: 'cmsDB',
    version: 1,
    table: 'cmsDBTable',
  };

  static getInstance() {
    if (!this.instance) {
      this.instance = new IndexDB(
        IndexDB.data.name,
        IndexDB.data.table,
        IndexDB.data.version
      );
    }
    return this.instance;
  }

  static instance: IndexDB;

  constructor(name: string, table: string, version: number) {
    if (!window.indexedDB) {
      alert('初始化数据库失败');
    }
    this.initDB(name, table, version);
  }

  // 初始化数据库
  initDB(name, table, version) {
    this.request = window.indexedDB.open(name, version);
    this.request.onupgradeneeded = function (e: IDBVersionChangeEvent) {
      const db = (e.target as any).result;
      if (!db.objectStoreNames.contains(table)) {
        db.createObjectStore(table, {
          keyPath: 'id',
        });
      }
    };
  }

  // 连接数据库
  async openDB(name, version) {
    this.request = window.indexedDB.open(name, version);
    return new Promise<IDBDatabase>((resolve, reject) => {
      this.request.onsuccess = (e) => {
        this.db = (e.target as any).result;
        resolve((e.target as any).result);
      };
      this.request.onerror = (event: Event) =>
        reject((event.target as any).error || '操作失败');
    });
  }

  // 打开仓库
  async getStore() {
    await this.openDB(IndexDB.data.name, IndexDB.data.version);
    const transaction = this.db.transaction(IndexDB.data.table, 'readwrite');
    const store = transaction.objectStore(IndexDB.data.table);
    return store;
  }

  // 写入数据
  async setItem(name, value) {
    try {
      const store = await this.getStore();
      const data = {
        id: name,
        value: value,
      };
      store.add(data);
    } catch (e) {
      alert(e);
    }
  }

  // 获取数据（异步）
  async getItem(value, callBack) {
    try {
      const store = await this.getStore();
      const request = store.get(value);
      request.onsuccess = function (e) {
        if (callBack && typeof callBack === 'function') {
          if (e.target.result) {
            callBack(e.target.result.value);
          } else {
            callBack('');
          }
        }
      };
    } catch (e) {
      alert(e);
    }
  }

  // 获取数据（同步）
  getItemSync(val) {
    return new Promise((resolve, reject) => {
      this.getItem(val, (res) => {
        resolve(res);
      });
    });
  }

  // 更新数据
  async putItem(name, value) {
    const store = await this.getStore();
    const request = store.put(name);
    request.onerror((e) => {
      console.error(e);
    });
  }
  // 删除数据
  async deleteItem(name) {
    const store = await this.getStore();
    store.delete(name);
  }
  // 清空表数据
  async clearTable() {
    const store = await this.getStore();
    store.clear();
  }

  // 关闭连接
  closeDB() {
    this.db.close();
  }
}
const INDEXDB = IndexDB.getInstance();
export default INDEXDB;
