import request from '@/utils/request';
export interface ResType {
    code:string;
    data:any;
    msg?:string
}

export function getRoleList(data){
    return request({
        url: '/api/platformRole/selectPageList',
        method: 'post',
        data
    });
}
//获取创建人列表
export function getCreateList(data){
    return request({
        url:'/api/platformRole/selectCreatedUserList',
        method:'post',
        data
    })
}
//删除角色
export function deleteRole(data){
    return request({
        url:'/api/platformRole/delete',
        method:'post',
        data
    },true)
}
//获取用户列表
export function getUserList(data){
    return request({
        url:'/api/personnel/management/page',
        method:'post',
        data
    })
}
//角色绑定人员
export function roleBindUser(data){
    return request({
        url:'/api/platformRole/addUser',
        method:'post',
        data
    },true)
}
//工号+姓名联想接口
export function nameLink(params){
    return request({
        url:'/api/personnel/management/list/user',
        method:'get',
        params
    })
}
//复制角色
export function copyRole(data){
    return request({
        url:'/api/platformRole/copy',
        method:'post',
        data
    })
}
//单个保存权限
// {
//     "languageId": 0,
//     "permissionIdList": [],
//     "roleId": 0
//   }
export function savePermission(data){
    return request({
        url:'/api/platformRole/savePermission',
        method:'post',
        data
    })
}
//批量保存权限
export function saveBatchPermission(data){
    return request({
        url:'/api/platformRole/saveBatchPermission',
        method:'post',
        data
    })
}
//获取单个权限信息
export function getSinglePermission(data){
    return request({
        url:'/api/platformRole/editPermission',
        method:'post',
        data
    })
}

//保存角色
export function saveRole(data){
    return request({
        url:'/api/platformRole/save',
        method:'post',
        data
    })
}
//重新角色下的人员列表
export function getUserListByRole(data){
    return request({
        url:'/api/platformRole/selectRoleUserPageList',
        method:'post',
        data
    })
}
//解绑角色下的人员
// "roleId": 0,
// "userIdList": []
export function unbindUser(data){
    return request({
        url:'/api/platformRole/deleteUser',
        method:'post',
        data
    })
}
//查询角色详情信息
export function getRoleDetail(data){
    return request({
        url:'/api/platformRole/selectRoleDetail',
        method:'post',
        data
    })
}
//详情页查询权限列表
export function getPermissionListByDetail(data){
    return request({
        url:'/api/platformRole/selectRolePermissionDetail',
        method:'post',
        data
    })
}

//更新用户权限信息
export function updateUserPermission(data){
    return request({
        url:'/api/auth/selectUserPermission',
        method:'post',
        data
    })
}
//查询职位列表+keyword
export function getPositionList(params){
    return request({
        url:'/api/personnel/management/list/job',
        method:'get',
        params
    })
}
//查询部门树
export function getDepartmentTree(data){
    return request({
        url:'/api/platformDepartment/selectTreeNodeList',
        method:'post',
        data
    })
}
