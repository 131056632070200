import {
  addFeiShuCloudDocumentResource,
  ResourceFeiShuDoc,
  editFeiShuCloudDocumentResource,
} from '@/api/resource/documentManager/documentManager';
import {
  Button,
  Cascader,
  Checkbox,
  Divider,
  Form,
  Input,
  Message,
  Radio,
  Spin,
  Switch,
  Upload,
} from '@arco-design/web-react';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { checkUpload } from '@/api/resource/documentManager/editManager';
import qs from 'query-string';
import styles from './style/index.module.less';
import api from '@/api/resource';
import { IconClose, IconPlus } from '@arco-design/web-react/icon';
import { AWSUpload } from '@/utils/aws';
import { fetchAsStreamToFile, getPath } from '@/utils/utils';
import useMainStation from '@/hooks/useMainStation';
import PdfView from '../PdfView';
interface Props {
  documentId: string;
  initValue: any;
  loading: boolean;
  resourceName: string;
  setResourceName: (name: string) => void;
}
const request = [
  addFeiShuCloudDocumentResource,
  editFeiShuCloudDocumentResource,
];
export default function EditContent(props: Props) {
  const [form] = Form.useForm();
  const { documentId, initValue, loading, resourceName, setResourceName } =
    props;
  const history = useHistory();
  const [isUploading, setUploading] = useState(false);
  const [isMainStation] = useMainStation();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initName, setInitName] = useState('');
  const formRef = useRef(null);
  const search = qs.parseUrl(window.location.href);
  const resourceUrl = process.env.REACT_APP_RESOURCE_URL;
  const { id, productId } = search.query;
  console.log(id, search, 'search');
  const [uploadList, setUploadList] = useState([]);
  const [successArr, setSuccessArr] = useState([]);
  const [options, setOptions] = useState([]);
  const defaultFormData: ResourceFeiShuDoc = {
    documentId: '',
    isPublished: false,
    isTop: false,
    label: '',
    isShowNew: false,
    metaDesc: '',
    metaKeyword: '',
    productIds: '',
    resourceCategoryId: undefined,
    resourceTypeId: undefined,
    url: '',
    resourceType: 2,
    documentCoverId: 0,
  };
  useEffect(() => {
    if (initValue) {
      console.log(initValue, 'initValue');
      getList(initValue.resourceType, initValue);
    } else {
      formRef.current.setFieldsValue(defaultFormData);
      getList(2);
    }
  }, [initValue]);
  useEffect(() => {
    if (resourceName === '​' || resourceName === '') {
      if (formRef.current.getFieldValue('metaKeyword')) return;
      formRef.current.setFieldValue('metaKeyword', undefined);
      return;
    }
    formRef.current.setFieldValue(
      'resourceName',
      resourceName.replaceAll('\n', '')
    );
    formRef.current.setFieldValue(
      'metaKeyword',
      resourceName.replaceAll('\n', '')
    );
  }, [resourceName]);
  useEffect(() => {
    if (productId) {
      formRef.current.setFieldsValue({
        productIds: productId,
        resourceType: 1,
      });
    }
  }, [productId]);
  async function check(list) {
    try {
      const res: ResObject = await checkUpload(list);
      if (res.code === '200') {
        return res.data;
      } else {
        Message.error(res.msg);
        setConfirmLoading(false);
        return [];
      }
    } catch (e) {
      Message.error(e.message);
      setConfirmLoading(false);
      return [];
    }
  }

  async function getFile(url, name) {
    const file = await fetchAsStreamToFile(url, name);
    return file;
  }

  const submit = async () => {
    if (resourceName.trim() === '' || resourceName.trim() === '​') {
      Message.error('文档标题不能为空');
      return;
    }
    // if (resourceName.length > 50) {
    //   Message.error('文档标题不能超过50个字符');
    //   return;
    // }
    try {
      console.log('submit', form.getFields());
      const validate = await formRef.current.validate();
      if (validate) {
        const params = formRef.current.getFields();
        setConfirmLoading(true);
        if (id) {
          params.id = id;
        }
        if (!params.productIds) {
          delete params.productIds;
        }
        params.resourceCategoryId = params.resource[0];
        params.resourceTypeId = params.resource[params.resource.length - 1];
        params.documentId = documentId;
        delete params.covers;
        if (uploadList.length === 0) {
          if (!resourceName || initName === resourceName || !params.url) {
            addRes(params);
            return;
          } else {
            const url = params.url.includes('.com')
              ? params.url
              : 'https://resource.fs.com/mall' + params.url;
            const file = await getFile(url, resourceName.trim());
            const body = {
              file,
              onSuccess: (res) => {
                successArr[0] = 1;
                setSuccessArr([...successArr]);
              },
              key: `${resourceUrl}/${resourceName.trim()}`,
              next: (res) => {
                // next(`mall/doc/${name}`, file);
              },
            };

            params.fileExtension = params.url.split('.').at(-1);

            uploadList.push(body);
          }
        }
        const urlList = await check([params]);
        if (urlList.length > 0) {
          uploadList.map((v, i) => {
            v.url = urlList[i];
            AWSUpload({
              ...v,
              key: 'mall' + urlList[i],
              next: () => {
                next(urlList[i], v.file);
              },
            });
            return v;
          });
          setUploadList(uploadList);
        } else {
          return;
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  function reset() {
    setUploadList([]);
    setSuccessArr([]);
    setInitName('');
    // setResourceName('');
  }
  function addRes(data) {
    request[id ? 1 : 0](data)
      .then((res: ResObject) => {
        setConfirmLoading(false);
        if (res.code === '200') {
          if (data.isPublished) {
            Message.success('您提交的文章已经发布成功!');
          } else {
            Message.success('您的文章编辑已保存成功!');
          }
          setResourceName('');
          history.push('/Resource/DocumentManager/EditManager');
        } else {
          Message.error(res.msg);
        }
        reset();
      })
      .finally(() => {
        setConfirmLoading(false);
        reset();
      });
  }
  useEffect(() => {
    const success =
      successArr.length &&
      successArr.length === uploadList.length &&
      successArr.every((v) => !!v);
    if (success) {
      const params = formRef.current.getFields();
      if (id) {
        params.id = id;
      }
      if (!params.productIds) {
        delete params.productIds;
      }
      params.resourceCategoryId = params.resource[0];
      params.resourceTypeId = params.resource[params.resource.length - 1];
      params.documentId = documentId;
      params.url = uploadList[0].url;
      addRes(params);
    }
  }, [successArr.length]);
  function beforeUpload(file) {
    const arr = [
      +![
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'image/bmp',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/zip',
        'application/x-zip-compressed',
        'application/pdf',
      ].includes(file.type),
      +/[\u4E00-\u9FA5]/.test(file.name),
    ];
    const err = [
      '只支持.pdf/.docx/.jpg/.jpeg/.png/.gif/.bmp/.zip格式',
      '只支持文件名称为英文的文件',
    ];
    if (arr.filter((v) => v).length >= 2) {
      Message.warning(
        '只支持.pdf/.docx/.jpg/.jpeg/.png/.gif/.bmp/.zip格式，且文件名称不能为中文'
      );
      return false;
    }
    if (arr.filter((v) => v).length === 1) {
      Message.warning(err[arr.findIndex((v) => v)]);
      return false;
    }
    setUploading(true);
    // const lists = [...list];
    // lists[i].loading = true;
    // setList(lists);
    // return true;
  }

  function slug(input) {
    if (!input || input.trim().length === 0) {
      return '';
    }
    // Replace whitespace with hyphens
    const nowhitespace = input.replace(/\s+/g, '-');
    // Normalize the string
    const normalized = nowhitespace.normalize('NFD');
    // Remove non-Latin characters
    const slug = normalized.replace(/[^a-zA-Z0-9-]/g, '');
    // Convert to lowercase

    return slug.toLowerCase();
  }
  function getName(resourceName, initValue) {
    if (resourceName !== '​') {
      return resourceName.replaceAll('\n', '');
    } else if (initValue && initValue.resourceName) {
      return initValue.resourceName;
    }
  }
  function customRequest(option) {
    // console.log(option,22222)
    const { onProgress, onError, onSuccess, file } = option;
    const date = new Date();
    const time = `${date.getFullYear()}${(date.getMonth() + 1 + '').padStart(
      2,
      '0'
    )}${(date.getDate() + '').padStart(2, '0')}${(
      date.getHours() + ''
    ).padStart(2, '0')}${(date.getMinutes() + '').padStart(2, '0')}${(
      date.getSeconds() + ''
    ).padStart(2, '0')}`;
    // const name = `${new Date().toLocaleString().replace(/[:\/ ]/g, '')}${Math.random().toString(36).slice(-6)}.${file.name.split('.').at(-1)}`;
    // const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
    const input = getName(resourceName, initValue);
    const name = `${slug(input)}.${file.name.split('.').at(-1)}`;
    const body = {
      file,
      onProgress,
      onSuccess: (res) => {
        onSuccess(res);
        successArr[0] = 1;
        setSuccessArr([...successArr]);
      },
      onError,
      key: `/${resourceUrl}/${name}`,
      next: (res) => {
        // next(`mall/doc/${name}`, file);
      },
    };
    // fs-lambda-edge-test
    // fs-community
    // AWSUpload(body);
    setUploadList([body]);
    setUploading(false);
    console.log(name, file, 'file');

    next(`/${resourceUrl}/${name}`, file);
  }
  function next(url, file) {
    setUploading(false);
    // imgInfo.imagesUrl = body.key
    // setImgInfo(imgInfo)
    console.log(
      file.name.slice(0, file.name.lastIndexOf('.') + 1),
      resourceName || initValue.resourceName,
      '456'
    );
    console.log(url, 'url');
    formRef.current.setFieldsValue({
      fileExtension: file.name.slice(file.name.lastIndexOf('.') + 1),
      url: url,
      resourceName: resourceName || initValue.resourceName,
      urlSize:
        file.size < 1024 * 1024
          ? (file.size / 1024).toFixed(2) + ' KB'
          : (file.size / (1024 * 1024)).toFixed(2) + ' MB',
    });
    // setFormData({
    //   ...formData,
    //   url: url,
    //   fileExtension: file.name.slice(file.name.lastIndexOf('.') + 1),
    //   urlSize:
    //     file.size < 1024 * 1024
    //       ? (file.size / 1024).toFixed(2) + ' KB'
    //       : (file.size / (1024 * 1024)).toFixed(2) + ' MB',
    // });
  }

  const filterTree = (children, level = 1) => {
    return children.map((v, k) => {
      v.value = v.styleId;
      v.label = v.styleName;
      v.key = String(v.styleId);
      v.level = level;
      const type = formRef.current?.getFieldValue('resourceType');
      // if (level < 3 && type === 2) {
      //   v.disabled = true;
      // }
      if (v.children && v.children.length > 0) {
        filterTree(v.children, level + 1);
      }
      return v;
    });
  };

  const getList = async (type, initValue?) => {
    const params = {
      styleType: type,
      resourceSearch: '',
    };
    api
      .getClassifyList(params)
      .then((res) => {
        if (res.code === '200') {
          const tree = filterTree(res.data.styleList);

          setOptions(tree);
          console.log(initValue, 'initValue');

          if (initValue) {
            console.log(
              tree,
              initValue.resourceTypeId,
              getPath(tree, initValue.resourceTypeId),
              'getPath(tree, initValue.resourceTypeId)'
            );
            const documentCoverId = initValue['documentCoverId'];

            formRef.current.setFieldsValue({
              ...initValue,
              resource: getPath(tree, initValue.resourceTypeId),
              documentCoverId,
            });
          }
        } else {
          Message.error(res.msg);
        }
      })
      .catch((e) => {
        Message.error(e.message);
      });
  };

  return (
    <div className={styles.edit_content}>
      <Spin loading={loading} style={{ width: '100%' }}>
        <Form ref={formRef} form={form} autoComplete="off" layout="vertical">
          <Form.Item
            style={{
              marginBottom: 0,
            }}
            // triggerPropName="checked"
            field="resourceType"
            label="文章类型"
            rules={[
              {
                required: true,
                message: '文章类型不能为空',
              },
            ]}
          >
            <Radio.Group
              onChange={(v) => {
                formRef.current.setFieldValue('resource', undefined);
                getList(v);
              }}
              disabled={!isMainStation}
            >
              <Radio value={2}>Pica8专属文章</Radio>
              <Radio value={1}>通用文章</Radio>
            </Radio.Group>
          </Form.Item>
          <Divider style={{ margin: '32px 0' }} />
          <Form.Item shouldUpdate noStyle>
            {(values) => {
              const type = formRef.current?.getFieldValue('resourceType');
              return (
                <Form.Item
                  style={{
                    marginBottom: 10,
                  }}
                  // triggerPropName="checked"
                  field="resource"
                  label="文档分类/类型："
                  rules={[
                    {
                      required: true,
                      message: '文档分类/类型不能为空',
                    },
                  ]}
                >
                  <Cascader
                    placeholder="输入标签(eg:售后;技术)"
                    expandTrigger="click"
                    options={options}
                    allowClear
                    disabled={!isMainStation}
                    // getPopupContainer={(node: HTMLElement) => node}
                    triggerProps={{
                      position: 'bl',
                    }}
                    changeOnSelect={type === 1 ? false : true}
                    onChange={(v, values) => {
                      console.log(v, values, 'vvv');

                      if (!v) return;
                      if (v.length < 3 && type === 2) {
                        Message.error('请选择三级以及以上的分类');
                        formRef.current.setFieldValue('resource', undefined);
                        return;
                      }
                      // if (type === 1) {
                      //   formRef.current.setFieldValue(
                      //     'resourceCategoryId',
                      //     v[0]
                      //   );
                      //   formRef.current.setFieldValue('resourceTypeId', v[1]);
                      // } else {
                      //   formRef.current.setFieldValue(
                      //     'resourceCategoryId',
                      //     v[1]
                      //   );
                      //   formRef.current.setFieldValue('resourceTypeId', v[2]);
                      // }
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            // triggerPropName="checked"
            triggerPropName="checked"
            field="isTop"
          >
            <Checkbox disabled={!isMainStation}>将文章置于子分类顶部</Checkbox>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {(values) => {
              const type = formRef.current?.getFieldValue('resourceType');
              return (
                type === 1 && (
                  <Form.Item
                    style={{
                      marginBottom: 20,
                    }}
                    // triggerPropName="checked"
                    field="productIds"
                    label="绑定产品:"
                    rules={[
                      {
                        required: true,
                        message: '绑定产品不能为空',
                      },
                    ]}
                  >
                    <Input
                      disabled={!isMainStation}
                      placeholder="请输入产品ID"
                    />
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          {!isMainStation && (
            <Form.Item
              // triggerPropName="checked"
              field="mainLabel"
              label="主站文档标签："
              style={{
                marginBottom: 10,
              }}
            >
              <Input.TextArea
                autoSize
                placeholder="输入标签(eg:售后;技术)"
                disabled
              />
            </Form.Item>
          )}
          <Form.Item
            // triggerPropName="checked"
            field="label"
            label="文档标签："
            style={{
              marginBottom: 10,
            }}
          >
            <Input.TextArea autoSize placeholder="输入标签(eg:售后;技术)" />
          </Form.Item>
          <Divider style={{ margin: '32px 0' }} />
          {!isMainStation && (
            <Form.Item
              // triggerPropName="checked"
              field="mainMetaKeyword"
              label="主站Meta关键词："
              rules={[
                {
                  required: true,
                  message: 'Meta关键词不能为空',
                },
              ]}
            >
              <Input.TextArea
                autoSize
                placeholder="请输入Meta关键词(eg:文章标题)"
                disabled
              />
            </Form.Item>
          )}
          <Form.Item
            // triggerPropName="checked"
            field="metaKeyword"
            label="Meta关键词："
            rules={[
              {
                required: true,
                message: 'Meta关键词不能为空',
              },
            ]}
          >
            <Input.TextArea
              autoSize
              placeholder="请输入Meta关键词(eg:文章标题)"
            />
          </Form.Item>
          {!isMainStation && (
            <Form.Item
              // triggerPropName="checked"
              field="mainMetaDesc"
              label="主站Meta标签："
              style={{
                marginBottom: '20px',
              }}
              rules={[
                {
                  required: true,
                  message: 'Meta标签不能为空',
                },
              ]}
            >
              <Input.TextArea
                autoSize
                placeholder="请输入Meta标签 （eg:这篇文章的描述,这是一篇关于...文章）"
                style={{ minHeight: 128 }}
                disabled
              />
            </Form.Item>
          )}
          <Form.Item
            // triggerPropName="checked"
            field="metaDesc"
            label="Meta标签："
            style={{
              marginBottom: 0,
            }}
            rules={[
              {
                required: true,
                message: 'Meta标签不能为空',
              },
            ]}
          >
            <Input.TextArea
              autoSize
              placeholder="请输入Meta标签 （eg:这篇文章的描述,这是一篇关于...文章）"
              style={{ minHeight: 128 }}
            />
          </Form.Item>
          <Divider style={{ margin: '32px 0' }} />
          <div className={styles.form_item}>
            <Form.Item
              label="New标签:"
              triggerPropName="checked"
              field="isShowNew"
              layout="horizontal"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: 'New标签不能为空',
                },
              ]}
              style={{
                marginBottom: 16,
              }}
            >
              <Switch size="small" disabled={!isMainStation} />
            </Form.Item>
            <Form.Item
              label="发布状态:"
              triggerPropName="checked"
              field="isPublished"
              layout="horizontal"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: '发布状态不能为空',
                },
              ]}
            >
              <Switch size="small" disabled={!isMainStation} />
            </Form.Item>
            {!isMainStation && (
              <Form.Item field="mainUrl" label="主站附件上传" shouldUpdate>
                <Input readOnly />
              </Form.Item>
            )}
            <div style={{ position: 'relative' }}>
              <Form.Item shouldUpdate noStyle>
                {(values) => {
                  const url = formRef.current?.getFieldValue('url');
                  return (
                    <>
                      <Form.Item
                        field="url"
                        label="附件上传:"
                        shouldUpdate
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <Input
                          // allowClear
                          // disabled
                          readOnly
                          onClear={() => {
                            console.log(123, '123');
                            setUploadList([]);
                            formRef.current.setFieldValue('url', '');
                          }}
                        />
                      </Form.Item>
                      <IconClose
                        style={{
                          display: !!url ? 'block' : 'none',
                          position: 'absolute',
                          right: 6,
                          top: 37,
                          cursor: 'pointer',
                          fontSize: 18,
                          color: '#999',
                        }}
                        onClick={() => {
                          setUploadList([]);
                          formRef.current.setFieldValue('url', '');
                          formRef.current.setFieldValue('urlSize', 0);
                        }}
                      />
                    </>
                  );
                }}
              </Form.Item>
            </div>

            <Upload
              // multiple
              // autoUpload={false}
              // action='/'
              accept=".pdf, .docx, image/*, .zip"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={customRequest}
              disabled={isUploading}
            >
              <div className={styles.upload_img}>
                <IconPlus />
                <span>添加附件</span>
              </div>
            </Upload>
            {/* <Form.Item field="resourceName" shouldUpdate noStyle></Form.Item>
            <Form.Item field="fileExtension" shouldUpdate noStyle></Form.Item> */}
            <Form.Item
              field="documentCoverId"
              // label="PDF封面选择："
              rules={[
                {
                  required: true,
                  message: '请选择封面',
                },
              ]}
              style={{
                marginTop: 20,
              }}
            >
              <PdfView documentId={documentId} />
            </Form.Item>
            <Divider style={{ margin: '0 0 20 0' }} />
            <div className={styles.buttons}>
              <Button
                shape="round"
                type="secondary"
                size="large"
                onClick={() => {
                  console.log(documentId, id, 'documentId');

                  window.open(
                    `${process.env.REACT_APP_FS_URL}/products_support/preview_sheet.html?id=${documentId}`,
                    '__blank'
                  );
                }}
              >
                预览
              </Button>
              <Button
                shape="round"
                type="primary"
                size="large"
                onClick={submit}
                loading={confirmLoading}
              >
                保存
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
}
