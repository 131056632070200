import { config, S3, AWSError } from 'aws-sdk';

export const createS3 = (token: string) => {
  console.log(token, 88888);
  const setting = {
    // accessKeyId: 'AKIAZ36WLN7C33DCPM7S',
    // secretAccessKey: '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',
    accessKeyId: 'AKIAZ36WLN7CUZL3XYFJ',
    secretAccessKey: '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',
    sessionToken: token,
  };
  config.update(setting);
  config.region = 'us-west-2';

  const s3 = new S3({
    apiVersion: '2006-03-01',
  });
  return s3;
};

interface paramsType {
  onProgress?: (percent, f: any) => void;
  onSuccess?: () => void;
  next?: ({}) => void;
  file: any;
  onError: () => void;
  token?: string;
  key?: string;
}

export const AWSUpload = (body: paramsType) => {
  const s3 = createS3(body.token); // 传入S3令牌
  s3.upload(
    {
      Body: body.file, // 是文件类型
      // Bucket: 'fs-lambda-edge-test', // 对应S3上的bucket
      Bucket: 'fs-static-resource',
      Key: body.key, // 需要上传到的路径
      ContentType: body.file.type,
      ACL: 'public-read',
    },
    {
      queueSize: Math.ceil(body.file.size / (5 * 1024 * 1024)),
      partSize: 5 * 1024 * 1024,
    },
    (err, res: any) => {
      console.log(err, res);
      if (err) {
      } else {
        body.onSuccess && body.onSuccess();
        body.next(res);
      }
    }
  )
    .on('httpUploadProgress', (progress) => {
      const percent = (100 * progress.loaded) / progress.total;
      console.log(`上传进度：${percent}%`);
      body.onProgress && body.onProgress(percent, progress);
    })
    .on('httpError', (err) => {
      if (err && body.onError) {
        body.onError();
        // s3subject.error(err);
      }
    })
    .on('send', (err: AWSError, data) => {
      console.log(err, data, 'send');
      if (err) {
        console.log(`S3分片上传文件出错:${err}`);
        body.onError();
        return false;
      }
      if (data) {
        console.log('S3上传文件成功：', data);
        body.onSuccess && body.onSuccess(); // 上传到百分百时调用 antd中的onSuccess
        return data;
      }
    });
  // return s3subject;
};
