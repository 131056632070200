// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 页面编辑、模板管理详情路由
const routes: Route[] = [
  {
    name: '敏感词管理',
    router: 'SensitiveManage',
    component: lazyload(() => import('@/pages/SensitiveManage/index')),
  },
];
export default routes;
