import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 解决方案
const routes: Route[] = [
  {
    name: '页面编辑详情',
    router: 'PageManager/SolutionManager/Detail/:id',
    component: lazyload(
      () => import('@/pages/PageManager/SolutionManager/Detail')
    ),
  },
  {
    name: '新页面编辑详情',
    router: 'PageManager/SolutionManager/NewDetail/:id',
    component: lazyload(
      () => import('@/pages/PageManager/SolutionManager/NewDetail/index')
    ),
  },
  {
    name: '新页面编辑详情',
    router: 'PageManager/SolutionManager/NewDetailV03/:id',
    component: lazyload(
      () => import('@/pages/PageManager/SolutionManager/NewDetailV03/index')
    ),
  },
];
export default routes;
