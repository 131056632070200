export default {
  'menu.home': '首页',
  'menu.pageManager': '页面管理',
  'menu.primaryPage': '一级页面管理',
  'navbar.logout': '退出登陆',
  'settings.otherSettings': '其他设置',
  'settings.colorWeek': '色弱模式',
  'settings.alertContent':
      '配置之后仅是临时生效，要想真正作用于项目，点击下方的 "复制配置" 按钮，将配置替换到 settings.json 中即可。',
  'settings.copySettings': '复制配置',
  'settings.copySettings.message':
      '复制成功，请粘贴到 src/settings.json 文件中',
  'settings.close': '关闭',
  'settings.color.tooltip':
      '根据主题颜色生成的 10 个梯度色（将配置复制到项目中，主题色才能对亮色 / 暗黑模式同时生效）',
  'message.tab.title.message': '消息',
  'message.tab.title.notice': '通知',
  'message.tab.title.todo': '待办',
  'message.allRead': '全部已读',
  'message.seeMore': '查看更多',
  'message.empty': '清空',
  'message.empty.tips': '暂无内容',
  'message.lang.tips': '语言切换至 ',
  'navbar.search.placeholder': '输入内容查询',
};