import React, { useContext, useRef, useEffect } from 'react';
import lazyload from '@/utils/lazyload'

import { Input, Button, Modal, Message } from '@arco-design/web-react';
import classNames from 'classnames';
import { ProductsContext } from '../../index'
import { deleteProductsHightlights } from '@/api/productsManager'
import styles from './style/index.module.less';
import FsSwitchTable from './components/FsSwitchTable'
import NoBlock from '@/assets/productsDetail/noblock.svg'
import debounce from 'lodash/debounce'
import { Types, hightlightsData } from './datas'

export default function ProductsHighlights(props: any) {
	const { data, table } = props;
	const context = useContext(ProductsContext);

	const [deleteModalData, setDeleteModalData] = React.useState({
		visible: false,
		block: '',
		btnLoading:true,
		index:''
	});


	const [importModalData, setImportModalData] = React.useState({
		visible: false,
		blockList: JSON.parse(JSON.stringify(hightlightsData)),
		checked: ''
	});

	function deleteHighlights(prama = { block: '' }) {
		setDeleteModalData((pre)=>{
			return{
				...pre,
				btnLoading:true
			}
		})
		deleteProductsHightlights({
			productsId: context.relationId,
			blockSortList:[deleteModalData.index],
			block: deleteModalData.block,
			componentId: data.componentId,
			operatePage:  1,
		}).then((res:any) => {
			if (res.code === '200') {
				context.siderCallback({type:8});
				setDeleteModalData({ visible: false, block: '',btnLoading:false ,index:''})
			}else{
				Message.error(res.msg);
				setDeleteModalData((pre)=>{
					return{
						...pre,
						btnLoading:false
					}
				})
			}
			
		})
	}

	const blockSearch = debounce((value) => {
		const arr = [];
		hightlightsData.map((item) => {
			if (item.block.includes(value)) {
				arr.push(item)
			}
		})

		setImportModalData((pre) => {
			return {
				...pre,
				blockList: arr
			}
		})
	}, 200)


	function importHightlights() {
		if (!importModalData.checked) {
			Message.error('请选择样式');
			return
		}
		setImportModalData((pre) => {
			return {
				...pre,
				visible: false,
				checked: ''
			}
		})

		context.openSiderBox({
			componentId: data.componentId,
			block: importModalData.checked,
			type: data.type,
			blockSort: ''
		})
	}

	return (
		<>
			{
				
				
				((data) || (table)) ? (
					<div className={classNames(`products_block_${context.relationId}_${data?.type||table?.type}`,[styles.products_highlights_box])}>
						<div className={styles.products_highlights_title}>
							<span className={styles.highlights_title}>Features</span>
						</div>
						{
							data && data.data && data.data.dataList && data.data.dataList.length ? (
							
												<div className={styles.products_highlights_ctn}>
													{
														data.data.dataList.map((item, index) => {
															const p = Types[item.block];
															console.log(item,'777')
															if (p) {
																if (item.data) {
																	const Component = lazyload(() => import(`./components/${p}`))
																	return (
																
																				<div className={styles.highlights_component_box}  key={`${item.block}-${index}`} onClick={(e) => {
																					e.stopPropagation(); console.log("1111222333"); context.openSiderBox({
																						componentId: data.componentId,
																						block: item.block || '',
																						type: data.type,
																						blockSort: index
																					})
																				}}>
																					<Component block={item.data} data={item.data} />
																					<div className={styles.highlights_component_mask}>{item.block}</div>
																				</div>
															
																	)
																} else {
																	return (
																		<div className={styles.highlights_component_empty_box} key={`${item.block}${index}`}>
																			<div className={styles.highlights_component_empty}>
																				{item.block}
																				<span className={classNames('iconfont', styles.iconfont_delete)}>&#xf019;</span>
																			</div>
																		</div>
																	)
																}
															} else {
																return null
															}
														})
													}
												</div>
							
							) : (
								<>
									{
										data && data.status ? (
											<div className={styles.products_highlights_empty}>特征描述内容区域</div>
										) : null
									}

								</>
							)
						}
						<FsSwitchTable data={table} />

						<Modal
							className={styles.delete_modal_box}
							title='确定删除'
							visible={deleteModalData.visible}
							onOk={() => { deleteHighlights(); }}
							onCancel={() => setDeleteModalData({ visible: false, block: '',btnLoading:false,index:'' })}
							autoFocus={false}
							focusLock={true}
							confirmLoading={deleteModalData.btnLoading}
						>
							<p>
								{`确定删除模块“${deleteModalData.block}” ？`}
							</p>
						</Modal>
						<Modal
							className={styles.import_modal_box}
							style={{ width: '750px' }}
							title='Features样式选择'
							visible={importModalData.visible}
							onCancel={() => setImportModalData((pre) => { return { ...pre, visible: false } })}
							autoFocus={false}
							focusLock={true}
							footer={<>
								<Button type="secondary" shape='round' onClick={() => setImportModalData((pre) => { return { ...pre, checked: '' } })}>清空选择</Button>
								<Button
									type="primary"
									shape='round'
									onClick={() => importHightlights()}
									style={{ marginLeft: 16 }}
								>
									导入
								</Button>
							</>}
						>
							<Input.Search
								allowClear
								placeholder='请输入样式编号'
								style={{ width: 456 }}
								onChange={blockSearch}
							/>
							<div className={styles.block_list_box}>
								<div className={styles.block_list_header}>
									<div className={styles.block_list_radio}></div>
									<div className={styles.block_list_image}>样式图片</div>
									<div className={styles.block_list_desc}>样式信息</div>
								</div>

								<div className={styles.block_list_body}>
									{
										importModalData.blockList && importModalData.blockList.length ? (
											<>
												{
													importModalData.blockList.map((item, index) => {
														return (
															<div className={classNames(styles.block_list_item, { [styles.block_list_item_active]: importModalData.checked === item.block })} key={index}>
																<div className={styles.block_list_radio}>
																	<input className={styles.block_radio} type="radio" value={item.block} name="block" checked={importModalData.checked === item.block} onChange={() => setImportModalData((pre) => { return { ...pre, checked: item.block } })} />
																</div>
																<div className={styles.block_list_image}>
																	<img src={item.img} alt="" />
																</div>
																<div className={styles.block_list_desc}>
																	<p>{item.block}</p>
																	{
																		item.content.map((c, ci) => {
																			return <p key={ci}>{c}</p>
																		})
																	}
																</div>
															</div>
														)
													})
												}
											</>
										) : (
											<div className={styles.noblock_box}>
												<NoBlock className={styles.noblock_img} />
												<p className={styles.noblock_info}>Wwwhy~ 这里好像什么也没有... </p>
											</div>
										)
									}
								</div>
							</div>
						</Modal>
					</div>
				) : null
			}
		</>
	)
}