import http from '@/utils/axios';
import store from '@/store';
import { setRequestLoading } from '@/store/user/userSlice'; // 在请求发出之前进行一些操作

import { AxiosResponse } from 'axios';
type resType = AxiosResponse<any, any> & {
  code: string;
  data: any;
  message: string;
};


// 请求拦截器,每次请求前都会调用,可以在这里进行一些操作,比如loading等
const request = (config: any, loading = false) => {
  return new Promise((resolve, reject) => {
    if (loading) {
      store.dispatch(setRequestLoading(true));
    }
    http(config).then((res: resType) => {
      resolve(res);
    }).catch((err: unknown) => {
      reject(err);
    }).finally(() => {
      store.dispatch(setRequestLoading(false));
    });
  });
};
export default request;

