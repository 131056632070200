import { RootState } from '@/store';
import { Avatar, Dropdown, Menu, Popover } from '@arco-design/web-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style/index.module.less';
import indexDB from '@/utils/indexDB';
import useStorage from '@/utils/useStorage';
import { getCategoryList } from '@/store/board/boardSlice';
import { useHistory } from 'react-router-dom';
import {
  removeRouterTag,
  setLocale,
  updateUserInfo,
} from '@/store/user/userSlice';
import { updateUserPermission } from '@/api/roleManager';
export default function LoginInfo() {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const language_codes = useSelector(
    (state: RootState) => state.user.language_code
  );
  const history = useHistory();
  const [, setUserStatus] = useStorage('userStatus');
  const dispatch = useDispatch();
  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick} selectedKeys={[language_codes]}>
      <Menu.Item key="logout">退出登录</Menu.Item>
    </Menu>
  );
  function logout() {
    setUserStatus('logout');
    localStorage.clear();
    indexDB.clearTable();
    window.location.href = '/login';
  }
  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    } else {
      dispatch(setLocale(key));
      dispatch(getCategoryList());
      updateUserPermission({}).then(async (res: any) => {
        const { data = {} } = res || {};
        const {
          websiteInfo = [],
          permission = [],
          functionPermissionIdList = [],
        } = data;
        await dispatch(
          updateUserInfo({
            ...userInfo,
            websiteInfo,
            permission,
            functionPermissionIdList,
          })
        );
        // const url = window.location.href
        // if (!hasPermission(data.permission,url)) {
        //   window.location.href = '/Welcome';
        // } else {
        //   window.location.href = window.location.href;
        // }
        history.push('/Welcome');
      });
      // Message.info(`You clicked ${key}`);
    }
  }
  return (
    <div className={styles.login_info}>
      <div className={styles.info}>
        <div className={styles.username}>{userInfo.name}</div>
        <div className={styles.noun}>{userInfo.job}</div>
      </div>
      {userInfo && (
        <div>
          <Dropdown droplist={droplist} position="br">
            <Avatar
              size={44}
              style={{
                cursor: 'pointer',
                border: '1.5px solid #DFE0EB',
              }}
            >
              <img alt="avatar" src={userInfo.avatar} />
            </Avatar>
          </Dropdown>
        </div>
      )}
    </div>
  );
}
