// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 运营看板路由
const routes: Route[] = [ 
  {
    name: '新品优化器',
    router: 'operationBoard/productOptimizer',
    component: lazyload(() => import('@/pages/OperationBoard/ProductOptimizer/index')),
  },
  {
    name: '详情页完整度',
    router: 'operationBoard/detailIntegrity',
    component: lazyload(() => import('@/pages/OperationBoard/ProductOptimizer/index')),
  },
  {
    name: '客户评论',
    router: 'operationBoard/customerComments',
    component: lazyload(() => import('@/pages/OperationBoard/ProductOptimizer/index')),
  },
  {
    name: '客户Q&A',
    router: 'operationBoard/customerQ&A',
    component: lazyload(() => import('@/pages/OperationBoard/ProductOptimizer/index')),
  },
  {
    name: 'A+内容范围',
    router: 'operationBoard/contentScope',
    component: lazyload(() => import('@/pages/OperationBoard/ProductOptimizer/index')),
  },
  {
    name: '有库存',
    router: 'operationBoard/stock',
    component: lazyload(() => import('@/pages/OperationBoard/ProductOptimizer/index')),
  },
  {
    name: '产品广告投放情况',
    router: 'operationBoard/productAdvertising',
    component: lazyload(() => import('@/pages/OperationBoard/ProductOptimizer/index')),
  },
  // {
  //   name: '模版管理详情',
  //   router: 'PageManager/ProductDetailManager/PageEditList/Detail',
  // },
];
export default routes;