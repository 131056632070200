import React, { createContext, useEffect, useMemo, useRef, useState } from 'react'
import IconSvg from '@/components/IconSvg';
import style from './style/index.module.less'
import ItemSpotlights from './ProductsComponents/ItemSpotlights/index';
import ProductsDescription from './ProductsComponents/ProductsDescription/index';
import ProductsSpecification from './ProductsComponents/ProductsSpecification/index';
import QualityCertifications from './ProductsComponents/QualityCertifications/index';
import QualityTestingProgram from './ProductsComponents/QualityTestingProgram/index';
import SoftwareSpotlights from './ProductsComponents/SoftwareSpotlights/index';
import NetworkConnectivity from './ProductsComponents/NetworkConnectivity/index';
import ProductsHighlights from './ProductsComponents/ProductsHighlights/index';
import SiderBox from './component/SiderBox';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { deleteComponent, ResType } from '@/api/globalLink';
import { Message, Popconfirm } from '@arco-design/web-react';

export const ProductsContext = createContext<{
	productId?: string;
	relationId?: string;
	type?: string;
	siderCallback?: any;
	openSiderBox?: any;
	reorderProductsHightlight?: any;
	templateName?: any;
	templatePerson?: any;
	templateTime?: any;
	editTemplate?: boolean;
	copyBtnLoading?: boolean;
}>({});

export default function ProductDetail({ data, detailContext,getDetailData }) {
    const [productData, setProductData] = useState(data)
    const [floorData, setFloorData] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const productsWrap = useRef(null);
    const [siderData, setSiderData] = useState({
        visible: false,
        componentId: null,
        block: '',
        type: 0,
        productId: parseInt(detailContext.id),
        operatePage: 1,
        blockSort: ''
    })
    const languageInfo = useSelector(
        (state: RootState) => state.user.languageInfo
      );
    const websiteCode = languageInfo.code === 'en' ? '' : `/${languageInfo.code}`;
      
    function sideMenuClick(e, item) {
        console.log(item)
		e.stopPropagation();
        const target = productsWrap.current.querySelector(`.${style.product_detail_r} .products_block_${productData.relationId}_${item.type || item.type}`);
        console.log(`.${style.product_detail_r} .products_block_${productData.relationId}_${item.type}`)
        const el:HTMLElement = document.querySelector(`.${style.product_detail_r}_${productData.relationId}`);
        if (el) {
            el.scrollTo({ top: target.offsetTop - 20, behavior: 'smooth' })
        }
	}

    function openSiderBox(params: any) {
        if (detailContext.editPage) {
            const { componentId = null, block = '', type = null, blockSort = '' } = params;
            console.log(block,'787878')
            setSiderData((prev) => {
                return {
                    ...prev,
                    componentId,
                    block,
                    type,
                    blockSort,
                    visible: true,
                }
            })
        }
      
    }
    const closeSiderBox = () => {
        setSiderData((prev) => {
            return {
                ...prev,
                visible: false
            }
        })
    }

    const navData = useMemo(() => {
		let arr = [];
		if (productData.componentList && productData.componentList.length) {
			productData.componentList.map((item, index) => {
                if ([2, 3, 4, 5, 6].includes(item.type)) {
                    if (!arr.includes('Specifications')) {
                        arr.push({
                            text: 'Specifications',
                            status: item.status,
                            type: 2
                        })
                    }
                } else if ([7].includes(item.type)) {
                    if (!arr.includes('Connectivity Solutions')) {
                        arr.push({
                            text: 'Connectivity Solutions',
                            status: item.status,
                            type: item.type
                        })
                    }
                } else if ([8, 9].includes(item.type)) {
                    if (!arr.includes('Features')) {
                        arr.push({
                            text: 'Features',
                            status: item.status,
                            type: item.type
                        })
                    }

                } else if ([10].includes(item.type)) {
                    if (!arr.includes('Videos')) {
                        arr.push({
                            text: 'Videos',
                            status: item.status,
                            type: item.type
                        })
                    }
                }
			})
		}

		if (arr && arr.length) {
			const map = new Map();
			for (const item of arr) {
				if (!map.has(item.text)) {
					map.set(item.text, item);
				}
			}
			arr = [...map.values()];
		}
		return arr;
	}, [productData]);
    
    const siderCallback = (p) => {
        if (p) {
            console.log('保存成功-待编辑到已编辑');
            closeSiderBox();
            getDetailData()
        }
    }
    const deleteFloor = (componentId) => {
        const params = {
            componentId
        }
        deleteComponent(params).then((res:ResType) => {
          if (res.code === '200') {
              Message.success('删除成功')
              getDetailData()
          }
        }).catch((err) => {
            Message.error('删除失败')
        })
    }
    useEffect(() => {
        const floorDataTmp = []
        productData?.componentList.map((item => {
            floorDataTmp[item.type] = item
        }))
        setFloorData(floorDataTmp)
    }, [productData])
    useEffect(() => {
       setProductData(data)
    }, [data])
    return (
        <ProductsContext.Provider value={{ ...productData, siderCallback, openSiderBox }}>
            <div className={style.product_detail}>
                <div className={style.product_detail_l}>
                    <div className={style.product_detail_data}>
                        <div>
                            产品ID：
                            <span className={style.product_detail_data_id} onClick={() => {
                                const href = `https://www.fs.com${websiteCode}/products/${productData.relationId}.html`
                                window.open(href)
                            }}>{productData.relationId}</span>
                        </div>
                        <div>
                            产品名称：{productData.relationName}
                        </div>
                    </div>
                    <div className={style.product_detail_anchor}>
                        {
                            productData?.componentList.map((item, index) => {
                                return <div key={index} className={`${style.product_detail_anchor_item} ${activeIndex === index ? style.product_detail_anchor_item_active : ''}`}>
                                    <span onClick={e => {
                                        setActiveIndex(index)
                                        sideMenuClick(e, item)
                                    }}>
                                        {item.componentName}
                                    </span>
                                     {
                                        detailContext.editPage ?
                                        <Popconfirm
                                        title='确认删除楼层吗？'
                                        onOk={() => {
                                            deleteFloor(item.componentId)
                                        }}
                                        trigger='hover'
                                        getPopupContainer={(node: HTMLElement) => node.parentElement}
                                    >
                                    <IconSvg
                                        type="icon-shanchu"
                                        className={style.product_detail_anchor_icon}
                                    ></IconSvg>
                                    </Popconfirm> : null
                                     }
                                   
                             
                                </div>
                            })
                        }

                    </div>
                </div>
                <div className={classNames(`${style.product_detail_r}_${productData.relationId}`,[style.product_detail_r])} ref={productsWrap}>
                    <div className={style.product_detail_r_container}>
                    <ItemSpotlights data={floorData[1]}></ItemSpotlights>
                     <div className={style.product_describe_box}>
								{
							navData && navData.length && navData[0].text === 'Specifications' ? (
										<div className={style.detail_nav_box}>
											{
												navData.map((item, index) => {
													{
														return <span key={item.text} className={classNames(style.detail_nav_item, { [style.detail_nav_item_active]: index === 0 })} onClick={(e) => sideMenuClick(e, item)}>{item.text}</span>
													}
												})
											}
										</div>
                            ) : null
								}
								<ProductsDescription data={floorData[2]} />
								<ProductsSpecification data={floorData[3]} />
								<SoftwareSpotlights data={floorData[4]} />
								<QualityTestingProgram data={floorData[5]} />
								<QualityCertifications data={floorData[6]} />
					</div>
                    <NetworkConnectivity data={floorData[7]} />
					<ProductsHighlights data={floorData[8]} table={floorData[9]} />
                    </div>
                </div>
            </div>
            <SiderBox visible={siderData.visible} onCancel={closeSiderBox} parentOnChange={siderCallback} detailParams={{
				componentId: siderData.componentId,
				block: siderData.block,
				type: siderData.type,
				productId: siderData.productId,
				operatePage: siderData.operatePage,
				blockSort: siderData.blockSort
			}} />
        </ProductsContext.Provider>
    )
}
