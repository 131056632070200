import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 视频管理
const routes: Route[] = [
  {
    name: '视频编辑管理',
    router: 'Resource/Video/EditManager',
    component: lazyload(
      () => import('@/pages/Resource/Video/EditManager/index')
    ),
  },
  {
    name: '视频标签管理',
    router: 'Resource/Video/TagManager',
    component: lazyload(
      () => import('@/pages/Resource/Video/TagManager/index')
    ),
  },
];
export default routes;
