import React, { useContext } from 'react'
import { Popover } from '@arco-design/web-react';
import styles from './style/index.module.less'
import classNames from 'classnames';
import { ProductsContext } from '../../index'
import { handleResourcePath,splitColonString } from '@/utils/utils'


export default function ProductsSpecification(props: any) {
	const { data } = props;
	const context = useContext(ProductsContext);
    console.log(data,'888')
	return (
		<>
			{
				data ? (
					<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.products_specification_box])} onClick={() => context.openSiderBox({
						componentId: data.componentId,
						block: data.block || '',
						type: data.type,
					})}>
						<div className={styles.products_specification_title}>Specifications</div>
						<div className={styles.products_specification_content_wrap}>
							{(data && data.data) ?
								(<div className={styles.products_specification_content}>
									{

										(data && data.data && data.data.paramList && data.data.paramList.length) ? <div className={styles.specification_box}>
											{
												Array.from(Array(Math.ceil(data.data.paramList.length / 2)), (item, index) => {
													return <div className={styles.specification_row} key={index}>
														{
															Array.from(Array(2), (citem, ci) => {
																if ((((2 * (index) + ci) <= (data.data.paramList.length - 1)) && data.data.paramList[2 * (index) + ci])) {
																	return (
																		<div className={styles.specification_item_box} key={ci}>
																			<div className={styles.specification_item}>
																				<span className={styles.specification_name} dangerouslySetInnerHTML={{ __html: data.data.paramList[2 * (index) + ci].title }}></span>
																				{
																					(data.data.paramList[2 * (index) + ci].titleBubbleList && data.data.paramList[2 * (index) + ci].titleBubbleList.length) ?
																						<Popover
																							trigger='hover'
																							title={
																								data.data.paramList[2 * (index) + ci].titleBubbleList[0] ? <div className={styles.pop_title} dangerouslySetInnerHTML={{ __html: data.data.paramList[2 * (index) + ci].titleBubbleList[0] }}></div> : null
																							}
																							content={
																								data.data.paramList[2 * (index) + ci].titleBubbleList.length > 1 ? <div className={styles.pop_content}>{
																									data.data.paramList[2 * (index) + ci].titleBubbleList.slice(1).map((pt, pi) => {
																										return <p key={pi} dangerouslySetInnerHTML={{ __html: pt }}></p>
																									})
																								}
																								</div> : null
																							}
																						>
																							<span className={classNames(`iconfont`, styles.iconfont_tip)}>&#xf011;</span>
																						</Popover>
																						: null}
																			</div>

																			<div className={styles.specification_item}>
																				<span className={styles.specification_value} dangerouslySetInnerHTML={{ __html: data.data.paramList[2 * (index) + ci].content }}></span>
																				{
																					(data.data.paramList[2 * (index) + ci].contentBubbleList && data.data.paramList[2 * (index) + ci].contentBubbleList.length) ?
																						<Popover
																							trigger='hover'
																							title={
																								data.data.paramList[2 * (index) + ci].contentBubbleList[0] ? <div className={styles.pop_title} dangerouslySetInnerHTML={{ __html: data.data.paramList[2 * (index) + ci].contentBubbleList[0] }}></div> : null
																							}
																							content={
																								data.data.paramList[2 * (index) + ci].contentBubbleList.length > 1 ? <div className={styles.pop_content}>{
																									data.data.paramList[2 * (index) + ci].contentBubbleList.slice(1).map((pt, pi) => {
																										return <p key={pi} dangerouslySetInnerHTML={{ __html: pt }}></p>
																									})
																								}
																								</div> : null
																							}
																						>
																							<span className={classNames(`iconfont`, styles.iconfont_tip)}>&#xf011;</span>
																						</Popover>
																						: null}
																			</div>
																		</div>
																	)
																} else {
																	return null
																}
															})
														}
													</div>
												})
											}
										</div> : null

									}
									{
										(data && data.data && data.data.remarks) ? (
											<div className={styles.specification_remarks} dangerouslySetInnerHTML={{ __html: data.data.remarks }}></div>
										) : null
									}
									{
										(data && data.data && data.data.recommendedTitle) ? (
											<div className={styles.reduction_title}>
												{
													splitColonString(data.data.recommendedTitle) && splitColonString(data.data.recommendedTitle)[0] ?(
														<span dangerouslySetInnerHTML={{__html:splitColonString(data.data.recommendedTitle)[0]}}></span>
													):null
												}
												{
													splitColonString(data.data.recommendedTitle) && splitColonString(data.data.recommendedTitle)[1] ?(
														<span dangerouslySetInnerHTML={{__html:splitColonString(data.data.recommendedTitle)[1]}}></span>
													):null
												}
											</div>
										) : null
									}
									{
										(data && data.data && data.data.recommendedList && data.data.recommendedList.length) ? (
											<div className={styles.specification_recommended_list}>
												{
													data.data.recommendedList.map((item, index) => {
														return (
															<div key={index} className={styles.recommended_item_box}>
																{
																	(data.data.recommendedList[index] && data.data.recommendedList[index].length) ? (
																		data.data.recommendedList[index].map((ritem, rindex) => {
																			return (
																				<div className={styles.recommended_item} key={rindex} dangerouslySetInnerHTML={{ __html: ritem }}></div>
																			)
																		})
																	) : null
																}
															</div>
														)
													})
												}
											</div>
										) : null
									}
								</div>) : (<div className={styles.products_specification_empty}>规格参数区域</div>)
							}
						</div>

					</div>
				) : null
			}
		</>
	)
}