import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Cascader, Message, Select, Spin } from '@arco-design/web-react';
import getCreatorList, { ResType } from '@/api/creator';
import debounce from 'lodash/debounce';
import { selectAdminList } from '@/api/BoardManage';
interface OptionType {
  value: string | number;
  label: string;
}
interface props {
  onChange: any;
  placeholder: string;
  width?: number | string;
  size?: any;
  defaultValue?: any;
  type?: string;
  mode?: 'multiple' | 'tags';
  option?: OptionType[];
  // defaultLabel?: string;
}
const ajax = [getCreatorList, selectAdminList];
export default function CreatSelect({
  onChange,
  placeholder,
  width,
  size = 'default',
  defaultValue,
  option,
  // defaultLabel,
  type = 'cms',
  mode = null,
}: props) {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const debouncedFetchUser = debounce((inputValue) => {
    setFetching(true);
    setOptions([]);
    console.log(inputValue, 'value');
    try {
      if (type === 'cms') {
        if (option && option.length && !inputValue) {
          setOptions([...option]);
          return;
        }
        getCreatorList({ keyword: inputValue }).then((res: ResType) => {
          const options = res.data.map((user) => ({
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {user.joinName}
              </div>
            ),
            value: user.id,
          }));
          setFetching(false);
          setOptions(options);
        });
      } else {
        selectAdminList({ username: inputValue }).then((res: ResType) => {
          console.log(res, 'resres');
          const options = res.data.map((user) => ({
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {user.adminName}
              </div>
            ),
            value: user.adminId,
          }));
          setFetching(false);
          setOptions(options);
        });
      }
    } catch (e) {
      Message.error(e);
    }
  }, 500);
  useEffect(() => {
    debouncedFetchUser();
  }, []);
  // useEffect(() => {
  //   if (defaultValue) {
  //     setOptions([
  //       {
  //         label: defaultLabel,
  //         value: defaultValue,
  //       },
  //     ]);
  //   }
  // }, [defaultValue]);
  return (
    <div>
      <Select
        defaultValue={defaultValue}
        style={{ width: width || 220 }}
        showSearch
        options={options}
        placeholder={placeholder}
        filterOption={false}
        size={size}
        allowClear
        mode={mode}
        notFoundContent={
          fetching ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin style={{ margin: 12 }} />
            </div>
          ) : null
        }
        onSearch={debouncedFetchUser}
        onChange={(value, item: any) => {
          const index = item?.children?.props?.children.indexOf('(');
          let name = item?.children?.props?.children;
          if (index > 0 && name) {
            name = name.slice(index + 1, name.length - 1);
          }
          onChange(value, name);
        }}
      />
    </div>
  );
}
