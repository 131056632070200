import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Button,
  Select,
  Space,
  Tag,
  Message,
  Spin,
} from '@arco-design/web-react';
import { tagTextColor, tagBgColor } from '../../components/entry';
import { tagProps } from '../../index';
import { ResType, getGlossaryTagList } from '@/api/GlossaryManager';
import debounce from 'lodash/debounce';
type Props = {
  visible: boolean;
  setVisible: any;
  tagList: tagProps[];
  setTagList: any;
};

const BindTagModel = (props: Props) => {
  const { visible, setVisible, tagList, setTagList } = props;
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    visible && getGlossaryTagListApi();
    !visible && setSelectValue(undefined);
  }, [visible]);

  const debouncedFetchUser = useCallback(
    debounce((inputValue: string) => {
      setFetching(true);
      setData([]);
      getGlossaryTagListApi(inputValue);
    }, 500),
    []
  );

  const getGlossaryTagListApi = (keyword?: string) => {
    getGlossaryTagList(keyword ? keyword : undefined)
      .then((res: ResType) => {
        if (res.code === '200') {
          setData(res.data);
        } else {
          setData([]);
          Message.error(res.msg);
        }
      })
      .finally(() => {
        setFetching(false);
      });
  };
  const handleSelectTag = (value: number, option) => {
    const { children } = option;
    console.log(value, children);
    const tagItem = tagList.find((v) => v.id === value);
    if (!tagItem) {
      setTagList([...tagList, { id: value, name: children }]);
    }
  };
  const handleClose = (removeTag) => {
    console.log(removeTag);
    const newTags = tagList.filter((tag) => tag.id !== removeTag);
    console.log(newTags);
    setTagList(newTags);
  };
  const save = () => {
    setVisible(false);
  };
  const footer = (): ReactElement => (
    <>
      <Button
        size="large"
        shape="round"
        type="secondary"
        onClick={() => {
          setVisible(false);
        }}
      >
        取消
      </Button>
      <Button size="large" shape="round" type="primary" onClick={save}>
        确定
      </Button>
    </>
  );
  return (
    <Modal
      title={<div style={{ textAlign: 'left' }}>绑定标签</div>}
      style={{
        width: 600,
      }}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      autoFocus={false}
      focusLock={true}
      footer={footer}
    >
      <Space wrap>
        {tagList.map((tag: tagProps, index) => {
          const idx = index % tagList.length;
          return (
            <Tag
              key={index + '-' + tag.name}
              closable
              onClose={() => handleClose(tag.id)}
              color={tagBgColor[idx]}
              style={{
                color: tagTextColor[idx],
              }}
              size="medium"
            >
              {tag.name}
            </Tag>
          );
        })}
      </Space>
      <Select
        style={{ borderRadius: 8, marginTop: 12 }}
        allowClear
        showSearch
        placeholder="请选择"
        onChange={handleSelectTag}
        value={selectValue}
        filterOption={false}
        onSearch={debouncedFetchUser}
        notFoundContent={
          fetching ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin style={{ margin: 12 }} />
            </div>
          ) : null
        }
      >
        {data &&
          data.map((item: tagProps, i) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
      </Select>
    </Modal>
  );
};

export default BindTagModel;
