import React, { useContext } from "react";
import styles from './style/index.module.less'
import { ProductsContext } from '../../index'
import classNames from 'classnames';



export default function ProductsDescription(props: any) {
	const { data } = props;
	const context = useContext(ProductsContext);
	return (
		<>
			{
				data ? (
					<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.products_description_box])} onClick={() => context.openSiderBox({
						componentId: data.componentId,
						block: data.block || '',
						type: data.type,
					})}>
						{
							(data && data.data) ? (<div className={styles.products_description_ctn}>
								{
									data.data.title ? <div className={styles.description_title} dangerouslySetInnerHTML={{ __html: data.data.title }}></div> : null
								}
								{
									(data && data.data && data.data.contentList && data.data.contentList.length) ?
										(<>
											{
												data.data.contentList.map((item, index) => {
													return <div className={styles.description_item} key={index} dangerouslySetInnerHTML={{ __html: item }}></div>
												})
											}
										</>) : null
								}
							</div>) : (<div className={styles.products_description_empty}>
								产品描述内容区域
							</div>)
						}
					</div>
				) : null
			}


		</>

	)
}