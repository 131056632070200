import React, { useState } from 'react';
import { IconPlus } from '@arco-design/web-react/icon';
import styles from './index.module.less';
import ImageSelect from '@/components/ImageSelect';
type PropsType = {
  onChange?: (value: string) => void;
  value?: string;
  fileType?: string[];
  label?: string;
  disabled?: boolean;
};

const CustomImage: React.FC<PropsType> = ({
  onChange,
  value,
  fileType = ['jpg', 'png'],
  label,
  disabled,
}) => {
  const [imageSelectVisible, setImageSelectVisible] = useState<boolean>(false);
  const handleOk = (data: any) => {
    const imagePath =
      Array.isArray(data) && data.length > 0 && data[0].picturePath;
    setImageSelectVisible(false);
    onChange && onChange(imagePath);
  };
  const onCancel = () => {
    console.log('取消');
    setImageSelectVisible(false);
  };
  return (
    <>
      <div className={styles['image-view']}>
        {label ? <span className={styles['image-label']}>{label}</span> : null}
        <div
          className={[
            styles['image-view-content'],
            disabled ? styles['image-view-disabled'] : '',
          ].join(' ')}
          onClick={(e) => {
            e.stopPropagation();
            setImageSelectVisible(true);
          }}
        >
          {value ? (
            <div className={styles['image-view-content-preview']}>
              <img src={value} alt="" />
              <div
                className={styles['image-view-content-preview-mask']}
                onClick={() => {
                  // setUploadVisible(true);
                }}
              >
                <span>更换封面</span>
              </div>
            </div>
          ) : (
            <div className={styles['image-view-content-add']}>
              <IconPlus />
              <span>上传封面 (1190*1684)</span>
            </div>
          )}
        </div>
        {/* 资源选择 */}
      </div>

      <ImageSelect
        imageSelectVisible={imageSelectVisible}
        type={'image'}
        handleOk={handleOk}
        onCancel={onCancel}
        imageLimt={fileType}
        limitMessage={`只允许${fileType.join('、')}格式的文件`}
        tableType={'radio'}
      />
    </>
  );
};

export default CustomImage;
