import React from 'react';
import styles from './index.module.less';
import LoginInfo from '@/components/LoginInfo';
import { RootState } from '@/store';
import { useSelector } from 'react-redux';

function EditorHeader() {
  const languageInfo = useSelector(
    (state: RootState) => state.user.languageInfo
  );
  const { code = '' } = languageInfo || {};
  return (
    <div className={styles.nav}>
      <div className={styles.logo}>
        <img src="https://resource.fs.com/mall/generalImg/202206161506275riiaf.png" />
        <span>
          Blog文章编辑-{code === 'en' ? 'US' : code.toLocaleUpperCase()}
        </span>
      </div>
      <div className={styles.right}>
        <LoginInfo />
      </div>
    </div>
  );
}

export default EditorHeader;
