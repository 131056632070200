export const Types = {
	FS01: 'FS01',
	FS01_A: 'FS01_A',
	FS01_1: 'FS01_1',
	FS01_2: 'FS01_2',
	FS02: 'FS02',
	FS03: 'FS03',
	FS03_1: 'FS03_1',
	FS03_2: 'FS03_2',
	FS05: 'FS05',
	FS06: 'FS06',
	FS07: 'FS07',
	FS08: 'FS08',
	FS09: 'FS09',
	FS10: 'FS10',
	FS11: 'FS11',
	FS011: 'FS011',
	FS12_02: 'FS12_02',
	FS13: 'FS13',
	FS14: 'FS14',
	FS15: 'FS15',
	FS16: 'FS16',
	FS18: 'FS18',
	FS19: 'FS19',
	FS20: 'FS20',
	FS21_01: 'FS21_01',
	FS21_02: 'FS21_02',
	FS22: 'FS22',
	FS24: 'FS24',
	FS24_01: 'FS24_01',
	FS24_02: 'FS24_02',
	FS25: 'FS25',
	FsSwitchTable: 'FsSwitchTable'
} 

export const hightlightsData = [
	{
		block: "FS01",
		img: require('@/assets/productsDetail/hightlightsImage/FS01.jpeg'),
		content: [
			'标题+描述+图片/视频+描述（选填）（图片不带底色）'
		]
	}, {
		block: "FS01_1",
		img: require('@/assets/productsDetail/hightlightsImage/FS01_1.jpeg'),
		content: [
			'标题+三段描述+图片（图片带底色）'
		]
	}, {
		block: "FS02",
		img: require('@/assets/productsDetail/hightlightsImage/FS02.jpeg'),
		content: [
			'标题+描述+图片/视频'
		]
	}, {
		block: "FS03",
		img: require('@/assets/productsDetail/hightlightsImage/FS03.jpeg'),
		content: [
			'【图片+描述】+【图片+描述】+【图片+描述】'
		]
	}, {
		block: "FS03_1",
		img: require('@/assets/productsDetail/hightlightsImage/FS03_1.jpeg'),
		content: [
			'【图片+标题 +描述】+【图片+标题 +描述】+【图片+标题 +描述】（带底色）'
		]
	}, {
		block: "FS03_2",
		img: require('@/assets/productsDetail/hightlightsImage/FS03_2.jpeg'),
		content: [
			'【图片+标题(选填)+描述】+【图片（圆角）+标题(选填)+描述】+【图片（圆角）+标题(选填)+描述】',
			'注：图片上传后，代码在Section2和Section3的样式上会自动给图片外部加上一个圈'
		]
	}, {
		block: "FS05",
		img: require('@/assets/productsDetail/hightlightsImage/FS05.jpeg'),
		content: [
			'标题（选填）+【图片+小标题+描述】',
			'注：图片位置可以选择在左侧或者右侧'
		]
	}, {
		block: "FS06",
		img: require('@/assets/productsDetail/hightlightsImage/FS06.jpeg'),
		content: [
			'标题+【图片+描述】X6',
			'注：一行三组，分两行展示'
		]
	}, {
		block: "FS07",
		img: require('@/assets/productsDetail/hightlightsImage/FS07.jpeg'),
		content: [
			'标题+副标题(选填)+【icon（选填）+标题+描述（选填）】X6',
			'注：描述和图片不能同时为空'
		]
	}, {
		block: "FS08",
		img: require('@/assets/productsDetail/hightlightsImage/FS08.jpeg'),
		content: [
			'标题+副标题（选填）+【icon+描述】'
		]
	}, {
		block: "FS09",
		img: require('@/assets/productsDetail/hightlightsImage/FS09.jpeg'),
		content: [
			'标题+副标题（选填）+【表头1+表头2+表头3】+【图标+描述1+描述2】'
		]
	}, {
		block: "FS11",
		img: require('@/assets/productsDetail/hightlightsImage/FS11.jpeg'),
		content: [
			'标题+描述+图片/视频+【icon+标题+描述】(选填)'
		]
	}, {
		block: "FS011",
		img: require('@/assets/productsDetail/hightlightsImage/FS011.jpeg'),
		content: [
			'标题+描述+链接文字+链接地址+图片/视频（选填）'
		]
	}, {
		block: "FS12_02",
		img: require('@/assets/productsDetail/hightlightsImage/FS12_02.jpeg'),
		content: [
			'标题+【描述+tag图】(选填)'
		]
	}, {
		block: "FS13",
		img: require('@/assets/productsDetail/hightlightsImage/FS13.jpeg'),
		content: [
			'标题+序号+小标题+【标题1+描述1】+【标题2+描述2】+【图片1+图片2+图片3】'
		]
	}, {
		block: "FS14",
		img: require('@/assets/productsDetail/hightlightsImage/FS14.jpeg'),
		content: [
			'标题+序号+小标题+【标题1+描述1】+【标题2+描述2】+图片/视频(选填)'
		]
	}, {
		block: "FS15",
		img: require('@/assets/productsDetail/hightlightsImage/FS15.jpeg'),
		content: [
			'标题（选填）+序号+小标题+描述（选填）+图片'
		]
	}, {
		block: "FS16",
		img: require('@/assets/productsDetail/hightlightsImage/FS16.jpeg'),
		content: [
			'标题+【icon1+标题1+描述1】(选填)+【icon2+标题2+描述2】（选填）+【icon3+标题3+描述3】(选填)'
		]
	},
	{
		block: "FS19",
		img: require('@/assets/productsDetail/hightlightsImage/FS19.jpeg'),
		content: [
			'标题+Tag图片'
		]
	}, {
		block: "FS20",
		img: require('@/assets/productsDetail/hightlightsImage/FS20.jpeg'),
		content: [
			'标题+描述+【图片/视频+描述1】+【图片/视频+描述2】'
		]
	}, {
		block: "FS21_01",
		img: require('@/assets/productsDetail/hightlightsImage/FS21_01.jpeg'),
		content: [
			'标题+小标题+标签+描述+图片',
			'注：图片位置可选择左侧或右侧'
		]
	},{
		block: "FS22",
		img: require('@/assets/productsDetail/hightlightsImage/FS22.jpeg'),
		content: [
			'标题+描述+【tag图片+描述】+【tag图片+描述】',
		]
	}, {
		block: "FS24",
		img: require('@/assets/productsDetail/hightlightsImage/FS24.jpeg'),
		content: [
			'标题+图片+icon+描述'
		]
	}, {
		block: "FS24_01",
		img: require('@/assets/productsDetail/hightlightsImage/FS24_01.jpeg'),
		content: [
			'标题+图片/视频+链接文字+链接地址+【icon+描述】'
		]
	}, {
		block: "FS24_02",
		img: require('@/assets/productsDetail/hightlightsImage/FS24_02.jpeg'),
		content: [
			'标题 + 副标题（选填）+ 图片/视频/Tag+【icon+标题（选填）+描述】',
			'注：icon和描述位置可选择上下结构或者左右结构'
		]
	}, {
		block: "FS25",
		img: require('@/assets/productsDetail/hightlightsImage/FS25.jpeg'),
		content: [
			'标题+描述+【图片/视频+（icon+icon描述）】'
		]
	}
]