// feed管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// google-feed管理
const routes: Route[] = [
  {
    "name": "GoogleFeed详情",
    "router": "Feed/GoogleFeed/:id",
    component: lazyload(() => import('@/pages/Feed/GoogleFeed/Detail/index')),
  },
  {
    "name": "feed管理",
    "router": "Feed/GoogleFeed",
    component: lazyload(() => import('@/pages/Feed/GoogleFeed/index')),
  },
]
export default routes;
