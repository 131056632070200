import request from '@/utils/request';

export interface ResType {
  code?: string;
  data?: any;
  msg?: string;
}
// QC认证管理列表分页查询
export function getQcList(data) {
  return request(
    {
      url: `/api/qualityCertification/selectPageList`,
      method: 'post',
      data,
    },
    true
  );
}
// QC认证管理图标列表查询
export function getTagList() {
  return request({
    url: `/api/qualityCertification/selectList`,
    method: 'post',
  });
}

// QC认证管理图标详情
export function getTagDetail(data) {
  return request({
    url: `/api/qualityCertification/selectDetail`,
    method: 'post',
    data,
  });
}

// QC认证管理图标保存
export function saveTag(data) {
  return request({
    url: `/api/qualityCertification/save`,
    method: 'post',
    data,
  });
}

// QC认证管理删除图标
export function deleteTag(data) {
  return request(
    {
      url: `/api/qualityCertification/delete`,
      method: 'post',
      data,
    },
    true
  );
}

//  QC认证获取国家list
export function getCountryListApi() {
  return request(
    {
      url: `/api/qualityCertification/countryList`,
      method: 'get',
    },
    true
  );
}

//  QC认证管理批量绑定
export function batchBind(data) {
  return request(
    {
      url: `/api/qualityCertification/batchBind`,
      method: 'post',
      data,
    },
    true
  );
}

//  QC认证管理查询管理产品
export function getBindProducts(data) {
  return request(
    {
      url: `/api/qualityCertification/getBindProducts`,
      method: 'post',
      data,
    },
    true
  );
}

//  QC认证管理同步资源
export function syncAssetsApi() {
  return request(
    {
      url: `/api/qualityCertification/sync`,
      method: 'post',
    },
    true
  );
}
