import request from '@/utils/request';
export interface ResType{
    code?: string;
    data?: any;
    msg?: string;
  }
// 创建人列表
export default function getCreatorList(data) {
    return request(
      {
        url: '/api/personnel/management/selectUserList',
        method: 'post',
        data,
      }
    );
  }
