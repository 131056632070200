import React, { useEffect, useState } from 'react';
import styles from './style/index.module.less';
import DetailNav from './components/DetailNav';
import FsEditor from '@/components/FsEditor';
import { createFeiShuDocument } from '@/api/resource/documentManager/documentManager';
import { Message } from '@arco-design/web-react';
import { IconPlayArrow } from '@arco-design/web-react/icon';
import Slide from './components/Slide';
import qs from 'query-string';
import { ResType, getGlossaryDetail } from '@/api/GlossaryManager';

export default function Edit() {
  const [src, setSrc] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [isFold, setIsFold] = useState(false);
  const [initValue, setInitValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resourceName, setResourceName] = useState('');

  const search = qs.parseUrl(window.location.href);
  const { id } = search.query;
  const getDetail = () => {
    if (!id) return;
    setLoading(true);
    getGlossaryDetail(id)
      .then((res: ResType) => {
        console.log(res, id, 'res');
        if (res.code === '200') {
          setLoading(false);
          console.log(res, 'res');
          setDocumentId(res.data.documentId);
          setSrc('https://mah2eds8ab.feishu.cn/docx/' + res.data.documentId);
          setInitValue(res.data);
          console.log(res.data.title);
          setTimeout(() => {
            setResourceName(res.data.title);
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getDocument = () => {
    createFeiShuDocument().then((res: ResObject) => {
      console.log(res, 'res');

      if (res.code === '200') {
        setDocumentId(res.data);
        setSrc('https://mah2eds8ab.feishu.cn/docx/' + res.data);
      } else {
        Message.error(res.msg);
      }
    });
  };
  const changeFold = () => {
    setIsFold(!isFold);
  };
  const titleChange = (title) => {
    console.log(title, 'title');

    setResourceName(title);
  };
  useEffect(() => {
    console.log(id, 'id');

    if (id) {
      getDetail();
    } else {
      getDocument();
    }
  }, []);
  return (
    <div className={styles.manager_detail}>
      <DetailNav></DetailNav>
      <div className={styles.edit_content}>
        <div className={styles.edit_content_left}>
          <FsEditor src={src} titleChange={titleChange}></FsEditor>
          <div className={styles.line}>
            <span
              className={isFold ? styles.fold : styles.unfold}
              onClick={() => changeFold()}
            >
              <IconPlayArrow />
            </span>
          </div>
        </div>
        <div
          className={styles.edit_content_right}
          style={{
            width: isFold ? 0 : '480px',
          }}
        >
          <Slide
            documentId={documentId}
            initValue={initValue}
            loading={loading}
            resourceName={resourceName}
            setResourceName={setResourceName}
          ></Slide>
        </div>
      </div>
    </div>
  );
}
