import React, { useRef, useEffect, useState } from 'react';
import { Avatar, Dropdown, Menu, Popover } from '@arco-design/web-react';
import { IconClose, IconRight } from '@arco-design/web-react/icon';
import { useSelector, useDispatch } from 'react-redux';
import { getCategoryList } from '@/store/board/boardSlice';
import { useHistory } from 'react-router-dom';
import {
  removeRouterTag,
  setLocale,
  updateUserInfo,
} from '@/store/user/userSlice';

import { RootState } from '@/store/';
import styles from './style/index.module.less';
import useStorage from '@/utils/useStorage';
import IconSvg from '../IconSvg';
import { updateUserPermission } from '@/api/roleManager';
import ERP from '@/assets/erp.svg';
import indexDB from '@/utils/indexDB';
function Navbar({ show }: { show: boolean }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = history.location.pathname.substring(
    1,
    history.location.pathname.length
  );
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const routerTag = useSelector((state: RootState) => state.user.routerTag);
  const isFold = useSelector((state: RootState) => state.menu.isFold);
  const language_codes = useSelector(
    (state: RootState) => state.user.language_code
  );
  const languageInfo = useSelector(
    (state: RootState) => state.user.languageInfo
  );
  const settings = useSelector((state: RootState) => {
    return state.user.settings;
  });
  const [, setUserStatus] = useStorage('userStatus');
  const tagRef = useRef<HTMLDivElement>(null);
  const [swipeNum, setSwipeNum] = useState(0);
  const menuList = [
    {
      key: '1',
      name: 'ERP系统',
      url: '',
    },
    {
      key: '2',
      name: '修改产品上下架状态',
      url: 'categories_products_manage.php?action=warehouse',
    },
    {
      key: '3',
      name: '修改产品销量',
      url: 'categories_products_manage.php',
    },
    {
      key: '4',
      name: '获取新品ID',
      url: 'categories_products_manage.php?action=products',
    },
    {
      key: '5',
      name: '库存关联',
      url: 'products_instock.php',
    },
    {
      key: '6',
      name: '修改产品标题&型号名',
      url: 'categories_products_manage.php?action=products',
    },
    {
      key: '7',
      name: '添加产品属性',
      url: 'attributes_batch_controller.php',
    },
    {
      key: '8',
      name: '产品层级属性',
      url: 'seo_attribute_custom_manage.php',
    },
  ];
  useEffect(() => {
    if (tagRef.current) {
      const num = tagRef.current.offsetWidth / 132;
      setSwipeNum(Math.ceil(num));
    }
  }, []);

  function logout() {
    setUserStatus('logout');
    localStorage.clear();
    indexDB.clearTable();
    window.location.href = '/login';
  }
  function hasPermission(routes, url) {
    const routesFilter = routes.filter((v) => {
      return url.indexOf(v.router) !== -1;
    });
    return routesFilter.length > 0 ? true : false;
  }
  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    } else {
      dispatch(setLocale(key));
      dispatch(getCategoryList());
      updateUserPermission({}).then(async (res: any) => {
        const { data = {} } = res || {};
        const {
          websiteInfo = [],
          permission = [],
          functionPermissionIdList = [],
        } = data;
        await dispatch(
          updateUserInfo({
            ...userInfo,
            websiteInfo,
            permission,
            functionPermissionIdList,
          })
        );
        // const url = window.location.href
        // if (!hasPermission(data.permission,url)) {
        //   window.location.href = '/Welcome';
        // } else {
        //   window.location.href = window.location.href;
        // }
        history.push('/Welcome');
      });
      // Message.info(`You clicked ${key}`);
    }
  }

  const routerGo = (item) => {
    if (pathname !== item.key) {
      history.push(`/${item.key}`);
    }
  };
  const removeTag = (item) => {
    const currenTagIndex = routerTag.findIndex((tag) => tag.key === item.key);
    const nextTageIndex =
      currenTagIndex !== routerTag.length - 1
        ? currenTagIndex + 1
        : currenTagIndex - 1;
    let nextPath = '/home/OperateDashboard';
    if (routerTag.length > 1) {
      nextPath = `/${routerTag[nextTageIndex].key}`;
    }
    dispatch(removeRouterTag({ ...item }));
    history.push(nextPath);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollRight = () => {
    if (tagRef.current) {
      if (
        tagRef.current.scrollLeft + tagRef.current.offsetWidth <
        tagRef.current.scrollWidth
      ) {
        tagRef.current.scrollLeft += 132;
      }
    }
  };

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick} selectedKeys={[language_codes]}>
      <Menu.Item key="logout">退出登录</Menu.Item>
    </Menu>
  );

  const websiteList = (
    <Menu
      onClickMenuItem={onMenuItemClick}
      selectedKeys={[language_codes]}
      className={styles.website_item}
    >
      {userInfo.websiteInfo &&
        userInfo.websiteInfo.map((item) => {
          return (
            <Menu.Item
              key={item.id}
              style={{
                textAlign: 'center',
                color: `${language_codes == item.id ? '#165DFF' : ''}`,
              }}
            >
              {item.siteName}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  //跳转至用户手册
  const toUserManual = () => {
    window.open(
      'https://mah2eds8ab.feishu.cn/wiki/G5ApwRGDLip57Sk0QPbcKvPznLh'
    );
  };
  const jumpTo = () => {
    console.log(process.env);
    const url = process.env.REACT_APP_ERP_URL;
    window.open(url);
  };
  const menuClick = (key) => {
    const url = process.env.REACT_APP_ERP_URL + menuList[Number(key) - 1].url;
    window.open(url);
  };
  return (
    <div className={styles.navbar}>
      <div
        className={styles.left}
        style={{
          width: isFold ? 54 : settings.menuWidth,
        }}
      >
        <div className={isFold ? styles.logo_fold : styles.logo}>
          <img src="https://resource.fs.com/mall/generalImg/202206161506275riiaf.png" />
        </div>
      </div>
      <div
        className={styles.menuTag}
        style={{ width: 'calc(100% - 633px)' }}
        ref={tagRef}
      >
        {routerTag.length > 0 &&
          routerTag.map((item) => {
            return (
              <div
                ref={tagRef}
                onClick={() => routerGo(item)}
                className={[
                  styles.tagbox,
                  pathname === item.key ? styles.tagActive : '',
                ].join(' ')}
                key={item.key}
              >
                <span>{item.name}</span>
                {pathname === item.key ? (
                  <IconClose
                    onClick={() => removeTag(item)}
                    style={{ paddingLeft: '10px', width: '32px' }}
                  />
                ) : (
                  ''
                )}
              </div>
            );
          })}
      </div>
      <ul className={styles.right}>
        {routerTag.length > swipeNum ? (
          <li>
            <div className={styles.turnRight} onClick={scrollRight}>
              <IconRight />
            </div>
          </li>
        ) : (
          ''
        )}
        <li className={styles.websiteInfo}>
          <div className={styles.website_box}>
            <Dropdown droplist={websiteList} position="bottom">
              <p>{languageInfo?.siteName}</p>
            </Dropdown>
          </div>
        </li>
        <li className={styles.go_erp}>
          {/* <img src={require('@/assets/image/erp.png')}/> */}
          {/* <Popover
            position="bl"
            title=""
            content={
              <div
                style={{
                  borderRadius: '8px',
                  color: '#9FA2B4',
                  fontSize: '13px'
                }}
              >
                ERP系统
              </div>
            }
          >  */}
          <Dropdown
            getPopupContainer={(node: HTMLElement) => node.parentElement}
            position="bl"
            droplist={
              <Menu
                onClickMenuItem={menuClick}
                style={{
                  maxHeight: '100%',
                }}
              >
                {menuList.map((item, index) => {
                  return <Menu.Item key={item.key}>{item.name}</Menu.Item>;
                })}
              </Menu>
            }
          >
            <ERP />
          </Dropdown>
          {/* </Popover> */}
        </li>
        <li className={styles.gohome}>
          <Popover
            position="bl"
            title=""
            content={
              <div
                style={{
                  borderRadius: '8px',
                  color: '#9FA2B4',
                  fontSize: '13px',
                  width: '158px',
                }}
              >
                点击此处查看CMS操作手册
              </div>
            }
          >
            <IconSvg type="icon-fs_2022041429icon" onClick={toUserManual} />
          </Popover>
        </li>
        <li className={styles.line}></li>
        <li className={styles.info}>
          <div className={styles.username}>{userInfo.name}</div>
          <div className={styles.noun}>{userInfo.job}</div>
        </li>
        {userInfo && (
          <li>
            <Dropdown droplist={droplist} position="br">
              <Avatar
                size={44}
                style={{
                  cursor: 'pointer',
                  border: '1.5px solid #DFE0EB',
                }}
              >
                <img alt="avatar" src={userInfo.avatar} />
              </Avatar>
            </Dropdown>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
