import React, { useEffect } from 'react';
import styles from './index.module.less';
import {
  getPdfCoverList,
  previewPdf,
  updatePdfCover,
} from '@/api/resource/documentManager/documentManager';
import { Button, Form, Message, Modal, Spin } from '@arco-design/web-react';
import PdfTable from '../PdfTable';
type PdfViewProps = {
  value?: number;
  onChange?: (number: number) => void;
  documentId?: string;
};
export interface PdfListItem {
  circularColor: string;
  cover: string;
  description: string;
  id: number;
  isDefault: number;
}

const PdfView: React.FC<PdfViewProps> = ({ value, onChange, documentId }) => {
  const [form] = Form.useForm();
  const [pdfList, setPdfList] = React.useState<PdfListItem[]>([]);
  const [activeIndex, setActiveIndex] = React.useState<number>(value);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const [pdfLoading, setPdfLoading] = React.useState<boolean>(false);
  const getPdfListData = async () => {
    try {
      const res: any = await getPdfCoverList();
      if (res.code === '200') {
        const { data = [] } = res;
        if (!value) {
          setActiveIndex(data[0]?.id);
        }
        setPdfList(data);
      } else {
        Message.error(res.msg);
      }
    } catch (error) {
      Message.error('获取数据失败');
    }
  };
  const changeActiveIndex = (index: number) => {
    setActiveIndex(index);
    onChange && onChange(index);
  };
  const handleOk = async () => {
    try {
      await form.validate();
      const params = form.getFieldsValue();
      console.log(params);
      //找出删除的数据，并加上isDelete 1代表删除
      const deleteList = pdfList
        .filter((item) => !params.pdfList.some((i) => i.id === item.id))
        ?.map((item) => ({ ...item, isDelete: 1 }));
      setSaveLoading(true);
      updatePdfCover({
        covers: [...params.pdfList, ...deleteList],
      })
        .then((res: any) => {
          if (res.code === '200') {
            Message.success('保存成功');
            setVisible(false);
            getPdfListData();
          } else {
            Message.error(res.msg);
          }
        })
        .finally(() => {
          setSaveLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // 预览pdf逻辑
  const preview = (res: any) => {
    if (res.code === '40001') {
      Message.error(res.msg);
    } else {
      const blob = new Blob([res.data], { type: 'application/pdf' });
      // 创建一个指向PDF文件的URL
      const pdfUrls = window.URL.createObjectURL(blob);
      // 打开新页面预览PDF
      window.open(pdfUrls);
    }
  };
  const handlePreview = () => {
    setPdfLoading(true);
    previewPdf({
      coverId: activeIndex,
      documentId,
    })
      .then((res: any) => {
        preview(res);
      })
      .catch(() => {
        Message.error('预览失败');
      })
      .finally(() => {
        setPdfLoading(false);
      });
  };
  useEffect(() => {
    onChange && onChange(activeIndex);
  }, [activeIndex]);
  useEffect(() => {
    if (value) {
      setActiveIndex(value);
    }
  }, [value]);
  React.useEffect(() => {
    getPdfListData();
  }, []);
  return (
    <div className={styles['pdf-view']}>
      <div className={styles.header}>
        <div className={styles.title}>PDF预览</div>
        <Spin loading={pdfLoading}>
          <div className={styles.operation}>
            <span onClick={handlePreview}>PDF下载预览</span>
            <span
              onClick={() => {
                setVisible(true);
              }}
            >
              编辑封面
            </span>
          </div>
        </Spin>
      </div>
      {/* 卡片展示区域 */}
      <div className={styles['card-wrapper']}>
        {pdfList.map((item, index) => (
          <div
            key={item.id}
            className={[
              styles.card,
              activeIndex === item.id ? styles['card-active'] : '',
            ].join(' ')}
            onClick={() => changeActiveIndex(item.id)}
          >
            <div className={styles['card-img']}>
              <img src={item.cover} alt="" />
              <div className={styles['card-mask']}></div>
            </div>
            <div className={styles['card-title']}>
              <span>{item.description}</span>
            </div>
          </div>
        ))}
      </div>
      {/* 编辑pdf 弹窗 */}
      {visible && (
        <Modal
          title={<div style={{ textAlign: 'left' }}>编辑封面</div>}
          maskClosable={false}
          focusLock={false}
          visible={visible}
          style={{ width: 750 }}
          onCancel={() => {
            setVisible(false);
          }}
          footer={
            <div>
              <Button
                type="secondary"
                shape="round"
                onClick={() => {
                  setVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                shape="round"
                type="primary"
                loading={saveLoading}
                style={{
                  marginLeft: '16px',
                }}
                onClick={handleOk}
              >
                确定
              </Button>
            </div>
          }
        >
          <>
            <Form form={form}>
              <PdfTable pdfList={pdfList} form={form} />
            </Form>
          </>
        </Modal>
      )}
    </div>
  );
};

export default PdfView;
