import React, { useEffect, useState } from 'react';
import { userLogin } from '@/api/user';
import { updateUserInfo, setInitData, setLocale } from '@/store/user/userSlice';
import { Message } from '@arco-design/web-react';
import store from '@/store';
import qs from 'query-string';
interface ResType {
  code: string;
  data: any;
  msg: string;
}

export default function Index() {
  const params = qs.parseUrl(window.location.href);
  const { query } = params;
  const [isRedirect, setIsRedirect] = useState(false);
  useEffect(() => {
    const { code, route = '', language_id } = query;
    if (isRedirect) return;
    // 重置用户信息
    store.dispatch(setInitData({}));
    localStorage.clear();
    userLogin({ code })
      .then((res: ResType) => {
        console.log(process.env.REACT_APP_APP_ID);
        console.log(res);
        if (!res.data) {
          Message.error('登录数据异常');
          return;
        }
        const { websiteInfo = [] } = res.data || {};
        store.dispatch(updateUserInfo(res.data));
        if (language_id) {
          store.dispatch(setLocale(language_id));
        } else {
          const defaultLanguageId = res.data.defaultLanguageId
            ? res.data.defaultLanguageId
            : websiteInfo[0]?.id;
          store.dispatch(setLocale(defaultLanguageId));
        }
        // debugger;
        setIsRedirect(true);
        if (route) {
          window.location.href = decodeURIComponent(route as string);
        } else {
          window.location.href = '/Welcome';
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isRedirect]);
  // return;
  return <div>正在进入系统</div>;
}
