// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 页面编辑、模板管理详情路由
const routes: Route[] = [
  {
    name: '查看产品',
    router: 'home/ProductBindManager/lookBindingProduct/:type',
    component: lazyload(
      () => import('@/pages/home/ProductBindManager/lookProduct')
    ),
  },
  {
    name: '查看详情',
    router: 'home/MyProducts/Detail/:id',
    component: lazyload(() => import('@/pages/home/MyProducts/Detail/index')),
  },
  {
    name: '我的产品',
    router: 'home/MyProducts',
    component: lazyload(() => import('@/pages/home/MyProducts/index')),
  },
];
export default routes;
