import { ColorType } from './types';

export const tagColorList = [
  {
    textColor: '#43A097',
    bgColor: '#D9F2F0',
  },
  {
    textColor: '#5C89FF',
    bgColor: '#E3ECFF',
  },
  {
    textColor: '#C55E50',
    bgColor: '#FDDCBA',
  },
  {
    textColor: '#CD3E3E',
    bgColor: '#FFC5C5',
  },
  {
    textColor: '#79891C',
    bgColor: '#F3FFAB',
  },
  {
    textColor: '#D22EAF',
    bgColor: '#FCCAF1',
  },
  {
    textColor: '#B349C5',
    bgColor: '#E8CBFF',
  },
  {
    textColor: '#388CDB',
    bgColor: '#BEDFFF',
  },
  {
    textColor: '#00B42A',
    bgColor: '#E8FFEA',
  },
  {
    textColor: '#B56325',
    bgColor: '#F2CE8F',
  },
  {
    textColor: '#8C53DA',
    bgColor: '#F5E8FF',
  },
  {
    textColor: '#338147',
    bgColor: '#B3E8C6',
  },
];

//根据下标循环得到当前的颜色
export function getColor(index: number, colorList: ColorType[]) {
  return colorList[index % colorList.length];
}
