import { combineReducers, configureStore } from '@reduxjs/toolkit';
import counterReducer from './user/userSlice';
import board from './board/boardSlice';
import menu from './menu/menuSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
const persistConfig = {
  key: 'root',
  storage,
};
const reducers = combineReducers({
  user: counterReducer,
  board,
  menu
});

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // 解决redux-persist持久化问题，每次刷新页面，redux-persist会把redux重置为初始状态，这里设置为false，不重置
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
  },

});
export default store;
export type RootState = ReturnType<typeof store.getState>;

