import React, { useEffect, useState } from 'react';
import { IconPlus } from '@arco-design/web-react/icon';
import styles from './index.module.less';
import ImageSelect from '@/components/ImageSelect';
import IconSvg from '@/components/IconSvg';
type PropsType = {
  onChange?: (value: string) => void;
  value?: string;
  fileType?: string[];
  label?: string;
};

const CustomImage: React.FC<PropsType> = ({
  onChange,
  value,
  fileType = ['jpeg', 'png', 'PNG'],
  label,
}) => {
  const [imageSelectVisible, setImageSelectVisible] = useState<boolean>(false);
  const handleOk = (data: any) => {
    const imagePath =
      Array.isArray(data) && data.length > 0 && data[0].picturePath;
    setImageSelectVisible(false);
    onChange && onChange(imagePath);
  };
  const onCancel = () => {
    console.log('取消');
    setImageSelectVisible(false);
  };
  return (
    <>
      <div className={styles['image-view']}>
        {label ? <span className={styles['image-label']}>{label}</span> : null}
        <div className={styles['image-view-content']}>
          {value ? (
            <div className={styles['image-view-content-preview']}>
              <img src={value} alt="" />
              <div
                className={styles['image-view-content-preview-mask']}
                onClick={() => {
                  // setUploadVisible(true);
                }}
              >
                <span
                  className={styles['image-view-content-preview-mask-icon']}
                  onClick={(e) => {
                    e.stopPropagation();
                    setImageSelectVisible(true);
                  }}
                >
                  <IconSvg type="icon-fs_2022041415icon" />
                </span>
                <span
                  className={styles['image-view-content-preview-mask-icon']}
                  onClick={(e) => {
                    onChange && onChange('');
                  }}
                >
                  <IconSvg type="icon-fs_2022041418icon" />
                </span>
              </div>
            </div>
          ) : (
            <div
              className={styles['image-view-content-add']}
              onClick={(e) => {
                e.stopPropagation();
                setImageSelectVisible(true);
              }}
            >
              <IconPlus />
              <span>图片资源库调入</span>
            </div>
          )}
        </div>
        {/* 资源选择 */}
      </div>

      <ImageSelect
        imageSelectVisible={imageSelectVisible}
        type={'image'}
        handleOk={handleOk}
        onCancel={onCancel}
        imageLimt={fileType}
        limitMessage={`只允许${fileType.join('、')}格式的文件`}
        tableType={'radio'}
      />
    </>
  );
};

export default CustomImage;
