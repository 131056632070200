import React, { useEffect, useState } from 'react';
import { Select } from '@arco-design/web-react';
import {
  getArticleAllTitleList,
  getBlogArticleList,
} from '@/api/pageManager/Blog/BlogEditor';
import debounce from 'lodash/debounce';
type PropsType = {
  onChange: (value: any) => void;
  value: any;
};

const CustomSelect: React.FC<PropsType> = ({ onChange, value }) => {
  const [option, setOption] = useState([]);
  const handleSelectChange = (value: string) => {
    // const newItems = items?.map((item) => {
    //   if (item.id === id) {
    //     return { ...item, selectedValue: value };
    //   }
    //   return item;
    // });
    // setItems(newItems);
    onChange(value);
    console.log(value);
  };
  const handleSearch = debounce(async (searchTerm: string) => {
    try {
      const res: any = await getArticleAllTitleList({
        search: searchTerm,
      });
      // setPageInfo({})
      const data = res?.data || [];
      // 这里可以进一步处理 options，例如过滤、格式化等
      const newData = data.map((item: any) => {
        return {
          label: item.id + '-' + item.title,
          value: item.id,
        };
      });
      console.log('newData', newData);

      setOption(newData);
    } catch (error) {
      console.error('Search error:', error);
    }
  }, 500);
  useEffect(() => {
    handleSearch('');
  }, []);
  return (
    <Select
      // value={item.itemValue}
      showSearch
      onChange={(value) => handleSelectChange(value)}
      onSearch={handleSearch}
      filterOption={false}
      placeholder="请选择文章"
      style={{ width: 392 }}
      options={option}
      value={value}
    />
  );
};

export default CustomSelect;
