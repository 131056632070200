/*
 * @Author: ajay
 * @Date: 2022-09-01 12:04:12
 * @LastEditors: ajay
 * @LastEditTime: 2022-09-09 12:25:07
 * @Description: 请填写简介
 */
import React, { useContext } from 'react';
import styles from './style/index.module.less';
import { ProductsContext } from '../../index'
import classNames from 'classnames';
import { handleResourcePath } from '@/utils/utils'


export default function SoftwareSpotlights(props: any) {
	const context = useContext(ProductsContext);
	const { data } = props;

	return (
		<>
			{
				data  ? (
					<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.software_spotlights_wrap])} onClick={() => context.openSiderBox({
						componentId: data.componentId,
						block: data.block || '',
						type: data.type,
					})}>
						<div className={styles.software_spotlights_title}>{data?.data?.title||''}</div>
						<div className={styles.software_spotlights_conetnt}>
							{
								(data && data.data && data.data.contentList) ? (<div className={styles.software_spotlights_list}>
									{
										data.data.contentList.map((item, index) => {
											return (
												<div className={styles.software_spotlights_box} key={index}>
													<div className={styles.spotlights_img_box}>
														<img src={handleResourcePath(item.image,false)} alt="" />
													</div>
													<div className={styles.spotlights_title} dangerouslySetInnerHTML={{ __html: item.title }}></div>
													{
														(item.textList && item.textList.length) ? (<div className={styles.spotlights_list}>
															{
																item.textList.map((ct, ci) => {
																	return (<div className={styles.spotlights_item} key={ci} dangerouslySetInnerHTML={{ __html: ct }}>
																	</div>)
																})
															}
														</div>) : null
													}
												</div>
											)
										})
									}
								</div>) : (<div className={styles.software_spotlights_empty}>产品亮点内容区域</div>)
							}
						</div>
					</div>
				) : null
			}
		</>
	)
}	