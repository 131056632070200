import request from '@/utils/request';
export interface ResType {
  code?: string;
  data?: any;
  msg?: string;
}
export interface ApplicationType {
  productsId: string;
  productsIdList: string[];
}

export interface CategoryType {
  productId: number;
}

//获取产品详情数据
export function getProductsDetail(data: object) {
  return request(
    {
      url: '/api/products/selectProductComponent',
      method: 'post',
      data,
    },
    true
  );
}

//获取产品列表数据
export function getProductsListApi(data: object) {
  return request(
    {
      url: '/api/products/selectList',
      method: 'post',
      data,
    },
    false
  );
}

//ProductHighlight排序
export function sortProductHighlightApi(data: object) {
  return request(
    {
      url: '/api/platformTemplate/sortProductHighlight',
      method: 'post',
      data,
    },
    true
  );
}

//importTemplate
export function importTemplateApi(data: object) {
  return request(
    {
      url: '/api/products/importTemplate',
      method: 'post',
      data,
    },
    false
  );
}

//获取所有模板列表
export function templateListApi(data: object) {
  return request(
    {
      url: '/api/platformTemplate/selectPageList',
      method: 'post',
      data,
    },
    false
  );
}

//模板保存

export function templateSaveApi(data: object) {
  return request(
    {
      url: '/api/platformTemplate/save',
      method: 'post',
      data,
    },
    true
  );
}

//产品信息存为模板
export function saveTemplateApi(data: object) {
  return request(
    {
      url: '/api/products/saveTemplate',
      method: 'post',
      data,
    },
    true
  );
}

//产品信息提交
export function submitTemplateApi(data: object) {
  return request(
    {
      url: '/api/products/submitTemplate',
      method: 'post',
      data,
    },
    true
  );
}

//修改楼层状态
export function changeComponentStatusApi(data: object) {
  return request(
    {
      url: '/api/platformTemplate/updateComponentStatus',
      method: 'post',
      data,
    },
    true
  );
}

//复制模板
export function copyTemplateApi(data: object) {
  return request(
    {
      url: '/api/platformTemplate/copy',
      method: 'post',
      data,
    },
    false
  );
}

//删除products——hightlights板块
export function deleteProductsHightlights(data: object) {
  return request(
    {
      url: '/api/platformTemplate/deleteProductHighlight',
      method: 'post',
      data,
    },
    true
  );
}
//获取产品标签
export function getProductTagList() {
  return request({
    url: '/api/productLabel/selectTree',
    method: 'post',
  });
}
// 获取Feed产品标签
export function getFeedProductTagList() {
  return request({
    url: '/api/feeds/productLabel/selectTree',
    method: 'get',
  });
}
//商详页查询产品关联组列表
export function getProductAttrList(params) {
  return request({
    url: `/api/product/attribute/detail/list`,
    method: 'get',
    params,
  });
}

//详情页产品对比批量应用
export function productApplication(params: ApplicationType) {
  return request({
    url: `/api/products/applyProductCompare`,
    method: 'post',
    data: params,
  });
}

//获取产品的分类列表
export function getProductCategory(data: CategoryType) {
  return request({
    url: `/api/products/getProductCategory`,
    method: 'post',
    data,
  });
}

//提交校验同型号的产品
export function checkSameProduct(data: { productsIdList: number[] }) {
  return request({
    url: `/api/products/checkSubmit`,
    method: 'post',
    data,
  });
}

//获取产品规格参数
export function getProductParameters(data: { productId: string }) {
  return request({
    url: `/api/platformTemplate/getProductParameters`,
    method: 'get',
    params: data,
  });
}

//获取产品的分类列表
export function getMallCategory() {
  return request({
    url: `/api/product/category/tree`,
    method: 'get',
  });
}
