import { FilterParams } from '@/enum/FilterList';
import request from '@/utils/request';

// 获取模版创建人列表
interface GetCreatorListParams {
  search: string;
}

export function getCreatorList(data: GetCreatorListParams) {
  return request(
    {
      url: '/api/platformTemplate/selectCreatedUserList',
      method: 'post',
      data,
    },
    true
  );
}

// 获取/查询 模版列表
export interface GetTemplateListParams extends FilterParams {
  search?: string;
  current: number;
  size: number;
  createdUserId?: number | string;
  startDate?: string;
  endDate?: string;
}

export function getTemplateList(data: GetTemplateListParams) {
  return request(
    {
      url: '/api/platformTemplate/selectPageList',
      method: 'post',
      data,
    },
    true
  );
}

// 批量删除模版
export interface DeleteTemplateParams {
  templateIdList: Array<number>;
}

export function delTemplate(data: DeleteTemplateParams) {
  return request(
    {
      url: '/api/platformTemplate/batchDelete',
      method: 'post',
      data,
    },
    true
  );
}

// 新增/保存模板

interface AddTemplateParams {
  templateName: string;
}

export function addTemplate(data: AddTemplateParams) {
  return request({
    url: '/api/platformTemplate/save',
    method: 'post',
    data,
  });
}

// 复制模板
export function copyTemplate(data: { templateId: number }) {
  return request(
    {
      url: '/api/platformTemplate/copy',
      method: 'post',
      data,
    },
    true
  );
}

// 产品ID模糊查询
export function getProductList(data: { id: number }) {
  return request({
    url: `/api/products/fuzzySearch/${data.id}`,
    method: 'get',
  });
}

// 产品应用模板
interface ProductApplyTemplateParams {
  productsIdList: Array<number>;
  templateId: number;
}

export function productApplyTemplate(data: ProductApplyTemplateParams) {
  return request({
    url: '/api/platformTemplate/applyProducts',
    method: 'post',
    data,
  });
}

// 获取ProductList
export function selectBatchEditProductList(data: { productsIdList: number[] }) {
  return request({
    url: '/api/products/selectBatchEditProductList',
    method: 'post',
    data,
  });
}
// 批量保存
export function submitTemplateBatch(data: { productsIdList: number[] }) {
  return request({
    url: '/api/products/submitTemplateBatch',
    method: 'post',
    data,
  });
}
