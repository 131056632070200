const files = require.context('./', true, /\.ts$/);
let totalApi: Record<string, any> = {};
// 约束一个变量为函数类型
files.keys().forEach((key) => {
  if (key != './index.ts') {
    console.log(key);
    totalApi = { ...totalApi, ...files(key) };
  }
});
// totalApi数据结构=>{selectTreeNodeList：function(){}}

export default totalApi;
