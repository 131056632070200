/*
 * @Author: Jabin
 * @Date: 2023-02-08 15:55:46
 * @LastEditors: Jabin
 * @LastEditTime: 2023-02-08 15:56:58
 * @Descripttion:
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@/api/pageManager';
import { groupList, getInfomation } from '@/api/operationBoard';
import { FeedsCategory } from '@/api/Feed/googleFeed';
export interface GlobalState {
  categoryList: Array<any>;
  selectGroup: Array<any>;
  categoryListLoading: boolean;
  groupInfoLoading: boolean;
  loaded: Array<string>;
  defaultGroup: any;
  operationGroupId: any;
  operationAdminId: number;
}
const initialState: GlobalState = {
  // 分类筛选
  categoryList: [],
  // 运营小组列表
  selectGroup: [],
  defaultGroup: 18,
  categoryListLoading: false,
  groupInfoLoading: true,
  operationGroupId: undefined,
  operationAdminId: undefined,
  loaded: [],
};
// 分类筛选下拉列表
export const getCategoryList = createAsyncThunk<any, number | void>(
  'board/getCategoryList',
  async (params: number | void = 1, thunkAPI) => {
    const data = {
      categoriesType: params, //  1:Product type,2:Network type 默认获取Product type
    };
    const res = await api.selectTreeNodeList(data);
    return res;
  }
);

// 运营小组列表
export const getSelectGroup = createAsyncThunk(
  'board/getSelectGroup',
  async (params, thunkAPI) => {
    const res = await groupList();
    return res;
  }
);

// 运营小组信息
export const getGroupInfo = createAsyncThunk(
  'board/getGroupInfo',
  async (params, thunkAPI) => {
    const res = await getInfomation();
    return res;
  }
);
export const slice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    changeloaded: (state, action) => {
      state.loaded.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.pending, (state, action) => {
        state.categoryListLoading = true;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.categoryList = action.payload.data;
        state.categoryListLoading = false;
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.categoryListLoading = false;
      })
      .addCase(getSelectGroup.fulfilled, (state, action: any) => {
        if (action.payload.data && action.payload.data.length) {
          state.selectGroup = action.payload.data;
        } else {
          state.selectGroup = [];
        }
      })
      .addCase(getGroupInfo.pending, (state, action) => {
        state.groupInfoLoading = true;
      })
      .addCase(getGroupInfo.fulfilled, (state, action: any) => {
        if (action.payload.data) {
          state.operationGroupId = action.payload.data.groupId;
          state.defaultGroup = action.payload.data.groupId;
          state.operationAdminId = action.payload.data.adminId;
        } else {
          state.operationGroupId = '';
          state.defaultGroup = '';
          state.operationAdminId = undefined;
        }
        state.groupInfoLoading = false;
      })
      .addCase(getGroupInfo.rejected, (state, action) => {
        state.groupInfoLoading = false;
      });
  },
});

export const { changeloaded } = slice.actions;
export default slice.reducer;
