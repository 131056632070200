import request from '@/utils/request';
import { BlogArticleListType } from './types';

export interface ResType {
  code?: string;
  data?: any;
  msg?: string;
}

// 标签列表
export function getTagList(data) {
  return request({
    url: '/api/blog/tag/list',
    method: 'post',
    data,
  });
}
//blog来源分类查询
export function getBlogSourceType() {
  return request({
    url: '/api/blog/article/sourceType',
    method: 'post',
  });
}
//blog分类列表查询
export function getBlogCategoryList(data) {
  return request({
    url: '/api/blog/blogCategory/list',
    method: 'post',
    data,
  });
}
// 获取所有文章标题
export function getArticleAllTitleList(data) {
  return request({
    url: '/api/blog/article/getArticlesTitles',
    method: 'post',
    data,
  });
}
// 文章列表
export function getBlogArticleList(data: BlogArticleListType) {
  return request({
    url: '/api/blog/article/list',
    method: 'post',
    data,
  });
}

// 获取排序列表
export function getBlogArticleSortList() {
  return request({
    url: '/api/blog/article/getSortAtricle',
    method: 'post',
  });
}

// 更新排序文章
export function updateBlogArticleSort(data) {
  return request({
    url: '/api/blog/article/updateSortAtricle',
    method: 'post',
    data,
  });
}

// 文章详情
export function getBlogArticleDetail(data) {
  return request({
    url: '/api/blog/article/detail',
    method: 'post',
    data,
  });
}
// 获取作者
export function getAuthorList(data) {
  return request({
    url: '/api/blog/getAuthorName',
    method: 'post',
    data,
  });
}
// 新增文章
export function addBlogArticle(data) {
  return request({
    url: '/api/blog/article/add',
    method: 'post',
    data,
  });
}
// 编辑文章
export function editBlogArticle(data) {
  return request(
    {
      url: '/api/blog/article/edit',
      method: 'post',
      data,
    },
    true
  );
}
// 删除文章
export function deleteBlogArticle(data) {
  return request({
    url: '/api/blog/article/del',
    method: 'post',
    data,
  });
}
// 更新发布状态
export function updateBlogArticleStatus(data) {
  return request({
    url: '/api/blog/article/updateStatus',
    method: 'post',
    data,
  });
}

// 上传excel路径
export function uploadExcel(data) {
  return request(
    {
      url: '/api/blog/article/uoloadArticleExcel',
      method: 'post',
      data,
    },
    true
  );
}
