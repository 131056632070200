import request from '@/utils/request';

// 运营小组列表查询
export function groupList() {
  return request({
    url: '/api/operation/selectGroupList',
    method: 'post',
  });
}
// 运营人员列表查询
export function operationUser(data) {
  return request({
    url: '/api/operation/selectAdminList',
    method: 'post',
    data,
  });
}
// 数据看板版块指标详情列表查询
export function operationDetail(data) {
  return request(
    {
      url: '/api/data/board/selectTargetPageList',
      method: 'post',
      data,
    },
    true
  );
}

// 数据看板指标详情查询
export function detailSearch(data) {
  return request({
    url: '/api/data/board/selectTargetDetail',
    method: 'post',
    data,
  });
}

// 组件规则详情查询
export function ruleSearch(data) {
  return request({
    url: '/api/data/board/selectComponentNormDetail',
    method: 'post',
    data,
  });
}

// 数据看板首页统计查询
export function statistics(data) {
  return request(
    {
      url: '/api/data/board/selectSectionCount',
      method: 'post',
      data,
    },
    true
  );
}

//
// 团队整体完成情况列表查询
export function groupComplete(data) {
  return request(
    {
      url: '/api/data/board/selectGroupComplete',
      method: 'post',
      data,
    }
    // true
  );
}

// 运营小组产品数量列表查询
export function groupProduct() {
  return request(
    {
      url: '/api/data/board/selectGroupProduct',
      method: 'post',
    }
    // true
  );
}

// 获取当前角色所属运营小组
export function getInfomation() {
  return request(
    {
      url: '/api/data/board/selectLoginOperationAdmin',
      method: 'post',
    }
    // true
  );
}
