export enum tagTextColor {
  '#43A097',
  '#5C89FF',
  '#C55E50',
  '#CD3E3E',
  '#79891C',
  '#D22EAF',
  '#B349C5',
  '#388CDB',
  '#00B42A',
  '#B56325',
  '#8C53DA',
  '#338147',
}

export enum tagBgColor {
  '#D9F2F0',
  '#E3ECFF',
  '#FDDCBA',
  '#FFC5C5',
  '#F3FFAB',
  '#FCCAF1',
  '#E8CBFF',
  '#BEDFFF',
  '#E8FFEA',
  '#F2CE8F',
  '#F5E8FF',
  '#B3E8C6',
}

export const frontStatusList = [
  {
    label: '全部',
    value: undefined,
  },
  {
    label: '展示中',
    value: 1,
  },
  {
    label: '未展示',
    value: 0,
  },
];

export const topSearchList = [
  {
    label: '全部',
    value: undefined,
  },
  {
    label: '是',
    value: 1,
  },
  {
    label: '否',
    value: 0,
  },
];

export const sortList = [
  {
    label: '按更新日期倒序排列',
    value: 1,
  },
  {
    label: '按更新日期正序排列',
    value: 2,
  },
  {
    label: '阅读量从高到低',
    value: 3,
  },
  // {
  //   label: '点赞量从高到低',
  //   value: 4,
  // },
];
