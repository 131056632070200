import request from '@/utils/request';

export function getEditList(data) {
  return request({
    url: `/api/resource/list`,
    method: 'post',
    data,
  });
}
//  文档资源列表查询2.0
export function selectPageList(data) {
  return request({
    url: `/api/resource/selectPageList`,
    method: 'post',
    data,
  });
}
//  编辑文档资源详情2.0
export function detail(data) {
  return request({
    url: `/api/resource/detail?id=${data.id}`,
    method: 'post',
  });
}
export function updateTag(data) {
  return request({
    url: `/api/resource/tagUpdate`,
    method: 'post',
    data,
  });
}
export function delDocEdit(data) {
  return request({
    url: `/api/resource/relationDelete`,
    method: 'post',
    data,
  });
}
// 删除文档资源2.0
export function removeResource(data) {
  return request({
    url: `/api/resource/removeResource`,
    method: 'post',
    data,
  });
}
export function addDocEdit(data) {
  return request({
    url: `/api/resource/relationAdd`,
    method: 'post',
    data,
  });
}
// 上传文档资源2.0
export function addResource(data) {
  return request({
    url: `/api/resource/addResource`,
    method: 'post',
    data,
  });
}

// 上传文档资源2.0 文档资源校验
export function checkUpload(data) {
  return request({
    url: `/api/resource/checkUpload`,
    method: 'post',
    data,
  });
}
export function editDocEdit(data) {
  return request({
    url: `/api/resource/relationUpdate`,
    method: 'post',
    data,
  });
}
export function editResource(data) {
  return request({
    url: `/api/resource/editResource`,
    method: 'post',
    data,
  });
}

// 文档资源网页版是否展示修改2.0
export function updateIsShow(data) {
  return request({
    url: `/api/resource/updateIsShow`,
    method: 'post',
    data,
  });
}

// 文档资源NEW标签显示隐藏修改
export function updateIsShowNew(data) {
  return request({
    url: `/api/resource/updateIsShowNew`,
    method: 'post',
    data,
  });
}

// 文档资源发布状态调整
export function updateIsPublished(data) {
  return request({
    url: `/api/resource/updateIsPublished`,
    method: 'post',
    data,
  });
}

// 上传
export function upload(data) {
  return request({
    url: `/api/resource/upload`,
    method: 'post',
    data,
  });
}

// 下载
export function download(data) {
  return request({
    url: `/api/resource/download`,
    method: 'post',
    responseType: 'blob',
    data,
  });
}
