// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 产品绑定路由
const routes: Route[] = [
  {
    name: '产品绑定',
    router: 'home/ProductBindManager/BindProduct',
    component: lazyload(
      () => import('@/pages/home/ProductBindManager/BindProduct')
    ),
  },
];
export default routes;
