import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';
const fileRoutes = require.context('./', false, /\.ts$/);
const totalRoutes: Route[] = [];
fileRoutes.keys().forEach((key) => {
  if (key != './index.ts') {
    totalRoutes.push(...fileRoutes(key).default);
  }
});
export const moduleRoutes: Route[] = [
  //固定注册路由，无需权限
  {
    name: '欢迎页',
    router: 'Welcome',
    component: lazyload(() => import('@/pages/Welcome/index')),
  },
  {
    name: 'qingchu huanc',
    router: 'ClearCache',
    component: lazyload(() => import('@/pages/ClearCache/index')),
  },
  ...totalRoutes,
];
