import request from '@/utils/request';

interface ComponentTemplateAdd {
  templateName: string;
  type: number;
  isCopy: number;
  copyTemplateId: number;
}

interface FloorAdd {
  id: number;
  name: string;
  sort: number;
  status: number;
  detailId: number;
}

interface componentTemplateList {
  createUser?: number;
  current?: number;
  endDate?: string;
  search?: string;
  size?: number;
  startDate?: string;
}
interface FloorStyle {
  floorId: number;
  style: any;
}
// 组件库列表
export function libraryList() {
  return request({
    url: '/api/componentTemplate/library/list',
    method: 'get',
  });
}

// 新增模版
export function componentTemplateAdd(data: ComponentTemplateAdd) {
  return request({
    url: '/api/componentTemplate/add',
    method: 'post',
    data,
  });
}

// 模版删除操作
export function componentTemplateDel(data) {
  return request({
    url: `/api/componentTemplate/del`,
    method: 'post',
    data,
  });
}

// 新增模版楼层
export function floorAdd(data: FloorAdd) {
  return request({
    url: '/api/componentTemplate/floor/add',
    method: 'post',
    data,
  });
}

// 楼层组件暂存
export function componentAdd(data: {
  floorInfoItemList: any[];
  detailId: number;
}) {
  return request({
    url: '/api/componentTemplate/floor/component/submit',
    method: 'post',
    data,
  });
}

// 楼层删除
export function floorDel(id) {
  return request({
    url: `/api/componentTemplate/floor/del/${id}`,
    method: 'get',
  });
}

// 模版列表
export function componentTemplateList(data: componentTemplateList) {
  return request({
    url: '/api/componentTemplate/list',
    method: 'post',
    data,
  });
}

// 模版详情
export function detail(id: number) {
  return request({
    url: `/api/componentTemplate/detail/${id}`,
    method: 'get',
  });
}

// 修改模版楼层排序
export function sortModify(data) {
  return request({
    url: '/api/componentTemplate/floor/sort/modify',
    method: 'post',
    data,
  });
}

// 模版楼层样式修改
export function floorStyle(data: FloorStyle) {
  return request({
    url: '/api/componentTemplate/floor/style',
    method: 'post',
    data,
  });
}

// 组件编辑回显
export function componentEdit(id) {
  return request({
    url: `/api/componentTemplate/detail/component/${id}`,
    method: 'get',
  });
}

// 楼层组件提交
export function componentSubmit(data) {
  return request({
    url: '/api/componentTemplate/floor/component/submit',
    method: 'post',
    data,
  });
}

// 模版绑定的楼层信息
export function floorData(id) {
  return request({
    url: `/api/componentTemplate/detail/floor/${id}`,
    method: 'get',
  });
}

// 获取组件数据结构
export function getData() {
  return request({
    url: '/api/component/struct/36',
    method: 'get',
  });
}
export default {
  libraryList,
  componentTemplateAdd,
  componentTemplateDel,
  floorAdd,
  componentAdd,
  floorDel,
  componentTemplateList,
  detail,
  sortModify,
  floorStyle,
  componentEdit,
  componentSubmit,
  floorData,
  type: 'template',
};
