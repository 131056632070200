// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 页面编辑、模板管理详情路由
const routes: Route[] = [
  {
    name: '页面编辑详情',
    router: 'PageEditList/Detail/:id',
    component: lazyload(() => import('@/pages/PageManager/Detail')),
  },
  {
    name: '一级分类编辑页',
    router: 'PageManager/SubjectManager/PrimarySubject/:subjectId',
    component: lazyload(
      () => import('@/pages/PageManager/SubjectManager/Detail')
    ),
  },
  // {
  //   name: '模版管理详情',
  //   router: 'PageManager/ProductDetailManager/PageEditList/Detail',
  // },
  {
    name: '列表页详情',
    router: 'PageManager/ProductListManager/PageListDetail/:id',
    component: lazyload(
      () => import('@/pages/PageManager/ProductListManager/ListDetail')
    ),
  },
  {
    name: 'caseStudy详情页',
    router: 'PageManager/CaseStudyManage/Detail',
    component: lazyload(
      () => import('@/pages/PageManager/CaseStudyManage/Detail')
    ),
  },
  {
    name: '模版详情',
    router: 'PageManager/TemplateManage/:id',
    component: lazyload(
      () => import('@/pages/PageManager/TemplateManage/Detail')
    ),
  },
];
export default routes;
