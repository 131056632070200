import React, { useContext } from 'react'
import classNames from 'classnames';
import { handleResourcePath } from '@/utils/utils'
import { ProductsContext } from '../../../../index'

import ProductsCompare from '@/assets/productsDetail/products_compare.svg'
import styles from './style/index.module.less'


export default function FsSwitchTable(props: any) {

	const { data } = props;
	const context = useContext(ProductsContext);
	console.log(data,'999')
	return (
		<>
			{
				data ? (
					<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.fs_switch_table_wrap])} onClick={() => context.openSiderBox({
						componentId: data.componentId,
						block: data.block || '',
						type: data.type,
					})}>
						{
							data.data && data.data.table && data.data.table.length ? (<table className={styles.table}>
								{
									data.data.table[0] && data.data.table[0].length ? (<thead>
										<tr>
											{
												data.data.table[0].map((h, hi) => {
													return (
														<th className={classNames({ [styles.th_hightlight]: h.is_lighted })} key={hi}>
															<div className={styles.products_box}>
																{
																	h.unit && h.unit.url ? (<a className={styles.products_img_box} href={`https://www.fs.com/${h.unit.url}`}>
																		<img className={styles.products_img} src={handleResourcePath(h.unit.image || h.unit.imageM, false)} alt="" />
																	</a>) : null
																}
																{
																	h.unit.content && h.unit.content ? (
																		<div className={styles.products_text} dangerouslySetInnerHTML={{ __html: h.unit.content }}></div>
																	) : null
																}
																{/* {

																	h.unit.content && h.unit.content.length ? (
																		<div className={styles.product_list}>
																			{
																				h.unit.content.map((hp, hpi) => {
																					return (
																						<div className={styles.products_text} key={hp.name}>
																							{
																								hp.url ? (
																									<a href={`https://www.fs.com/${h.unit.url}`} dangerouslySetInnerHTML={{ __html: hp.name }}></a>
																								) : (<span dangerouslySetInnerHTML={{ __html: hp.name }}></span>)
																							}
																						</div>
																					)
																				})
																			}
																		</div>) : null
																} */}
															</div>
														</th>
													)
												})
											}
										</tr>
									</thead>) : null
								}
								{
									data.data.table.length > 1 ? (
										<tbody>
											{
												Array.from(Array(data.data.table.length - 1), (citem, ci) => {
													return (<tr key={ci}>
														{
															data.data.table[ci + 1].map((t, ti) => {
																return (<td className={classNames({ [styles.td_hightlight]: t.is_lighted })} key={ti}>
																	{
																		t.unit.name === 'icon' ? (
																			<span className={classNames('iconfont', styles.iconfont_select)}>&#xf154;</span>
																		) : (<div className={styles.info} dangerouslySetInnerHTML={{ __html: t.unit.name }}></div>)
																	}
																</td>)
															})
														}
													</tr>)
												})
											}
										</tbody>
									) : null
								}
							</table >) : <ProductsCompare />
						}
					</div>
				) : null
			}

		</>
	)
}