import React,{useEffect, useState} from 'react'
import { Switch, Select, Spin, Button, Modal, Message, Input, Breadcrumb, Popover, Popconfirm } from '@arco-design/web-react';
import { useParams, useLocation, Link } from "react-router-dom";
import style from './style/index.module.less'
import { IconArrowUp } from '@arco-design/web-react/icon'
import ProductDetail from './ProductDetail/index';
import {ResType, selectDetail, submit,selectSourceDetail} from '@/api/globalLink/index'
interface Props {
  type: 'detail' | 'preview'
}
export default function GlobalLinkDetail(props:Props) {
  const {type} = props
    const { id } = useParams();
  const [linkData,setLinkData] = useState(undefined)
  const [loading,setLoading] = useState(false)
  const [detailContext,setDeatilContext] = useState({
    id,
    editPage:false
  })
  const submitSubject = () => {
    const params = {
      id
    }
    submit(params).then((res:ResType) => {
      if(res.code === '200') {
        Message.success('提交成功')
        getDetailData()
      }else {
        Message.error({
          content:res.msg,
          duration: 2000
        })
      }
    }).catch((err) => {
      Message.error('提交失败')
    })
  }
  const getDetailData = () => {
    const params ={
       id
    }
    setLoading(true)
    if (type === 'detail') {
      selectDetail(params).then((res:ResType) => {
        if (res.code === '200') {
          console.log(res.data.checkStatus)
          if (res.data.checkStatus === 3) {
            detailContext.editPage = false
          } else {
            detailContext.editPage = true
          }
          setDeatilContext({...detailContext})
          setLinkData(res.data)
          setLoading(false)
        }
      }).finally(() => {
        setLoading(false)
      })
    }
    if (type === 'preview') {
      const data = {
        submissionId:id
      }
      detailContext.editPage = false
      selectSourceDetail(data).then((res:ResType) => {
        if (res.code === '200') {
          if (res.data?.relationList&&!res.data.relationList.
            length) {
            Message.warning('预览数据为空')
          }
          setDeatilContext({...detailContext})
          setLinkData(res.data)
          setLoading(false)
        }
      }).finally(() => {
        setLoading(false)
      })
      setDeatilContext({...detailContext})
    }
  }
  useEffect(() => {
    getDetailData()
  },[])
  return (
    <div className={style.global_link_detail}>
          {
            type !== 'preview' ?
            <>
                <div className={style.global_link_detail_nav}>
            <Breadcrumb>
                        <Breadcrumb.Item>GlobalLink翻译审查</Breadcrumb.Item>
             </Breadcrumb>
            </div>
            <div className={style.global_link_detail_data}>
                <div className={style.global_link_detail_data_l}>
                    <div>
                    任务ID：{linkData?.submissionId}
                    </div>
                    <div>
                    任务名称：{linkData?.submissionName}
                    </div>
                    <div>
                    目标名称：{linkData?.targetName}
                    </div> 
                </div>
                {
                  detailContext.editPage ? 
                  <Popconfirm
                  trigger='click'
                  title='确认提交吗？提交后该任务内的最新数据提交到前台对应的小语种站点，被删除的楼层不会同步'
                  onOk={() => {
                    submitSubject()
                  }}
                  getPopupContainer={(node: HTMLElement) => node.parentElement}
              >
                 <div className={style.global_link_detail_data_r}>
                      <Button type='primary' shape='round' icon={<IconArrowUp />} >提交</Button> 
                </div>
              </Popconfirm> 
                  : null
                }
            </div>
            </> 
         : null
          }
            <div className={style.global_link_detail_content}>
                {
                    linkData?.relationList.map( (item,index) => {
                        return  <div className={style.global_link_detail_product} key={index}>
                        <ProductDetail data={item} detailContext={detailContext} getDetailData={getDetailData}></ProductDetail>
                    </div>
                    })
                }
            </div>
    </div>
  )
}
