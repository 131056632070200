import React, { useEffect, useState } from 'react';
import { Button } from '@arco-design/web-react';
import { IconClose, IconPlus } from '@arco-design/web-react/icon';
import AddTag from '../AddTag';
import { TagItem } from '../../types';
import { getColor, tagColorList } from '../../constans';
type CustomTagType = {
  onChange?: (value: TagItem[]) => void;
  value?: TagItem[];
};

const CustomTag: React.FC<CustomTagType> = ({ onChange, value = [] }) => {
  const [tagVisible, setTagVisible] = useState(false);
  const [tagList, setTagList] = useState<TagItem[]>(value);
  const handleAddItem = () => {
    setTagVisible(true);
  };
  const onTagChange = (data: TagItem[]) => {
    console.log(data);
    const newTagList = data.filter((item) => {
      return !tagList.find((tag) => tag.value === item.value);
    });
    setTagList((pre) => {
      return [...pre, ...newTagList];
    });
    console.log([...tagList, ...newTagList]);
    onChange && onChange([...tagList, ...newTagList]);
  };
  const handleDeleteTag = (index: number) => {
    const newTagList = tagList.filter((item, i) => i !== index);
    setTagList(newTagList);
    onChange && onChange(newTagList);
  };
  const tagRender = (item, index: number) => {
    const colorInfo = getColor(index, tagColorList);
    return (
      <div
        style={{
          color: colorInfo.textColor,
          backgroundColor: colorInfo.bgColor,
          padding: '2px 4px',
          marginRight: 5,
          marginBottom: 20,
          height: 26,
          boxSizing: 'border-box',
        }}
      >
        <span>{item.label}</span>
        <span
          style={{ marginLeft: 8, cursor: 'pointer' }}
          onClick={() => handleDeleteTag(index)}
        >
          <IconClose style={{ fontSize: 12 }} />
        </span>
      </div>
    );
  };
  useEffect(() => {
    if (value && value.length > 0) {
      setTagList(value);
    }
  }, [value]);
  return (
    <div>
      {tagList.length > 0 ? (
        <div
          style={{
            display: 'flex',
            padding: 10,
            borderRadius: 8,
            background: '#F7F8FA',
            minHeight: 104,
            marginBottom: 12,
            flexWrap: 'wrap',
          }}
        >
          {tagList.map((item, index) => {
            return <div key={index}>{tagRender(item, index)}</div>;
          })}
        </div>
      ) : null}
      <Button
        type="outline"
        onClick={handleAddItem}
        icon={<IconPlus />}
        style={{ borderRadius: 8 }}
      >
        添加标签
      </Button>
      {tagVisible && (
        <AddTag
          tagVisible={tagVisible}
          setTagVisible={setTagVisible}
          onAddTag={onTagChange}
        />
      )}
    </div>
  );
};

export default CustomTag;
