import request from '@/utils/request';

type SignProp = {
  url: string;
};
// 获取飞书云文档组件签名
export function getSignature(data: SignProp) {
  return request({
    url: '/api/resource/sign',
    method: 'post',
    data,
  });
}
