import { Message } from '@arco-design/web-react';

// 根据配置排序，生成正确的菜单顺序
export function verbMenu(_routes) {
  const verbArray = ['home', 'PageManager'];
  const otherMenu = [];
  const verbMenu = [];
  _routes.forEach((v) => {
    const hasValue = verbArray.filter((t) => t === v.key);
    if (hasValue.length > 0) {
      verbMenu.push(v);
    } else {
      otherMenu.push(v);
    }
  });
  return [...verbMenu, ...otherMenu];
}
export const stripHtml = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};
// 生成唯一id
export const generateUUID = () => {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now();
  }
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  uuid = uuid.replace(/-/g, '');
  return uuid;
};
// 处理资源路径
export const handleResourcePath = (path = '', isMall = true) => {
  if (
    /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(
      path
    )
  ) {
    return path;
  } else {
    let basePath = `${process.env.REACT_APP_APP_RESOURCE}`;
    //   if (!isMall) {
    //     basePath = `${process.env.REACT_APP_APP_RESOURCE}mall/`;
    //   }
    // 切割url中斜杠，分别encode,再拼接
    const urlPath =
      path.lastIndexOf('?') > 0
        ? path.substring(0, path.lastIndexOf('?'))
        : path;
    const pathArray = urlPath.split('/');
    const encodePathArray = pathArray.map((v) => encodeURIComponent(v));
    const encodePath = encodePathArray.join('/');
    //images 和 video开头，不拼 mall
    if (!['images', 'video', 'mall'].includes(pathArray[0]) && !isMall) {
      basePath = `${process.env.REACT_APP_APP_RESOURCE}mall/`;
    }
    basePath += encodePath;
    return `${basePath}`;
  }
};

// 判断链接类型  图片 视频 tag
export const handleLinkType = (url) => {
  if (parseInt(url)) {
    return 'tag';
  } else {
    let s = url;
    if (s.includes('www.youtube.com')) {
      return 'youtube';
    } else {
      if (s.includes('?')) {
        s = s.substr(0, s.indexOf('?'));
      }
      if (s.includes('.')) {
        s = s.substring(s.lastIndexOf('.') + 1).toLowerCase();
      }

      const imgs = [
        'gif',
        'jpg',
        'jpeg',
        'png',
        'gif',
        'svg',
        'png',
        'jfif',
        'webp',
      ];
      const videos = [
        'ogm',
        'ogv',
        'ogg',
        'mp4',
        'm4v',
        'mov',
        'wmv',
        'webm',
        'm3u8',
      ];
      if (imgs.includes(s)) {
        return 'img';
      } else if (videos.includes(s)) {
        return 'video';
      } else {
        return '';
      }
    }
  }
};

// 切割冒号前后内容
export const splitColonString = (s) => {
  let arr = [];
  if (s.includes(':')) {
    arr = [s.substr(0, s.indexOf(':') + 1), s.substring(s.indexOf(':') + 1)];
  } else {
    arr = ['', s];
  }
  return arr;
};

//上传文件签名生成，time、name
export const getSignature = (file) => {
  const date = new Date();
  const time = `${date.getFullYear()}${(date.getMonth() + 1 + '').padStart(
    2,
    '0'
  )}${(date.getDate() + '').padStart(2, '0')}${(date.getHours() + '').padStart(
    2,
    '0'
  )}${(date.getMinutes() + '').padStart(2, '0')}${(
    date.getSeconds() + ''
  ).padStart(2, '0')}`;
  const name = `${time}${Math.random().toString(36).slice(-6)}.${file.name
    .split('.')
    .at(-1)}`;
  return { time, name };
};

/**
 * 文件流 a 链接导出
 * @param fileName 文件名称
 * @param fileStream 文件流
 */
export const fileExcelExport = function (fileName: string, fileStream: any) {
  const blob = new Blob([fileStream], { type: 'application/vnd.ms-excel' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a'); // 创建a标签
  link.href = url;
  if (fileName) {
    link.download = decodeURIComponent(fileName);
  }
  link.click();
  URL.revokeObjectURL(url); // 释放内存
};

/**
 * response.data json
 * @param response.data
 * @param callback 回调
 */
export const fileReader = function (data: any, cb: (data: any) => void) {
  const reader: any = new FileReader(); //创建一个FileReader实例
  reader.readAsText(data, 'utf-8'); //读取文件,结果用字符串形式表示
  reader.onload = function () {
    const data = JSON.parse(reader.result);
    cb && cb(data);
  };
};

export const readerAndExport = (res: any, defaultName?) => {
  if (res.data && res.data?.type === 'application/json') {
    fileReader(res.data, (response: any) => {
      Message.error(response.msg);
    });
  } else {
    console.log(res.headers, 'res.headers');
    const contentDisposition = res.headers['content-disposition'];
    const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*');
    const result = patt.exec(contentDisposition);
    const filtName = result[1] || defaultName;
    fileExcelExport(filtName, res.data);
    Message.success('导出成功');
  }
};

// 根据id获取树状路径
export const getPath = function (tree, id, path = []) {
  for (let i = 0; i < tree.length; i++) {
    const tempPath = [...path];
    const item = tree[i];
    tempPath.push(item.value);
    if (item.value === id) {
      return tempPath;
    }
    if (item.children && item.children.length) {
      const result = getPath(item.children, id, tempPath);
      if (result) {
        return result;
      }
    }
  }
};

// 根据文件地址获取数据流 将数据流转换为File对象
export const fetchAsStreamToFile = async function (
  url: string,
  fileName: string
): Promise<File> {
  const response = await fetch(url, { mode: 'cors' });

  if (!response.ok) {
    throw new Error(`Failed to fetch file, status: ${response.status}`);
  }

  const readableStream = response.body;
  if (!readableStream) {
    throw new Error('No body in the response');
  }

  const chunks: Uint8Array[] = [];
  const reader = readableStream.getReader();

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    chunks.push(value);
  }

  const blob = new Blob(chunks, {
    type: response.headers.get('content-type') || 'application/octet-stream',
  });
  return new File([blob], fileName, { type: blob.type });
};

export const waringMsg = (ids: any[]) => {
  if (!ids || ids.length) return;
  Message.warning({
    content:
      '当前操作针对筛选的所有结果，若需对指定产品进行操作，请勾选相关产品再进行批量操作!',
    duration: 5000,
  });
};
