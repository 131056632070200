// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 页面编辑、模板管理详情路由
const routes: Route[] = [
  {
    "name": "角色编辑",
    "router": "AuthManager/peronalManagerDetail",
    component: lazyload(() => import('@/pages/AuthManager/personnelManagerDetail')),
  },
  {
    name:'角色管理-角色编辑',
    router:'RoleManager/Detail/:id',
    component:lazyload(() => import('@/pages/AuthManager/RoleManager/Detail')),
  }
];
export default routes;
