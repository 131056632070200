// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 页面编辑、模板管理详情路由
const routes: Route[] = [
  {
    name: 'QC认证管理',
    router: 'Resource/QCManager/:id',
    component: lazyload(() => import('@/pages/Resource/QCManager')),
  },
  {
    name: '文档分类管理',
    router: 'Resource/DocumentManager/ClassifyManagerOld',
    component: lazyload(
      () => import('@/pages/Resource/DocumentManager/ClassifyManagerOld')
    ),
  },
];
export default routes;
