import React, { useState, useEffect, useRef, useContext } from 'react'
import Tag from '../Tag'
import classNames from 'classnames';
import styles from './style/index.module.less'
import { ProductsContext } from '../../index'

export default function NetworkConnectivity(props: any) {
	const context = useContext(ProductsContext);
	
	function titleScroll() {
		setEleData((prevState) => {
			return {
				...prevState,
				titleWrapLeft: titleWrap.current.scrollLeft
			}
		});

	}

	function setActiveTitle(e, index) {
		e.stopPropagation();
		if (titleWrap && titleWrap.current) {
			setActiveIndex(index)
		}
	}
	
	const { data } = props;
	const [activeIndex, setActiveIndex] = useState(0);
	const [eleData, setEleData] = useState({
		titleCtnWidth: 0,
		titleWrapWidth: 0,
		titleWrapLeft: 0
	});


	const titleCtn = useRef<HTMLDivElement>(null);
	const titleWrap = useRef<HTMLDivElement>(null);


	useEffect(() => {
		if (data && data.scroll_title && data.scroll_title.length) {
			setEleData((prevState) => {
				return {
					...prevState,
					titleCtnWidth: titleCtn.current.clientWidth,
					titleWrapWidth: titleWrap.current.scrollWidth,
				}
			});
			titleWrap.current.addEventListener("scroll", titleScroll);
			titleScroll();
			return () => {
				if (titleWrap && titleWrap.current) {
					titleWrap.current.removeEventListener("scroll", titleScroll);
				}
			}
		}
	}, []);

	return (
		<>
			{
				data ? (
					<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.network_connectivity_box])} onClick={() => context.openSiderBox({
						componentId: data.componentId,
						block: data.block || '',
						type: data.type,
					})}>
						<div className={styles.network_connectivity_title}>Connectivity Solutions</div>
						{
							data && data.data ? (
								<div className={styles.network_connectivity_ctn}>
									{
										data.data.title ? (
											<div className={styles.connectivity_title} dangerouslySetInnerHTML={{ __html: data.data.title }}></div>
										) : null
									}
									{
										data.data.list && data.data.list.length > 0 ? (
											<div className={styles.connectivity_tag_wrap_new}>
												<div className={classNames(styles.scroll_title_wrap, { [styles.scroll_title_wrap_center]: eleData.titleCtnWidth >= eleData.titleWrapWidth })} ref={titleCtn} >
													{
														eleData.titleCtnWidth < eleData.titleWrapWidth ? (<span className={classNames('iconfont', styles.iconfont_btn, styles.iconfont_prev, { [styles.iconfont_disable]: activeIndex === 0 })} onClick={(e) => setActiveTitle(e, activeIndex - 1 > 0 ? activeIndex - 1 : 0)}>&#xf048;</span>) : null
													}

													<div className={styles.scroll_title_list} ref={titleWrap}>
														{
															eleData.titleWrapLeft > 0 ? (
																<span className={styles.left_shadow}></span>
															) : null
														}

														{
															data.data.list.map((item, index) => {
																return <div className={classNames(styles.scroll_title_item, { [styles.scroll_title_item_active]: index === activeIndex })} key={index} onClick={(e) => setActiveTitle(e, index)}>{item.desc}</div>
															})
														}
														{
															eleData.titleCtnWidth + eleData.titleWrapLeft < eleData.titleWrapWidth ? <span className={styles.right_shadow}></span> : null
														}

													</div>
													{
														eleData.titleCtnWidth < eleData.titleWrapWidth ? (<span className={classNames('iconfont', styles.iconfont_btn, styles.iconfont_next, { [styles.iconfont_disable]: activeIndex === data.scroll_title.length - 1 })} onClick={(e) => setActiveTitle(e, activeIndex + 1 < data.scroll_title.length ? activeIndex + 1 : data.scroll_title.length - 1)}>&#xf047;</span>) : null
													}

												</div>
												<div className={styles.scroll_tag_box}>
													<Tag data={data.data.list[activeIndex].tagDetail} />
												</div>
											</div>
										) : (
											<div className={styles.connectivity_tag_wrap}>
												{
													data.data.map((item, index) => {
														return <div className={styles.connectivity_tag} key={index}>  <Tag data={item} /></div>
													})
												}
											</div>
										)
									}
									<Tag />
								</div>
							) : (
								<div className={styles.network_connectivity_empty}>应用方案内容区域</div>
							)
						}
					</div>
				) : null
			}
		</>

	)
}