import request from '@/utils/request';

interface FloorAdd {
  id: number;
  name: string;
  sort: number;
  status: number;
  detailId: number;
}

interface ImportTemplateType {
  solutionId: number;
  templateId: number;
}
interface SaveTemplateType {
  name: number;
  solutionId: number;
}
// 组件库列表
export function libraryList() {
  return request({
    url: '/api/componentTemplate/library/list',
    method: 'get',
  });
}

// 新增模版楼层
export function floorAdd(data: FloorAdd) {
  return request({
    url: '/api/solutionV3/detail/floor/add',
    method: 'post',
    data,
  });
}

// 楼层组件暂存
export function componentAdd(data: {
  floorInfoItemList: any[];
  detailId: number;
}) {
  return request({
    url: '/api/solutionV3/detail/floor/component/add',
    method: 'post',
    data,
  });
}

// 楼层删除
export function floorDel(id) {
  return request({
    url: `/api/solutionV3/detail/floor/del/${id}`,
    method: 'get',
  });
}

// 解决方案详情
export function detail(id: number) {
  return request({
    url: `/api/solutionV3/detail/${id}`,
    method: 'get',
  });
}

// 修改模版楼层排序
export function sortModify(data) {
  return request({
    url: '/api/solutionV3/detail/floor/sort/modify',
    method: 'post',
    data,
  });
}

// // 模版楼层样式修改
// export function floorStyle(data: FloorStyle) {
//   return request({
//     url: '/api/componentTemplate/floor/style',
//     method: 'post',
//     data,
//   });
// }

// 组件编辑回显
export function componentEdit(typeId) {
  return request({
    url: `/api/solutionV3/detail/preEdit/${typeId}`,
    method: 'get',
  });
}

// 模版楼层样式修改
export function componentSubmit(data) {
  return request({
    url: '/api/solutionV3/detail/submit',
    method: 'post',
    data,
  });
}

// 模版绑定的楼层信息
export function floorData(id) {
  return request({
    url: `/api/solutionV3/detail/floor/${id}`,
    method: 'get',
  });
}

// 导入模版
export function importTemplate(data: ImportTemplateType) {
  return request({
    url: `/api/solutionV3/detail/import/template`,
    method: 'post',
    data,
  });
}

// 存为模版
export function saveTemplate(data: SaveTemplateType) {
  return request({
    url: `/api/solutionV3/detail/save/template`,
    method: 'post',
    data,
  });
}
// 模版类型
export function templateType() {
  return request({
    url: `/api/solutionV3/detail/template/type`,
    method: 'get',
  });
}

// 模版信息(通过模版类型获取模版信息)
export function templateInfo(typeId) {
  return request({
    url: `/api/solutionV3/detail/template/info/${typeId}`,
    method: 'get',
  });
}

export default {
  libraryList,
  floorAdd,
  componentAdd,
  floorDel,
  detail,
  sortModify,
  //   floorStyle,
  componentEdit,
  componentSubmit,
  floorData,
  importTemplate,
  saveTemplate,
  templateType,
  templateInfo,
};
