import { RootState } from '@/store';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './style/index.module.less';
import { Avatar, Dropdown, Menu, Popover } from '@arco-design/web-react';
import LoginInfo from '@/components/LoginInfo';
import IconSvg from '@/components/IconSvg';
export default function DetailNav() {
  return (
    <div className={styles.nav}>
      <div className={styles.logo}>
        <img src="https://resource.fs.com/mall/generalImg/202206161506275riiaf.png" />
        <span>文档编辑</span>
      </div>
      <div className={styles.right}>
        <div
          className={styles.desc}
          onClick={() => {
            window.open(
              'https://mah2eds8ab.feishu.cn/docx/CPODd8yDDoJHLOxgc9Pc1cQPnRc',
              '_blank'
            );
          }}
        >
          <IconSvg type="icon-testt" />
          <span>编辑规范说明</span>
        </div>
        <div className={styles.line}></div>
        <LoginInfo />
      </div>
    </div>
  );
}
