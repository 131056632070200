import { FilterParams } from '@/enum/FilterList';
import request from '@/utils/request';

export interface imageParamsData extends FilterParams {
  userName: string;
  cid?: number; // 筛选分类ID
  keyword: string; //	查询参数
  current: number; // 当前页
  size: number; // 每页条数
  productsId?: number;
  startTime: string;
  endTime: string;
  isPop?: number;
}

export function getImageList(data) {
  return request({
    url: '/api/images/page',
    method: 'post',
    data,
  });
}
export function getImageDetail(params) {
  return request(
    {
      url: '/api/images/detail',
      method: 'get',
      params,
    },
    true
  );
}
export function getToken() {
  return request({
    url: '/api/aws/s3/policy',
    method: 'get',
  });
}
export function addImage(data) {
  return request({
    url: `/api/images/save`,
    method: 'post',
    data,
  });
}
export function delImage(data) {
  return request({
    url: `/api/images/remove?ids=${data}`,
    method: 'post',
    // data,
  });
}
export function editImage(data) {
  return request({
    url: `/api/images/update`,
    method: 'post',
    data,
  });
}
export function imgFuzzy(data) {
  return request({
    url: `/api/images/fuzzy`,
    method: 'post',
    data,
  });
}
export function imgReCut(data) {
  return request({
    url: `/api/images/reCut`,
    method: 'post',
    data,
  });
}
export function imgReplace(data) {
  return request({
    url: `/api/images/replace`,
    method: 'post',
    data,
  });
}
