import { createSlice } from '@reduxjs/toolkit';
import defaultSettings from '../../settings.json';

// import axios from 'axios';
export interface LanguageType {
  id?: number;
  siteName?: string;
  code?: string;
}
export interface GlobalState {
  settings?: typeof defaultSettings;
  userInfo?: {
    userId?: number;
    websiteInfo?: any;
    name?: string;
    avatar?: string;
    job?: string;
    organization?: string;
    location?: string;
    email?: string;
    permission?: Array<Record<string, any>>;
    token?: string;
    functionPermissionIdList?: Array<number>;
    isSystemAdmin?: boolean;
    defaultLanguageId?: number;
  };
  requestLoading?: boolean;
  routerTag?: Array<Record<string, string>>; // 路由标签
  language_code?: string; // 数据站点
  languageInfo?: LanguageType; // 数据站点
}

const initialState: GlobalState = {
  settings: defaultSettings,
  userInfo: {
    permission: [],
  },
  requestLoading: false,
  routerTag: [],
  language_code: '1',
  languageInfo: {
    id: undefined,
    siteName: undefined,
    code: undefined,
  },
};
export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      const userInfo = action.payload;
      state.userInfo = userInfo;
      // state.language_code = userInfo.defaultLanguageId
    },
    setRouterTag: (state, action) => {
      const routerTag = action.payload;
      state.routerTag = [...state.routerTag, routerTag];
    },
    removeRouterTag: (state, action) => {
      const routerTag = action.payload;
      state.routerTag = state.routerTag.filter(
        (item) => item.key !== routerTag.key
      );
    },
    setRequestLoading: (state, action) => {
      const requestLoading = action.payload;
      state.requestLoading = requestLoading;
    },
    setLocale: (state, action) => {
      const locale = action.payload;
      const websiteInfo = { ...state }?.userInfo?.websiteInfo || [];
      const info = websiteInfo.find((item) => item.id == locale);
      state.language_code = String(locale);
      state.languageInfo = info || {};
    },
    setInitData: (state, action) => {
      state.userInfo = {
        permission: [],
      };
      state.routerTag = [];
      state.language_code = '1';
      state.languageInfo = {};
    },
  },
});

export const {
  updateUserInfo,
  setRouterTag,
  removeRouterTag,
  setLocale,
  setRequestLoading,
  setInitData,
} = slice.actions;
// 获取用户信息
export const getUserInfo = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    const data = {
      avatar:
        'https://s3-imfile.feishucdn.com/static-resource/v1/v2_940c590d-556c-4adc-8a4b-f60da49b7d7g~?image_size=72x72&cut_type=&quality=&format=image&sticker_format=.webp',
      email: 'ajay.li@feisu.com',
      job: '高级前端开发工程师',
      name: '李杰',
      permission: [
        {
          children: [],
          id: 1,
          name: '首页',
          pid: 0,
          router: 'home',
          routerName: 'menu.home',
          sort: 1,
        },
        {
          children: [
            {
              children: [
                {
                  children: [],
                  id: 4,
                  name: '页面编辑',
                  pid: 3,
                  router: 'PageManager/ProductDetailManager/PageEditList',
                  routerName: 'menu.PageEditList',
                  sort: 1,
                },
                {
                  children: [],
                  id: 5,
                  name: '模板管理',
                  pid: 3,
                  router: 'PageManager/ProductDetailManager/TemplateManager',
                  routerName: 'menu.TemplateManager',
                  sort: 2,
                },
              ],
              id: 3,
              name: '商品详情管理',
              pid: 2,
              router: 'PageManager/ProductDetailManage',
              routerName: 'menu.ProductDetailManager',
              sort: 1,
            },
          ],
          id: 2,
          name: '页面管理',
          pid: 0,
          router: 'PageManager',
          routerName: 'menu.pageManager',
          sort: 2,
        },
        {
          children: [
            {
              children: [],
              id: 9,
              name: '主多图管理',
              pid: 8,
              router: 'Resource/MainImage',
              routerName: 'menu.mainImage',
              sort: 1,
            },
            {
              children: [],
              id: 10,
              name: '视频管理',
              pid: 8,
              router: 'Resource/Video',
              routerName: 'menu.video',
              sort: 2,
            },
            {
              children: [],
              id: 11,
              name: '图片管理',
              pid: 8,
              router: 'Resource/GeneralImage',
              routerName: 'menu.generalImage',
              sort: 3,
            },
          ],
          id: 8,
          name: '资源管理',
          pid: 0,
          router: 'Resource',
          routerName: 'menu.resource',
          sort: 8,
        },
        {
          children: [],
          id: 6,
          name: '操作日志',
          pid: 0,
          router: 'OperationLog',
          routerName: 'menu.log',
          sort: 99,
        },
      ],
      token:
        'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NTUyMjAxNDIsInVzZXJJZCI6IjE5MDYifQ.hso1tsIKABQEl7al9OaphoLV7AnqbqsWm00tlOUup6Q',
      websiteInfo: [
        {
          code: 'en',
          id: 1,
          siteName: 'US主站',
        },
        {
          code: 'es',
          id: 2,
          siteName: 'ES站',
        },
        {
          code: 'fr',
          id: 3,
          siteName: 'FR站',
        },
        {
          code: 'ru',
          id: 4,
          siteName: 'RU站',
        },
        {
          code: 'de',
          id: 5,
          siteName: 'DE站',
        },
        {
          code: 'jp',
          id: 8,
          siteName: 'JP站',
        },
        {
          code: 'uk',
          id: 9,
          siteName: 'UK主站',
        },
        {
          code: 'it',
          id: 14,
          siteName: 'IT站',
        },
      ],
    };
    dispatch(updateUserInfo(data));
    resolve(data);
  });
};

export default slice.reducer;
