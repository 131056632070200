import request from '@/utils/request';

export function getTagList(data) {
  return request({
    url: `/api/tags/list`,
    method: 'post',
    data,
  });
}
export function addTag(data) {
  return request({
    url: `/api/tags/add`,
    method: 'post',
    data,
  });
}
export function getUser(params) {
  return request({
    url: '/api/tags/adminInfo',
    method: 'get',
    params,
  });
}
export function delTag(data) {
  return request({
    url: `/api/tags/delete`,
    method: 'post',
    data,
  });
}
export function uploadExcel(type, data) {
  return request({
    url: `/api/tags/upload/${type}`,
    method: 'post',
    data,
  });
}
export function downloadExcel(type, data) {
  return request({
    url: `/api/tags/download/${type}`,
    method: 'post',
    data,
    responseType: 'blob',
  });
}
export function getScene(data) {
  return request({
    url: `/api/tags/images/sceneImage`,
    method: 'post',
    data,
  });
}
export function getTree() {
  return request({
    url: `/api/tags/scene/tree`,
    method: 'post',
  });
}
export function delScene(data) {
  return request({
    url: `/api/tags/scene/delete`,
    method: 'post',
    data,
  });
}
export function addScene(data) {
  return request({
    url: `/api/tags/scene/add`,
    method: 'post',
    data,
  });
}
export function addBatch(data) {
  return request({
    url: `/api/tags/scene/addBatch`,
    method: 'post',
    data,
  });
}
export function getAdmin(params) {
  return request({
    url: `/api/tags/scene/adminIds`,
    method: 'get',
    params,
  });
}

export function getSceneDetail(imagesId) {
  return request({
    url: `/api/tags/images/sceneImageDetail/${imagesId}`,
    method: 'post',
  });
}
