import React from 'react';
import { Upload } from '@arco-design/web-react';
import { AWSUpload } from '@/utils/aws';
import { getSignature } from '@/utils/utils';
/**
 *
 * @param props
 * uploadPath 上传路径
 * successCallback 上传成功回调
 * uploadProps 上传组件属性
 * @returns
 */

function FsUpload(props) {
  const {
    uploadPath = '',
    uploadName = '',
    successCallback,
    uploadProps = {},
    isUpload = false,
  } = props;
  //上传方法
  const customRequest = (option) => {
    const { onProgress, onError, onSuccess, file } = option;
    const { name } = getSignature(file);
    console.log(file);
    const { name: fileName, type } = file;
    console.log(onProgress);
    const body = {
      file,
      onProgress,
      onSuccess,
      onError,
      key: isUpload
        ? `${uploadPath}/${
            uploadName ? uploadName + '.' + type.split('/')[1] : fileName
          }`
        : `${uploadPath}/${name}`, //设置上传路径
      next: async (res) => {
        successCallback && successCallback(res, file);
      },
    };
    AWSUpload(body);
  };
  return (
    <>
      <Upload
        customRequest={customRequest}
        showUploadList={false}
        {...uploadProps}
      >
        {props.children}
      </Upload>
    </>
  );
}

export default FsUpload;
