import { getSignature } from '@/api/sign';
import { generateUUID } from '@/utils/utils';
import { Message } from '@arco-design/web-react';
import React, { useEffect, useState } from 'react';
let myComponent = null;
interface Props {
  src: string;
  titleChange?: (type: string) => void;
}
export default function FsEditor(props: Props) {
  const { src, titleChange } = props;
  const [id, setId] = useState(null);
  const init = async () => {
    console.log(id, src, 'init');

    if (!id || !src) return;
    try {
      console.log(location.href, 'location.href');

      const res: ResObject = await getSignature({ url: location.href });

      if (res.code !== '200') {
        Message.error(res.msg);
        return;
      }
      const { openId, signature, nonceStr, timestamp } = res.data;
      window.webComponent
        .config({
          openId, // 当前登录用户的open id，要确保与生成 signature 使用的 user_access_token 相对应，使用 app_access_token 时此项不填。
          signature, // 签名
          appId: process.env.REACT_APP_APP_ID, // 应用 appId
          timestamp, // 时间戳（毫秒）
          nonceStr, // 随机字符串
          url: location.href, // 参与签名加密计算的url
          jsApiList: ['DocsComponent'], // 指定要使用的组件，请根据对应组件的开发文档填写。如云文档组件，填写['DocsComponent']
          lang: 'zh', // 指定组件的国际化语言：en-英文、zh-中文、ja-日文
        })
        .then((res) => {
          // 可以在这里进行组件动态渲染
          myComponent = window.webComponent.render(
            'DocsComponent',
            {
              //组件参数
              src,
              minHeight: '500px',
              height: '100%',
              width: '100%',
            },
            document.querySelector(`#${id}`) // 将组件挂在到哪个元素上
          );
          myComponent.config.setFeatureConfig({
            HEADER: {
              enable: true, //  隐藏头部
            },
            MODAL: {
              outerMask: true,
            },
            LIKE: {
              enable: false, //  隐藏点赞
            },
            SHARE: {
              enable: false, //  隐藏分享
            },
            FOOTER: {
              enable: false, //  隐藏底部
            },
            COMMENT: {
              global: {
                enable: false, //  隐藏评论
              },
              partial: {
                enable: false,
              },
            },
            DIRECTORY: {
              enable: true,
              pin: true,
            },
            MORE_MENU: {
              enable: true, //  隐藏更多菜单
              items: {
                translateEnable: true,
              },
            },
            COLLABORATOR: {
              enable: false, //  隐藏协作者
            },
          });
          myComponent.event.onTitleChange((title) => {
            titleChange && titleChange(title);
          });
        });
    } catch (e) {
      Message.error(e.message);
    }
  };
  useEffect(() => {
    init();
  }, [src, id]);
  useEffect(() => {
    const id = generateUUID();
    setId('editor_' + id);
  }, []);
  return (
    <div
      id={id}
      style={{
        overflowY: 'clip',
        width: '100%',
        height: '100%',
      }}
    ></div>
  );
}
