import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 测试专题
const routes: Route[] = [
  {
    name: '页面编辑详情',
    router: 'PageManager/SubjectManager/SpecialTest/:id',
    component: lazyload(() => import('@/pages/Special')),
  }
];
export default routes;
