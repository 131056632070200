// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 页面编辑、模板管理详情路由
const routes: Route[] = [
  {
    "name": "产品树管理-Tag图新增",
    "router": "Resource/ProductTreeManager/TagImgManager/Detail",
    component: lazyload(() => import('@/pages/Resource/ProductTreeManager/TagImgManager/Detail')),
  }
];
export default routes;
