// feed管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// google-feed管理
const routes: Route[] = [
  {
    name: 'document',
    router: 'Document/:id',
    component: lazyload(() => import('@/pages/Document')),
  },
];
export default routes;
