import request from '@/utils/request';

// blog首页查询
export function blogHomeList() {
  return request({
    url: '/api/blog/index',
    method: 'post',
  });
}
// 首页编辑
export function blogHomeEdit(data) {
  return request({
    url: '/api/blog/index/update',
    method: 'post',
    data,
  });
}
