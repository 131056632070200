import { Message } from '@arco-design/web-react';
import axios from 'axios';
import store from '@/store';
import qs from 'qs';

const instance = axios.create({
  baseURL: process.env.REACT_APP_COMMUNITY_FEISU_URL,
  withCredentials: false,
  // headers: {
  // 	'Accept': 'application/json',
  // 	'Content-Type': 'application/json'
  // },
  timeout: 120000,
});

instance.interceptors.request.use(
  (config) => {
    const { user } = store.getState();
    config.headers.Accept = 'application/json;charset=UTF-8';
    const { token } = user.userInfo;
    const { language_code } = user;
    if (token) {
      config.headers.Authorization = token;
    }
    if (language_code) {
      config.headers['language-id'] = language_code;
    }
    // 请求为Get时 当params参数含有数组 进行序列化处理
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      };
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (res) => {
    if (res.headers) {
      if (res.headers.authorization) {
        localStorage.setItem('token', res.headers.authorization);
      }
    }
    if (
      res.config &&
      res.config.responseType &&
      res.config.responseType === 'blob'
    ) {
      return Promise.resolve(res);
    }
    return Promise.resolve(res.data);
  },
  (err) => {
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 401:
          // 无权限,清除缓存
          localStorage.clear();
          break;
        default:
          Message.error(err.response.data.message);
      }
      return Promise.reject(err.response.data);
    } else {
      if (err.message.includes('timeout')) {
        console.log('网络超时', err);
      }
      return Promise.reject(err);
    }
  }
);

export default instance;
