import React, { useState } from 'react';
import { Popover } from '@arco-design/web-react';
import classNames from 'classnames'
import styles from './style/index.module.less';
import { handleResourcePath } from '@/utils/utils';

export default function Tag(props: any) {


	const { data, position = 'top' } = props;


	const positionObj = {
		'middle-top': 'top',
		'left-middle': 'left',
		'middle-bottom': 'bottom',
		'right-middle': 'right'
	}

	const [activePoint, setActivePoint] = useState(0);

	function pointClick(e, i) {
		e.stopPropagation();
		setActivePoint(i)
	}
	return (
		<div className={styles.tag_wrap}>
			{
				(position === 'top' && data && data.text) ? (
					<div className={styles.tag_title}>
						{data.text}
					</div>
				) : null
			}
			{
				data && data.points_data && data.points_data.length ? (
					<div className={styles.tag_content}>
						<img className={styles.tag_img} src={handleResourcePath(data.images_url,false)} alt="" />
						{
							data.points_data.map((item, index) => {
								return <Popover  className={styles.point_wrap} key={index} position={positionObj[item.direction]} trigger="click" popupVisible={(activePoint && activePoint === index + 1) ? true : false}
									content={
										<div className={classNames(styles.point_content, { [styles.point_content_more]: item.products_tag_info && item.products_tag_info.length > 1 })}>
											{
												item.products_tag_info && item.products_tag_info.length ? (
													<>
														{
															item.products_tag_info.map((p, pi) => {
																return (
																	<div className={styles.point_box} key={pi}>
																		<a className={styles.products_img} href={`https://www.fs.com/products/${p.products_id}.html`}>
																			<img src={p.products_images} alt="" />
																		</a>
																		<div className={styles.products_info}>
																			<a className={styles.products_name} href={`https://www.fs.com/products/${p.products_id}.html`} dangerouslySetInnerHTML={{ __html: p.products_name }}></a>
																			<a className={styles.products_id} href={`https://www.fs.com/products/${p.products_id}.html`}>#{p.products_id}</a>

																			<div className={styles.products_price_box}>
																				<span className={styles.products_price} dangerouslySetInnerHTML={{ __html: p.products_price }}></span>
																				<span className={classNames('iconfont', [styles.iconfont_cart])}>&#xf025;</span>
																			</div>
																			<div className={styles.price_info} dangerouslySetInnerHTML={{ __html: p.products_price }}>


																			</div>
																			<div className={styles.products_cart}>
																				<span className={classNames('iconfont', [styles.iconfont_cart])}>&#xf025;</span>
																			</div>
																		</div>
																	</div>
																)
															})
														}
													</>
												) : null
											}
											<span className={classNames('iconfont', [styles.iconfont_close])} onClick={(e) => pointClick(e, 0)}>&#xf041;</span>
										</div>
									}>
									<div className={classNames(styles.point_item, { [styles.point_item_active]: (index + 1) === activePoint })} style={{ top: `${item.points_top}%`, left: `${item.points_left}%` }} onClick={(e) => pointClick(e, activePoint ? ((activePoint === index + 1) ? 0 : index + 1) : index + 1)}>
										<span className={classNames('iconfont', styles.iconfont_tag)}>&#xf017;</span>
										<span className={classNames('iconfont', styles.iconfont_close)}>&#xf041;</span>
									</div>
								</Popover>
							})
						}
					</div>
				) : null
			}
			{
				(position === 'bottom' && data && data.text) ? (
					<div className={styles.tag_title}>
						{data.text}
					</div>
				) : null
			}
		</div>
	)
}