import request from '@/utils/communityApiRequest';
export interface ResType {
  code?: number;
  data?: any;
  message?: string;
  extend?: any;
  traceId?: any;
}

// 文章列表
export function getCaseStudyArticleList(data) {
  return request({
    url: '/article/list',
    method: 'post',
    data,
  });
}

// 删除案例
export function delCaseStudyArticle(data) {
  return request({
    url: '/article/remove',
    method: 'post',
    data,
  });
}

// caseStudy标签分类信息
export function getCaseStudyTagList(data) {
  return request({
    url: '/case-study/category/list',
    method: 'get',
    params: data,
  });
}

// caseStudy获取单个分类标签信息
export function getCategoryType(data: { type: 1 | 2 | 3 | 4 }) {
  return request({
    url: '/case-study/category/getCategoryType',
    method: 'get',
    params: data,
  });
}

//新增-编辑标签-表单
export function addAndEditTagSaveLabel(data) {
  return request({
    url: '/case-study/category/saveLabel',
    method: 'post',
    data,
  });
}

//编辑标签值-表单
export function addAndEditTagsaveLabelValue(data) {
  return request({
    url: '/case-study/category/saveLabelValue',
    method: 'post',
    data,
  });
}

// 删除分类
export function deleteTag(data) {
  return request({
    url: '/case-study/category/delete',
    method: 'post',
    data,
  });
}

//添加案例
export function addCaseStudy(data) {
  return request({
    url: '/article/add',
    method: 'post',
    data,
  });
}

//修改案例状态
export function editCaseStudyStatus(data) {
  return request({
    url: '/article/changeStatus',
    method: 'post',
    data,
  });
}

//管理列表排序
export function articleSort(data) {
  return request({
    url: '/article/manageList',
    method: 'post',
    data,
  });
}

// 获取详情页数据
export function getCaseStudyDetail(data) {
  return request({
    url: `/article/detail/${data.id}`,
    method: 'get',
  });
}

//获取组件的列表
export function getComponentList() {
  return request({
    url: '/article/componentList',
    method: 'get',
  });
}

//新增一个楼层
export function addFloor(data) {
  return request({
    url: '/article/floor/new',
    method: 'post',
    data,
  });
}

//删除一个楼层
export function deleteFloor(data) {
  return request({
    url: '/article/floor/remove',
    method: 'post',
    data,
  });
}

//添加或修改楼层信息
export function addOrEditFloor(data) {
  return request({
    url: '/article/floor/name/save',
    method: 'post',
    data,
  });
}

//楼层拖拽排序修改
export function floorSort(data) {
  return request({
    url: '/article/floor/sort/edit',
    method: 'post',
    data,
  });
}

//提交解决方案
export function submitSolution(data) {
  return request({
    url: '/article/detail/submit',
    method: 'post',
    data,
  });
}

//保存案例
export function saveCaseStudy(data) {
  return request({
    url: '/article/saveArticle',
    method: 'post',
    data,
  });
}

//获取组件的列表
export function getComponentLists() {
  return request({
    url: '/article/componentList',
    method: 'get',
  });
}

// 保存单个组件
export function saveComponent(data) {
  return request({
    url: '/article/component/save',
    method: 'post',
    data,
  });
}

//新增或者编辑banner
export function saveBanner(data) {
  return request({
    url: '/article/banner/save',
    method: 'post',
    data,
  });
}

//获取banner详情
export function getBannerDetail(data) {
  return request({
    url: '/article/banner/detail',
    method: 'post',
    data,
  });
}

//保存模板
export function saveTemplate(data) {
  return request({
    url: '/componentManager/save',
    method: 'post',
    data,
  });
}

//模板列表分页
export function getTemplateList(data) {
  return request({
    url: '/componentManager/list',
    method: 'post',
    data,
  });
}

//应用模板
export function applyTemplate(data) {
  return request({
    url: '/componentManager/apply',
    method: 'post',
    data,
  });
}
