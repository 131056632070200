import {
  getBlogCategoryList,
  getTagList,
} from '@/api/pageManager/Blog/BlogEditor';
import { useEffect, useState } from 'react';

export default function useGetData() {
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  // 获取分类信息
  const getCategoryListRequest = async () => {
    try {
      const res: any = await getBlogCategoryList({
        status: 1,
      });
      console.log(res);
      if (res.code === '200') {
        if (res?.data) {
          setCategoryList(res?.data);
        }
      }
    } catch (error) {}
  };
  const getTagListRequest = async () => {
    try {
      const res: any = await getTagList({
        status: 1,
      });
      console.log('tagList', res);
      if (res.code === '200') {
        if (res?.data) {
          setTagList(res?.data);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getCategoryListRequest();
    getTagListRequest();
  }, []);
  return {
    categoryList,
    tagList,
  };
}
