import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

const routes: Route[] = [
    {
        name: '新建站内信',
        router: 'Message/MessageManager/edit/:type/:id?',
        component: lazyload(() => import('@/pages/Message/MessageManagerEdit')),
    }
];
export default routes;