export default {
  'menu.home': 'Home',
  'menu.pageManager': 'page manager',
  'menu.primaryPage': 'primary manager page',
  'navbar.logout': 'logout',
  'settings.otherSettings': 'Other Settings',
  'settings.colorWeek': 'Color Week',
  'settings.alertContent':
      'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
  'settings.copySettings': 'Copy Settings',
  'settings.copySettings.message':
      'Copy succeeded, please paste to file src/settings.json.',
  'settings.close': 'Close',
  'settings.color.tooltip':
      '10 gradient colors generated according to the theme color',
  'message.tab.title.message': 'Message',
  'message.tab.title.notice': 'Notice',
  'message.tab.title.todo': 'ToDo',
  'message.allRead': 'All Read',
  'message.seeMore': 'SeeMore',
  'message.empty': 'Empty',
  'message.empty.tips': 'No Content',
  'message.lang.tips': 'Language switch to ',
  'navbar.search.placeholder': 'Please search',
};