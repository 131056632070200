import {
  DatePicker,
  Space,
  Modal,
  Input,
  Table,
  Image,
  Message,
} from '@arco-design/web-react';
const { RangePicker } = DatePicker;
const InputSearch = Input.Search;
import React, { useEffect, useState } from 'react';
import api from '@/api/resource';
import CreatSelect from '@/components/CreatSelect';
export default function ImgSelect(props) {
  const columns = [
    {
      title: '产品主图',
      dataIndex: 'picture',
      render: (text, record) => {
        return (
          <Image
            width={80}
            height={80}
            src={
              'https://resource.fs.com/mall' +
              record?.picturePath?.split('/mall')[1]
            }
          ></Image>
        );
      },
    },
    {
      title: '图片ID',
      dataIndex: 'id',
    },
    {
      title: '图片名称',
      dataIndex: 'pictureAlias',
      render: (text, record) => {
        return <>{text ? text : '--'}</>;
      },
    },
    {
      title: '上传时间',
      dataIndex: 'createdAt',
    },
    {
      title: '上传人',
      dataIndex: 'userName',
    },
    {
      title: '图片路径',
      dataIndex: 'picturePath',
      render: (text, record) => {
        return <>{text && text.split('mall/')[1]}</>;
      },
    },
  ];
  const { tableType = 'radio', onChange } = props;
  // 定义多选结果变量
  const [loading, setLoading] = useState(true);
  const [productList, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listFilter, setListFilter] = useState({
    userName: '',
    keyword: '',
    current: 1,
    size: 6,
    startTime: '',
    endTime: '',
    // pictureSuffix:'svg,png'
  });
  const [pagination, setPagination] = useState({
    sizeCanChange: true,
    showTotal: true,
    total: 10,
    pageSize: 6,
    current: 1,
    pageSizeChangeResetCurrent: true,
    showJumper: true,
  });
  function onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    listFilter.current = current;
    listFilter.size = pageSize;
    setListFilter({
      ...listFilter,
      current,
      size: pageSize,
    });
    console.log(current);
    setPagination((pagination) => ({
      ...pagination,
      current,
      pageSize,
    }));
    getList();
    setLoading(true);
  }
  const getList = (v?) => {
    setLoading(true);
    if (v) {
      listFilter.current = 1;
      setListFilter(listFilter);
      setPagination({ ...pagination, current: 1 });
    }
    api.getGImageList(listFilter).then((res) => {
      setList(res.data.records);
      console.log(pagination);
      pagination.current = res.data.current;
      setPagination({ ...pagination, total: res.data.total });
      setLoading(false);
    });
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <>
      <Space style={{ marginBottom: 20 }}>
        <RangePicker
          style={{}}
          onChange={(v = []) => {
            listFilter.startTime = v[0];
            listFilter.endTime = v[1];
            setListFilter({
              ...listFilter,
              startTime: v[0],
              endTime: v[1],
            });
            getList(1);
          }}
        />
        <CreatSelect
          placeholder="上传人名称"
          onChange={(id, name) => {
            listFilter.userName = name;
            setListFilter({
              ...listFilter,
              userName: name,
            });
            getList(1);
          }}
        />
        <InputSearch
          size="large"
          placeholder="图片ID/名称"
          onSearch={(v) => {
            getList(1);
          }}
          onChange={(v) => {
            listFilter.keyword = v;
            setListFilter({
              ...listFilter,
              keyword: v,
            });
          }}
        />
      </Space>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        data={productList}
        pagination={pagination}
        onChange={onChangeTable}
        rowSelection={{
          type: tableType,
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            onChange && onChange(selectedRows);
          },
          preserveSelectedRowKeys: false,
          checkCrossPage: false,
        }}
        renderPagination={(paginationNode) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 20,
            }}
          >
            <Space>{paginationNode}</Space>
          </div>
        )}
      />
    </>
  );
}
