import React from 'react';
import { Modal } from '@arco-design/web-react';
import '@/pages/PageManager/SolutionManager/NewDetail/style/Modal.less';
import { IconExclamationCircleFill } from '@arco-design/web-react/icon';

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  onOk?: () => void;
  content: React.ReactNode | string;
  title?: React.ReactNode | string;
}

function CustomModel(props: ModalProps) {
  const { open, onClose, onOk, content, title } = props;
  return (
    <Modal
      title={title || '提示框'}
      visible={open}
      onCancel={onClose}
      onOk={onOk}
      className="solution_modal"
      okButtonProps={{
        shape: 'round',
      }}
      cancelButtonProps={{
        shape: 'round',
      }}
    >
      <div className={'content'}>
        <IconExclamationCircleFill
          style={{ color: '#FF7D00', fontSize: '24px' }}
        />
        <div className={'content_text'}>{content}</div>
      </div>
    </Modal>
  );
}

export default CustomModel;
