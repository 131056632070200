import React, { useContext } from 'react'
import classNames from 'classnames';
import styles from './style/index.module.less'
import { handleResourcePath } from '@/utils/utils'
import { ProductsContext } from '../../index'



export default function QualityTestingProgram(props: any) {
	const context = useContext(ProductsContext)
	const { data } = props;

	return (
		<>
			{
				data ? (
					<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.quality_testing_program_wrap])} onClick={() => context.openSiderBox({
						componentId: data.componentId,
						block: data.block || '',
						type: data.type,
					})}>
						<div className={styles.quality_testing_program_title}>Quality Testing Program</div>
						{
							(data && data.data) ? (
								<div className={styles.quality_testing_program_content}>
									<img className={styles.testing_program_image} src={handleResourcePath(data.data.pcImage,false)} alt="" />
									<div className={styles.testing_program_ctn}>
										{
											(data && data.data && data.data.title) ? (
												<span className={styles.testing_program_title}>
													<a href={data.data.link ? data.data.link : 'javascript:;'} dangerouslySetInnerHTML={{ __html: data.data.title }}></a>
													<span className={classNames('iconfont', styles.iocnfont_right)}>&#xf047;</span>
												</span>
											) : null
										}
										{
											(data && data.data && data.data.text) ? (
												<p className={styles.testing_program_text} dangerouslySetInnerHTML={{ __html: data.data.text }}></p>
											) : null
										}
									</div>
								</div>
							) : (
								<div className={styles.quality_testing_program_empty}>产品质量检测图片区域</div>
							)
						}
					</div>
				) : null
			}
		</>
	)
}