import React, { useContext } from 'react'
import { Popover } from '@arco-design/web-react';
import { ProductsContext } from '../../index'
import { handleResourcePath,splitColonString } from '@/utils/utils'
import classNames from 'classnames';

import styles from './style/index.module.less'

export default function QualityCertifications(props: any) {
	const context = useContext(ProductsContext);
	const { data } = props;

	return (
		<>
			{
				data ? (
					<div className={classNames(`products_block_${context.relationId}_${data.type}`,[styles.quality_certifications_box])} onClick={() => context.openSiderBox({
						componentId: data.componentId,
						block: data.block || '',
						type: data.type,
					})}>
						<div className={styles.quality_certifications_title}>Quality Certifications</div>
						{
							(data && data.data) ? (
								<div className={styles.quality_certifications_content}>
									<div className={styles.certifications_icon_box}>
										{
											(data.data.iconList && data.data.iconList.length) ? (
												data.data.iconList.map((item, index) => {
													return <Popover position='bl' key={index} content={<p dangerouslySetInnerHTML={{ __html: item.des }}></p>}>
														<div className={styles.certifications_icon}>
															<img src={handleResourcePath(item.url)} alt="" />
														</div>
													</Popover>
												})
											) : null
										}
									</div>
									{
										data.data && data.data.remarks?(
											<div className={styles.notes}>
											
												
												{
													splitColonString(data.data.remarks) && splitColonString(data.data.remarks)[0]?(
														<span className={styles.notes_title}  dangerouslySetInnerHTML={{__html:splitColonString(data.data.remarks)[0]}}></span>
													):null
												}
												{
													splitColonString(data.data.remarks) && splitColonString(data.data.remarks)[1]?(
														<span className={styles.notes_text}   dangerouslySetInnerHTML={{__html:splitColonString(data.data.remarks)[1]}}></span>
													):null
												}
											</div>
										):null
									}
								</div>
							) : (
								<div className={styles.quality_testing_program_empty}>质量认证图片区域</div>
							)
						}
					</div>
				) : null
			}
		</>
	)
}