import React from 'react';
import Home from '@/assets/image/empty.png';
import styles from './style/index.module.less'

function SelfEmpty() {
  return (
    <div className={styles.self_empty}>
      <img src={Home}/>
      <p className={styles.message}>Wwwhy~ 这里好像什么也没有... </p>
    </div>
  );
}

export default SelfEmpty;
