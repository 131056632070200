import React, { ReactNode, useEffect } from 'react';
import styles from './index.module.less';
import {
  Button,
  ColorPicker,
  Form,
  FormInstance,
  Input,
  Modal,
} from '@arco-design/web-react';
import { IconDelete, IconPlus } from '@arco-design/web-react/icon';
import CustomImage from '../CustomImage';
import { PdfListItem } from '../PdfView';
const FormItem = Form.Item;
type PdfTableProps = {
  pdfList: PdfListItem[];
  form: FormInstance<any, any, string | number | symbol>;
};
const PdfTable: React.FC<PdfTableProps> = ({ pdfList, form }) => {
  useEffect(() => {
    form.setFieldValue('pdfList', [...pdfList]);
  }, [pdfList]);
  return (
    <div className={styles['pdf-table']}>
      <table>
        <thead>
          <tr>
            <th>封面图</th>
            <th>类型描述</th>
            <th>飞速圆色值</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <Form.List field="pdfList">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((item, index) => {
                    const isDisabled =
                      Array.isArray(form.getFieldsValue()?.pdfList) &&
                      !form.getFieldsValue()?.pdfList[index]?.isDefault;
                    return (
                      <tr key={item.key}>
                        <td>
                          <FormItem
                            field={item.field + 'cover'}
                            rules={[{ required: true, message: '请上传封面' }]}
                          >
                            <CustomImage />
                          </FormItem>
                        </td>
                        <td>
                          <FormItem
                            field={item.field + 'description'}
                            rules={[
                              { required: true, message: '请输入类型描述' },
                            ]}
                          >
                            <Input
                              placeholder="请输入类型描述"
                              style={{
                                borderRadius: 8,
                              }}
                            />
                          </FormItem>
                        </td>
                        <td>
                          <FormItem
                            field={item.field + 'circularColor'}
                            rules={[
                              { required: true, message: '请输入圆色值' },
                            ]}
                          >
                            <ColorPicker
                              style={{
                                borderRadius: 8,
                                width: '100%',
                              }}
                              showText
                            />
                            {/* <Input
                              placeholder="请输入圆色值"
                              style={{
                                borderRadius: 8,
                              }}
                              prefix={
                                <CircleComponent
                                  circularColor={form.getFieldValue(
                                    item.field + 'circularColor'
                                  )}
                                />
                              }
                              onChange={(value) => {
                                form.setFieldValue(
                                  item.field + 'circularColor',
                                  value
                                );
                              }}
                            /> */}
                          </FormItem>
                        </td>
                        <td style={{ display: 'none' }}>
                          <FormItem field={item.field + 'id'}>
                            <Input />
                          </FormItem>
                        </td>
                        <td style={{ display: 'none' }}>
                          <FormItem field={item.field + 'isDefault'}>
                            <Input />
                          </FormItem>
                        </td>
                        <td>
                          {isDisabled ? (
                            <span
                              onClick={() => {
                                Modal.confirm({
                                  title: '提示',
                                  content: '是否确定删除？',
                                  onOk: () => {
                                    remove(index);
                                  },
                                });
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <IconDelete style={{ color: '#165DFF' }} />
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={4}>
                      <FormItem
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <Button
                          style={{
                            width: '100%',
                            borderRadius: 8,
                          }}
                          type="outline"
                          icon={<IconPlus />}
                          onClick={() => {
                            add();
                          }}
                        >
                          新增封面
                        </Button>
                      </FormItem>
                    </td>
                  </tr>
                </>
              );
            }}
          </Form.List>
        </tbody>
      </table>
    </div>
  );
};

export default PdfTable;
