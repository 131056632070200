// 页面管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 产品管理数据看板详情路由
const routes: Route[] = [
  {
    name: '产品管理数据看板详情',
    router: 'home/ManageDashboard/Detail/List',
    component: lazyload(() => import('@/pages/home/ManageDashboard/Detail')),
  },
];
export default routes;
