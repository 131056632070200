import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from '@arco-design/web-react';
import {
  IconDelete,
  IconDragDotVertical,
  IconPlus,
} from '@arco-design/web-react/icon';
import CustomSelect from './CustomSelect';

type ItemType = {
  id: string | number;
  itemValue: string | number;
};
type PropsType = {
  onChange?: (value: ItemType[]) => void;
  value?: ItemType[];
};
type DragItem = ItemType & {
  dragId: string;
};
const CustomAddText: React.FC<PropsType> = (props) => {
  const { onChange, value = [] } = props;
  const [items, setItems] = useState<DragItem[]>([]);
  useEffect(() => {
    // if (value.length === 0 && items.length === 0) {
    //   handleAddItem();
    // }
    if (value.length === 0) return;
    //添加唯一的拖拽id
    const newItems = [...value].map((item, index) => {
      return {
        id: item.itemValue,
        dragId: `${item.itemValue}-${index}`,
        ...item,
        // itemValue: item.id,
      };
    });
    console.log(newItems);
    setItems(newItems);
  }, [value]);
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // 检查是否在同一个列表中
    if (!destination) {
      return;
    }

    // 移动元素
    const newItems = Array.from(items);
    const [removedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removedItem);
    setItems(newItems);
  };
  const handleDeleteItem = (id: string | number) => {
    console.log(id);
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
    onChange && onChange(newItems);
  };

  const handleAddItem = () => {
    const newItemId = 'item_' + Date.now(); // 假设使用时间戳生成唯一 ID
    setItems([...items, { id: newItemId, itemValue: '', dragId: newItemId }]);
  };
  // 转换数字为字符串的辅助函数
  // const toStringId = (id: number) => id.toString();
  const handleChange = (value, index) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, itemValue: value };
      }
      return item;
    });
    console.log(newItems);
    setItems(newItems);
    onChange && onChange(newItems);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.dragId} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 12,
                      }}
                    >
                      <div
                        {...provided.dragHandleProps} // 拖拽手柄属性
                        style={{
                          cursor: 'move', // 设置鼠标指针为移动图标
                          width: '24px',
                          height: '24px',
                          display: 'inline-block',
                        }}
                      >
                        <IconDragDotVertical />
                      </div>
                      <CustomSelect
                        value={item.itemValue}
                        onChange={(value) => handleChange(value, index)}
                      />
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDeleteItem(item.id)}
                      >
                        <IconDelete style={{ color: '#165DFF' }} />
                      </span>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button
        type="outline"
        onClick={handleAddItem}
        icon={<IconPlus />}
        style={{ borderRadius: 8 }}
      >
        添加文章
      </Button>
    </DragDropContext>
  );
};

export default CustomAddText;
