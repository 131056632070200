import React, { useEffect, useMemo, useRef } from 'react';
import { Modal, Image, Message } from '@arco-design/web-react';
import ResourceTable from './component/ResourceTable';
import { handleResourcePath } from '@/utils/utils';

interface ResourceProps {
  imageSelectVisible: boolean;
  type?: string; //资源类型，视频或者图片
  handleOk?: (data: any) => void;
  onCancel?: () => void;
  tableType?: 'checkbox' | 'radio';
  imageLimt?: Array<string>;
  limitMessage?: string;
}

const ImageSelect: React.FC<ResourceProps> = ({
  imageSelectVisible = false,
  type = 'image',
  handleOk,
  onCancel,
  tableType,
  imageLimt = [],
  limitMessage = '',
}) => {
  const selectData = useRef<Array<any>>();
  const sourceType = useMemo(() => {
    if (type) {
      return type;
    }
  }, [type]);
  const imageColums = [
    {
      title: '图片',
      dataIndex: 'picturePath',
      render: (col, record, index) => {
        const imgsrc = record.picturePath
          ? handleResourcePath(record.picturePath, false)
          : null;
        return <Image width={80} height={80} src={imgsrc} />;
      },
      // width: '100px',
    },
    {
      title: '图片名称',
      dataIndex: 'pictureAlias',
    },
    // {
    //   title: '图片尺寸',
    //   dataIndex: 'pictureTypeName',
    //   // width:'140px'
    // },
    // {
    //   title: '分类信息',
    //   dataIndex: 'cname',
    //   width: '200px',
    // },
    // {
    //   title: '',
    //   dataIndex: 'op',
    //   render: (col, record, index) => (
    //     <span
    //       style={{ color: '#165DFF', cursor: 'pointer' }}
    //       onClick={() => deleteImage(index)}
    //     >
    //       删除
    //     </span>
    //   ),
    //   width: '100px',
    // },
  ];
  const videoColums = [
    {
      title: '视频封面',
      dataIndex: 'videoCover',
      render: (col, record, index) => (
        <Image
          width={80}
          height={80}
          src={
            record.videoCover
              ? record.videoCover.includes('http')
                ? record.videoCover
                : handleResourcePath(record.videoCover)
              : ''
          }
        />
      ),
      width: '100px',
    },
    {
      title: '视频名称',
      dataIndex: 'videoName',
    },
    {
      title: '视频信息',
      dataIndex: 'videoSize',
      render: (col, record, index) => (
        <div>
          {record.videoSize}/{record.videoTime}
        </div>
      ),
      width: '140px',
    },
    {
      title: '分类信息',
      dataIndex: 'cname',
      render: (col, record, index) => <div>{record.cname}</div>,
      width: '200px',
    },
    // {
    //   title: '',
    //   dataIndex: 'op',
    //   render: (col, record, index) => (
    //     <span
    //       style={{ color: '#165DFF', cursor: 'pointer' }}
    //       onClick={() => deleteVideo(index)}
    //     >
    //       删除
    //     </span>
    //   ),
    // },
  ];
  const sourceColum = useMemo(() => {
    return type === 'image' ? imageColums : videoColums;
  }, [type]);
  const confirmSelect = () => {
    if (!selectData.current || !selectData.current.length) {
      Message.warning('请选择资源文件');
      return;
    }
    if (imageLimt.length == 0) {
      handleOk && handleOk(selectData.current);
      return;
    }
    //检验资源格式是否正确
    const lastIndex = selectData.current[0].picturePath.lastIndexOf('.') + 1;
    const imageType = selectData.current[0].picturePath.substring(lastIndex);
    console.log(imageType);

    if (imageLimt.includes(imageType)) {
      handleOk && handleOk(selectData.current);
    } else {
      Message.warning(limitMessage);
      return;
    }
  };
  const selectSourceChange = (data) => {
    console.log(data);
    selectData.current = data;
  };
  useEffect(() => {
    // console.log(data);
    console.log(imageSelectVisible);
  }, [imageSelectVisible]);
  return (
    <>
      <Modal
        title={
          <div style={{ textAlign: 'left' }}>
            {sourceType === 'video' ? '视频' : '图片'}选择
          </div>
        }
        visible={imageSelectVisible}
        style={{ width: '1280px' }}
        autoFocus={false}
        onCancel={() => {
          onCancel && onCancel();
        }}
        onOk={confirmSelect}
      >
        {imageSelectVisible ? (
          <ResourceTable
            sourceType={sourceType}
            onChange={selectSourceChange}
            type={tableType}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default ImageSelect;
