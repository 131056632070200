import qs from 'query-string';
export default function checkLogin() {
  const params = qs.parseUrl(window.location.href);
  console.log(params.query);
  const { query } = params;
  const queryKey = Object.keys(query);
  if (queryKey.length > 0 && queryKey.includes('code')) {
    return query;
  }
  return false;
}
