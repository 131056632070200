/*
 * @Author: Jabin
 * @Date: 2022-11-15 15:39:14
 * @LastEditors: Jabin
 * @LastEditTime: 2022-12-09 14:39:41
 * @Descripttion:
 */
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 产品关联
const routes: Route[] = [
  {
    name: '关联组管理',
    router: 'GoodsManager/ProductRelate/RelateGroupManager',
    component: lazyload(
      () => import('@/pages/GoodsManager/RelevancyManage/index')
    ),
  },
  {
    name: '关联组基本信息列表页',
    router: 'ProductRelate/RelateGroupManager/InfoList/:productId',
    component: lazyload(
      () => import('@/pages/GoodsManager/RelevancyManage/InfoList')
    ),
  },
  {
    name: '关联组基本信息详情页',
    router: 'ProductRelate/RelateGroupManager/Detail/:productId/:id?',
    component: lazyload(
      () => import('@/pages/GoodsManager/RelevancyManage/Detail')
    ),
  },
  {
    name: '关联组生成器编辑页',
    router: 'GoodsManager/ProductRelate/RelateGroupGenerator',
    component: lazyload(
      () => import('@/pages/GoodsManager/ProductRelate/RelateGroupGenerator')
    ),
  },
  {
    name: '关联组生成器编辑页',
    router: 'GoodsManager/RelateGroupGenerator/Detail/:id',
    component: lazyload(
      () =>
        import('@/pages/GoodsManager/ProductRelate/RelateGroupGenerator/Detail')
    ),
  },
  {
    name: '关联组生成器编辑页',
    router: 'GoodsManager/RelateGroupGenerator/Preview/:id',
    component: lazyload(
      () =>
        import(
          '@/pages/GoodsManager/ProductRelate/RelateGroupGenerator/Preview'
        )
    ),
  },
  {
    name: 'PLM后台分类',
    router: 'GoodsManager/PlmCategory',
    component: lazyload(() => import('@/pages/GoodsManager/PlmCategory')),
  },
  {
    name: '编辑商城分类',
    router: 'GoodsManager/CategoryManagement/EditCategory',
    component: lazyload(() => import('@/pages/GoodsManager/EditCategory')),
  },
  {
    name: '商城分类管理',
    router: 'GoodsManager/CategoryManagement',
    component: lazyload(
      () => import('@/pages/GoodsManager/CategoryManagement')
    ),
  },

  {
    name: '非模块库存关联',
    router: 'GoodsManager/InventoryAssociation/NonModule',
    component: lazyload(
      () => import('@/pages/GoodsManager/InventoryAssociation/NonModule')
    ),
  },

  {
    name: '商品全局设置',
    router: 'GoodsManager/GlobalSetting',
    component: lazyload(() => import('@/pages/GoodsManager/GlobalSetting')),
  },
];
export default routes;
