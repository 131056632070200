import React from 'react';
import { Button, Modal } from '@arco-design/web-react';
import { IconExclamationCircleFill } from '@arco-design/web-react/icon';
type PropsType = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onOk: () => void;
  title: string;
  content: string;
  style?: React.CSSProperties;
};
const ConfirmModal: React.FC<PropsType> = ({
  visible,
  setVisible,
  onOk,
  title,
  content,
  style = {},
}) => {
  return (
    <>
      <Modal
        title={<div style={{ textAlign: 'left' }}>{title}</div>}
        maskClosable={false}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        style={style}
        footer={
          <>
            <Button
              type="secondary"
              shape="round"
              onClick={() => {
                setVisible(false);
              }}
            >
              取消
            </Button>
            <Button shape="round" type="primary" onClick={onOk}>
              确定
            </Button>
          </>
        }
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconExclamationCircleFill
            style={{ color: '#FF7D00', marginRight: 16, fontSize: 20 }}
          />
          <span>{content}</span>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
