import { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
import { moduleRoutes } from '@/router';
import lazyload from './utils/lazyload';


export type Route = AuthParams & {
  name: string;
  key?: string;
  breadcrumb?: boolean;
  children?: Route[];
  router?: string;
  routerName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
};

export const routes: Route[] = moduleRoutes;

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
//
export const getRouteName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.router}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getRouteName(path, item.children);
    }
  });
};
//
export const getbram = (data) => {
  const res = [];
  function translate(datas) {
    datas.forEach(item => {
      if (item.children && item.children.length > 0) {
        res.push(item.name);
        translate(item.children);
      } else {
        res.push(item.name);
      }
    });
  }
  translate(data);
  return res;
};
// 菜单转换
export const translateMenu = (permission) => {
  let res = [];
  const filterRoute = (userRoutes: Route[], arr = []): Route[] => {
    if (userRoutes.length === 0) {
      return res;
    }
    for (const route of userRoutes) {
      if (route.children && route.children.length) {
        // component:lazyload(() => import(`./pages/${route.router}`))
        const newRoute = { name: route.name, children: [], key: route.router };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        const thereRoute = { name: route.name, key: route.router, component: lazyload(() => import(`./pages/${route.router}`)) };
        arr.push({ ...thereRoute });
      }
    }

    return arr;
  };
  res = filterRoute(permission);
  return res;
};

const useRoute = (userPermission): [Route[], string] => {
  const menu = [...routes, ...userPermission];
  const filterRoute = (userRoutes: Route[], arr = []): Route[] => {
    if (userRoutes.length === 0) {
      return arr;
    }
    for (const route of userRoutes) {
      if (route.children && route.children.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newRoutes: any = { children: [], key: route.router };
        if (!route.component) {
          newRoutes = { ...newRoutes, component: lazyload(() => import(`./pages/${route.router}`)) };
        }
        filterRoute(route.children, newRoutes.children);
        if (newRoutes.children.length) {
          arr.push(newRoutes);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let thereRoute: any = { key: route.router };
        if (!route.component) {
          thereRoute = { ...thereRoute, component: lazyload(() => import(`./pages/${route.router}`)) };
        } else {
          thereRoute = { ...thereRoute, component: route.component };
        }
        arr.push({ ...thereRoute });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState([]);

  useEffect(() => {
    const newRoutes = filterRoute(menu);
    setPermissionRoute(newRoutes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;
