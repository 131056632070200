import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Form,
  Input,
  Button,
  Switch,
  InputNumber,
  Space,
  Message,
  Image,
  Select,
  Tag,
  Spin,
  Popover,
} from '@arco-design/web-react';
import {
  IconPlus,
  IconSearch,
  IconDelete,
  IconDragDotVertical,
} from '@arco-design/web-react/icon';
import {
  ResType,
  getGlossaryList,
  postAddGlossaryDetail,
  getDefalutTopSearchesNum,
  postEditGlossaryDetail,
} from '@/api/GlossaryManager';
import styles from '../style/index.module.less';
import FsUpload from '@/components/FsUpload/index';
import IconSvg from '@/components/IconSvg';
import CustomModal from '@/pages/PageManager/GlossaryManager/Edit/components/CustomModel';
import { useHistory } from 'react-router-dom';
import Sortable from 'sortablejs';
import classnames from 'classnames/bind';
import BindTagModel from './bindTagModel';
import { tagProps } from '../../index';
import { tagBgColor, tagTextColor } from '../../components/entry';
import qs from 'query-string';

const cx = classnames.bind(styles);
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;

interface Props {
  documentId: string;
  initValue: any;
  loading: boolean;
  resourceName: string;
  setResourceName: (name: string) => void;
}

const GlossarySlide = (props: Props) => {
  const { documentId, initValue, loading, resourceName } = props;
  const search = qs.parseUrl(window.location.href);
  const { id } = search.query;
  const itemElRef = useRef<HTMLDivElement>(null);
  // const tagModelRef = useRef<TagModelRef | null>();
  const [form] = Form.useForm();
  const history = useHistory();
  const [field, setField] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [coverUrl, setCoverUrl] = useState({
    metaUrl: '',
    imageUrl: '',
  });
  const [coverVisible, setCoverVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [tagVisible, setTagVisible] = useState<boolean>(false);
  const [glossaryList, setGlossaryList] = useState([]);
  const [tagList, setTagList] = useState<tagProps[]>([]);
  const [tagStatus, setTagStatus] = useState(false);
  const [topSearchNum, setTopSearchNum] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      Sortable.create(itemElRef.current, {
        animation: 150,
        handle: '.move',
        // disabled: !isMainStation,
        onEnd: function ({ oldIndex, newIndex, from, item }) {
          setIsOpen(true);
          if (newIndex === oldIndex) return;
          const items = document.querySelectorAll('.list .item');
          if (oldIndex > newIndex) {
            from.insertBefore(item, items[oldIndex + 1]);
          } else {
            from.insertBefore(item, items[oldIndex]);
          }
          const { glossaryIds } = form.getFieldsValue();
          glossaryIds.splice(newIndex, 0, glossaryIds.splice(oldIndex, 1)[0]);
          form.setFieldsValue({ glossaryIds });
        },
        onStart: function () {
          setIsOpen(false);
        },
      });
    }, 300);
    getGlossaryListApi();
    getApiDefalutTopSearchesNum();
  }, []);

  useEffect(() => {
    const name =
      resourceName.trim() === '' || resourceName.trim() === '​'
        ? ''
        : resourceName;
    if (!initValue?.metaTitle) {
      form.setFieldValue('metaTitle', name);
    }
    if (!initValue?.originalArticleUrl) {
      form.setFieldValue('originalArticleUrl', name);
    }
  }, [resourceName]);

  useEffect(() => {
    if (!initValue) {
      form.setFieldValue('isShow', true);
    } else {
      initValueFun();
    }
  }, [initValue]);

  const initValueFun = () => {
    console.log(initValue);
    const values = {
      ...initValue,
      solution: [
        { title: initValue.solutionOneTitle, url: initValue.solutionOneUrl },
        { title: initValue.solutionTwoTitle, url: initValue.solutionTwoUrl },
      ],
      glossaryIds: initValue.otherGlossaryIdArray.map((v) => v.id),
    };
    form.setFieldsValue(values);
    setCoverUrl({
      metaUrl: initValue.metaUrl,
      imageUrl: initValue.imageUrl,
    });
    setTagList(initValue.bindLabelIdsArray);
  };

  const getGlossaryListApi = () => {
    getGlossaryList({ current: 1, size: 99999 }).then((res: ResType) => {
      if (res.code === '200') {
        setGlossaryList(res.data.records);
      } else {
        Message.error(res.msg);
      }
    });
  };

  const getApiDefalutTopSearchesNum = () => {
    getDefalutTopSearchesNum().then((res: ResType) => {
      if (res.code === '200') {
        setTopSearchNum(res.data || 0);
      } else {
        Message.error(res.msg);
      }
    });
  };

  function beforeUpload(
    file: any,
    isSize = false,
    isFlag = false,
    size?: number,
    width?: number,
    height?: number
  ) {
    console.log(file);
    const fileType = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/svg+xml',
      'image/svg',
      'image/webp',
    ];
    if (!fileType.includes(file.type)) {
      Message.warning('Warning~上传文件格式错误');
      return false;
    }
    if (isSize && file.size > 1024 * 1024 * size) {
      Message.warning('图片大小不能超过300k');
      return false;
    }
    setUploading(true);
    if (isFlag) {
      return new Promise(function (resolve, reject) {
        const _URL = window.URL || window.webkitURL;
        const image = document.createElement('img');
        image.src = _URL.createObjectURL(file);
        image.onload = function () {
          const valid = image.width === width && image.height === height;
          valid ? resolve('') : reject('');
        };
      }).then(
        () => {
          return file;
        },
        () => {
          Message.error(
            '上传图片尺寸不符合,尺寸为' + width + '*' + height + '!'
          );
          return Promise.reject();
        }
      );
    } else {
      return file;
    }
  }
  const renderImgUrl = (url) => {
    if (url) {
      return 'https://resource.fs.com/' + url;
    } else {
      return '';
    }
  };

  const successCallback = (res, field: string) => {
    setField(field);
    form.setFieldValue(field, res.key);
    setCoverUrl({
      ...coverUrl,
      [field]: res.key,
    });
  };

  const handleClose = (removeTag) => {
    console.log('Removing tag:', removeTag);
    const newTags = tagList.filter((tag) => tag.id !== removeTag);
    console.log('New tags after filter:', newTags);
    setTagList([...newTags]);
  };

  const tipContent = () => {
    return (
      <div
        style={{
          // padding: '16px 20px',
          fontSize: 13,
          color: '#4E5969',
        }}
      >
        用于新链接与原词条链接进行关联绑定，301重定向
      </div>
    );
  };
  const oldLabel = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>原词条URL</span>
        <Popover position="tl" content={tipContent()}>
          <img
            className={styles['base-info-first-operation']}
            src="https://resource.fs.com/mall/generalImg/202407231030581b3zij.svg"
            style={{
              margin: '0 4px',
            }}
          />
        </Popover>
        <span>：</span>
      </div>
    );
  };

  const submit = async () => {
    //字符 U+200b 不可见
    if (resourceName.trim() === '' || resourceName.trim() === '​') {
      Message.error('请输入文章标题');
      return;
    }
    if (resourceName.trim().length >= 100) {
      Message.error('文章标题应少于100个字符');
      return;
    }
    try {
      let values = null;
      if (tagList.length <= 0) {
        setTagStatus(true);
        values = await form.validate();
        return;
      } else {
        setTagStatus(false);
      }
      values = await form.validate();
      if (values) {
        const data = {
          ...values,
          title: resourceName,
          documentId,
          labelIds: tagList.map((v) => v.id),
          glossaryIds:
            values?.glossaryIds?.length > 0
              ? values?.glossaryIds?.filter(Boolean)
              : undefined,
          isShow: values?.isShow ? 1 : 0,
          isTopSearches: values?.isTopSearches ? 1 : 0,
          solutionOneTitle: values?.solution?.[0]?.title,
          solutionOneUrl: values?.solution?.[0]?.url,
          solutionTwoTitle: values?.solution?.[1]?.title,
          solutionTwoUrl: values?.solution?.[1]?.url,
        };
        console.log(data);
        const api = id ? postEditGlossaryDetail : postAddGlossaryDetail;
        api(id ? { ...data, id } : data).then((res: ResType) => {
          if (res.code === '200') {
            Message.success('保存成功');
            history.push('/PageManager/GlossaryManager');
          } else {
            Message.error(res.msg);
          }
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className={styles.GlossarySlide}>
      <Spin loading={loading} style={{ width: '100%' }}>
        <Form
          layout="vertical"
          form={form}
          onValuesChange={(value) => {
            if (value.isShow === false) {
              form.setFieldValue('isTopSearches', false);
            }
            if (value.isTopSearches === true) {
              form.setFieldValue('topSearchesOrderNum', topSearchNum);
            }
          }}
        >
          <FormItem
            label="Meta标题："
            field="metaTitle"
            rules={[{ required: true, message: '请输入Meta标题' }]}
          >
            <Input maxLength={100} showWordLimit placeholder="请输入Meta标题" />
          </FormItem>
          <FormItem
            label="Meta描述："
            field="metaDes"
            rules={[{ required: true, message: '请输入Meta描述' }]}
          >
            <TextArea
              placeholder="请输入Meta描述"
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </FormItem>
          <FormItem
            label="Meta图片："
            field="metaUrl"
            style={{ width: '100px' }}
          >
            <FsUpload
              style={{
                pointerEvents: uploading ? 'none' : 'inherit',
                width: '100%',
              }}
              listType="picture-card"
              uploadProps={{
                accept:
                  'image/jpg,image/jpeg,image/svg+xml,image/svg,image/png,image/webp',
                beforeUpload,
              }}
              accept="image/*"
              uploadPath="mall/generalImg"
              successCallback={(res) => {
                successCallback(res, 'metaUrl');
              }}
            >
              <div
                className={styles.uploadCoverDefault}
                onClick={() => {
                  setField('metaUrl');
                }}
              >
                {coverUrl.metaUrl ? (
                  <div className={styles.imgBox}>
                    <img src={renderImgUrl(coverUrl.metaUrl)} alt="" />
                    <div>
                      <div className={styles.editBtn}>
                        <Space size="large">
                          <IconSearch
                            onClick={(e) => {
                              e.stopPropagation();
                              setCoverVisible(true);
                            }}
                          />
                          <IconDelete
                            onClick={(e) => {
                              e.stopPropagation();
                              successCallback({ key: '' }, 'metaUrl');
                            }}
                          />
                        </Space>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <IconSvg type="icon-fs_2022041424icon" />
                    <p>上传图片</p>
                  </div>
                )}
              </div>
            </FsUpload>
          </FormItem>
          <Image.Preview
            src={renderImgUrl(coverUrl.metaUrl)}
            visible={coverVisible}
            onVisibleChange={setCoverVisible}
          />
          <FormItem
            label="更换词条URL（填写格式为词条标题）："
            field="articleUrlTitle"
          >
            <Input placeholder="请输入词条标题" />
          </FormItem>
          {id && (
            <FormItem
              label="词条URL："
              field="articleUrl"
              disabled
              rules={[{ required: true, message: '请输入词条URL' }]}
            >
              <Input placeholder="请输入词条URL" />
            </FormItem>
          )}
          <FormItem label={oldLabel()} field="originalArticleUrl">
            <Input placeholder="请输入原词条URL，用于新链接与原词条链接进行关联绑定" />
          </FormItem>
          <FormItem
            label="项目编号："
            field="projectNumber"
            rules={[{ required: true, message: '请输入项目编号' }]}
          >
            <Input placeholder="请输入项目编号" />
          </FormItem>

          <div className={styles.resizebox_line} />

          <FormItem
            label={
              <span>
                封面图：
                <span style={{ color: '#9FA2B4' }}>
                  （支持格式Webp/jpeg/jpg/png，上传尺寸974*548）
                </span>
              </span>
            }
            field="imageUrl"
            rules={[
              {
                required: true,
                message:
                  '请上传封面图 / 仅支持webp、jpeg、png格式，尺寸974*548',
              },
            ]}
          >
            <FsUpload
              style={{
                pointerEvents: uploading ? 'none' : 'inherit',
                width: '100%',
              }}
              listType="picture-card"
              uploadProps={{
                accept:
                  'image/jpg,image/jpeg,image/svg+xml,image/svg,image/png,image/webp',
                beforeUpload: (file) =>
                  beforeUpload(file, true, true, 0.3, 974, 548),
              }}
              accept="image/*"
              uploadPath="mall/generalImg"
              successCallback={(res) => {
                successCallback(res, 'imageUrl');
              }}
            >
              <div
                className={styles.uploadCover}
                onClick={() => {
                  setField('imageUrl');
                }}
              >
                {coverUrl.imageUrl ? (
                  <div className={styles.imgBox}>
                    <img src={renderImgUrl(coverUrl.imageUrl)} alt="" />
                    <div>替换图片</div>
                  </div>
                ) : (
                  <div>
                    <IconSvg type="icon-fs_2022041424icon" />
                    <p>上传图片</p>
                  </div>
                )}
              </div>
            </FsUpload>
          </FormItem>
          <FormItem
            label="文章概述："
            field="summaryDes"
            rules={[{ required: true, message: '请输入文章概述' }]}
          >
            <TextArea
              placeholder="请输入文章概述"
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </FormItem>
          <FormItem
            label="绑定标签："
            field="labelIds"
            rules={[{ required: true }]}
          >
            {tagList.length > 0 && (
              <div className={styles.bindTags}>
                {tagList.map((tag: tagProps, index) => {
                  const idx = index % tagList.length;
                  return (
                    <Tag
                      key={index + '-' + tag.id}
                      closable
                      onClose={() => handleClose(tag.id)}
                      color={tagBgColor[idx]}
                      style={{
                        color: tagTextColor[idx],
                        margin: '0 8px 8px 0',
                      }}
                      size="medium"
                    >
                      {tag.name}
                    </Tag>
                  );
                })}
              </div>
            )}
            <Button
              type="outline"
              size="large"
              icon={<IconPlus />}
              style={{ borderRadius: 8 }}
              onClick={() => {
                setTagVisible(true);
              }}
            >
              绑定标签
            </Button>
            {tagStatus && (
              <p
                style={{
                  fontSize: 12,
                  marginTop: 2,
                  color: 'rgb(245, 63, 63)',
                }}
              >
                请绑定标签
              </p>
            )}
          </FormItem>

          <FormItem label="关联其他词条：">
            <Form.List field="glossaryIds">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <div ref={itemElRef} className="list">
                      {fields.map((item, index) => (
                        <div
                          key={index}
                          className={cx('drag_item_box', 'item')}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 12,
                            }}
                          >
                            <IconDragDotVertical
                              className={cx('drag_icon', 'move')}
                              fontSize={16}
                              style={{ marginRight: 8 }}
                            />
                            <FormItem
                              field={item.field}
                              // rules={[
                              //   { required: true, message: '请选择词条' },
                              // ]}
                              style={{ marginBottom: 0, width: 377 }}
                            >
                              <Select
                                placeholder="请选择词条"
                                showSearch
                                filterOption={(inputValue, option) => {
                                  console.log(option);
                                  return (
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(inputValue.toLowerCase()) >= 0
                                  );
                                }}
                              >
                                {glossaryList.length > 0 &&
                                  glossaryList.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                      {v.title}
                                    </Option>
                                  ))}
                              </Select>
                            </FormItem>
                            <div onClick={() => remove(index)}>
                              <IconDelete
                                style={{
                                  fontSize: 16,
                                  color: '#165DFF',
                                  marginLeft: 8,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <FormItem noStyle shouldUpdate>
                      {(values) => {
                        const glossaryIds = form.getFieldValue('glossaryIds');
                        return glossaryIds === undefined ||
                          glossaryIds.length < 6 ? (
                          <Button
                            type="outline"
                            size="large"
                            icon={<IconPlus />}
                            style={{ borderRadius: 8 }}
                            onClick={() => {
                              add();
                            }}
                          >
                            添加词条
                          </Button>
                        ) : null;
                      }}
                    </FormItem>
                  </>
                );
              }}
            </Form.List>
          </FormItem>
          <FormItem label="绑定解决方案：" rules={[{ required: true }]}>
            <p style={{ color: '#4E5969', fontSize: 12, marginBottom: 8 }}>
              Solution1
            </p>
            <FormItem
              field="solution[0].title"
              rules={[{ required: true, message: '请输入标题' }]}
            >
              <Input maxLength={100} showWordLimit placeholder="请输入标题" />
            </FormItem>
            <FormItem
              field="solution[0].url"
              rules={[{ required: true, message: '请输入链接' }]}
            >
              <Input placeholder="请输入链接" />
            </FormItem>
            <p style={{ color: '#4E5969', fontSize: 12, marginBottom: 8 }}>
              Solution2
            </p>
            <FormItem
              field="solution[1].title"
              rules={[{ required: true, message: '请输入标题' }]}
            >
              <Input maxLength={100} showWordLimit placeholder="请输入标题" />
            </FormItem>
            <FormItem
              field="solution[1].url"
              rules={[{ required: true, message: '请输入链接' }]}
            >
              <Input placeholder="请输入链接" />
            </FormItem>
            <div
              className={styles.resizebox_line}
              style={{ marginBottom: 0 }}
            />
          </FormItem>
          <div className={styles.switchFromItem}>
            <div className={styles.label}>
              <strong>*</strong>
              <span>设置前台展示状态：</span>
            </div>
            <FormItem field="isShow" triggerPropName="checked" noStyle>
              <Switch size="small" />
            </FormItem>
          </div>
          <div className={styles.switchFromItem}>
            <div className={styles.label}>
              <strong>*</strong>
              <span>是否设置为Top Searches：</span>
            </div>
            <FormItem field="isTopSearches" triggerPropName="checked" noStyle>
              <Switch size="small" />
            </FormItem>
          </div>
          <FormItem noStyle shouldUpdate>
            {(values) => {
              const isTopSearches = form.getFieldValue('isTopSearches');
              return isTopSearches ? (
                <FormItem
                  label="Top排序："
                  field="topSearchesOrderNum"
                  rules={[{ required: true, message: '请输入Top排序' }]}
                >
                  <InputNumber
                    style={{ width: 122 }}
                    placeholder="请输入Top排序"
                    min={0}
                    max={999999}
                  />
                </FormItem>
              ) : null;
            }}
          </FormItem>
        </Form>
      </Spin>
      <div
        className={styles.resizebox_line}
        style={{ margin: '0 -20px 16px -20px' }}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="secondary"
          shape="round"
          style={{ width: '48%' }}
          onClick={() => {
            setVisibleModal(true);
          }}
        >
          预览
        </Button>
        <Button
          type="primary"
          shape="round"
          style={{ width: '48%' }}
          onClick={submit}
        >
          提交
        </Button>
      </div>
      <CustomModal
        open={visibleModal}
        onClose={() => setVisibleModal(false)}
        onOk={() => {
          window.open(
            process.env.REACT_APP_FS_URL +
              '/glossary_preview.html?id=' +
              documentId,
            '_blank'
          );
          setVisibleModal(false);
        }}
        content={'预览链接为测试站，24小时有效，过后可再次点击“预览”更新。'}
      />
      <BindTagModel
        visible={tagVisible}
        setVisible={setTagVisible}
        tagList={tagList}
        setTagList={setTagList}
      />
    </div>
  );
};

export default GlossarySlide;
