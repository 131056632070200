import './style/global.less';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider, Empty } from '@arco-design/web-react';
import zhCN from '@arco-design/web-react/es/locale/zh-CN';
import enUS from '@arco-design/web-react/es/locale/en-US';
import { BrowserRouter, Switch, Route, Router } from 'react-router-dom';
import store from '@/store';
import PageLayout from './layout';
import { GlobalContext } from './context';
import Login from './pages/login';
import Preview from './pages/preview';
import GlPreview from '@/pages/GlobalLink/Detail/PreviewComponent';
import changeTheme from './utils/changeTheme';
import useStorage from './utils/useStorage';
import Auth from '@/components/Auth';
import Detail from '@/pages/Resource/DocumentManager/Edit';
import GlossaryDetail from '@/pages/PageManager/GlossaryManager/Edit';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import SelfEmpty from '@/components/SelfEmpty';
import Monitor from './components/Monitor';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import BlogEditorDetail from '@/pages/PageManager/BlogManager/BlogEditor/component/Editor';

const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();
// Sentry.init({
//   dsn: 'https://6620fbfdf72f2fd73dfe9687830d6b33@o4506675741327360.ingest.sentry.io/4506675745062912',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     }),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   release: process.env.REACT_APP_ENV + 'liveChat',
//   environment: process.env.REACT_APP_ENV,
// });
// Sentry.init({

//   dsn: 'https://0cdde06054344bf4a399595da472b93e@sentry.whgxwl.com/3',
//   integrations: [],
//   release: process.env.REACT_APP_ENV + 'cms',
//   environment: process.env.REACT_APP_ENV,
//   tracesSampleRate: 1.0,
//   // 配置 token header
// });
// Sentry.init({
//   dsn: 'https://429aad7ce4ad2202ecc3a27c4c69070b@sentry.fs.com/3',
//   enabled: true,
//   release: process.env.REACT_APP_ENV + 'cms',
//   environment: process.env.REACT_APP_ENV,
//   integrations: [
//     Sentry.reactRouterV5BrowserTracingIntegration({ history }),
//     Sentry.replayIntegration(),
//   ],
//   sampleRate: 1,
//   normalizeDepth: 10,
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const persistor = persistStore(store);
function Index() {
  const [lang, setLang] = useStorage('arco-lang', 'zh-CN');
  const [theme, setTheme] = useStorage('arco-theme', 'light');

  function getArcoLocale() {
    switch (lang) {
      case 'zh-CN':
        return zhCN;
      case 'en-US':
        return enUS;
      default:
        return zhCN;
    }
  }

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  const contextValue = {
    lang,
    setLang,
    theme,
    setTheme,
  };
  function renderEmpty(componentName) {
    switch (componentName) {
      case 'Table':
        return <SelfEmpty />;
      default:
        return <Empty />;
    }
  }

  return (
    <BrowserRouter>
      <ConfigProvider
        locale={getArcoLocale()}
        renderEmpty={renderEmpty}
        componentConfig={{
          Card: {
            bordered: false,
          },
          List: {
            bordered: false,
          },
          Table: {
            border: false,
          },
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* <Monitor> */}
            <GlobalContext.Provider value={contextValue}>
              <Router history={history}>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/preview" component={Preview} />
                  <Route path="/GlPreview/:id" component={GlPreview} />
                  <Route path="/BlogEditor" component={BlogEditorDetail} />
                  <Route
                    path="/EditManager/Edit"
                    component={() => (
                      <Auth>
                        <Detail />
                      </Auth>
                    )}
                  />
                  <Route
                    path="/PageManager/GlossaryManager/Edit"
                    component={() => (
                      <Auth>
                        <GlossaryDetail />
                      </Auth>
                    )}
                  />
                  <Route
                    path="/"
                    component={() => (
                      <Auth>
                        <PageLayout />
                      </Auth>
                    )}
                  />
                </Switch>
              </Router>
            </GlobalContext.Provider>
            {/* </Monitor> */}
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
}
// ReactDOM.render(<Index />, document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);
