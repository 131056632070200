import { Drawer, Message, Spin, Button, Image } from '@arco-design/web-react';
import React, { useEffect, useRef, useState } from 'react';
import styles from './style/index.module.less';
import lazyload from '@/utils/lazyload';
import store from '@/store';
import { saveComponent, selectComponentDetail } from '@/api/globalLink';
import buttondData from './sideData';
const { user } = store.getState();
interface ResType {
  code: string;
  data: any;
  msg: string;
}

interface DetailQuery {
  componentId: number; // 组件id
  type: number; // 组件类型,如:product,software
  block: string; // 组件所属区块，如：Fs01,Fs02,Fs03
  operatePage: number; // 操作页面编码
  productId: number; // 产品id
  blockSort: string; // 区块排序
}
interface IProps {
  parentOnChange: (
    data: Record<string, any>
  ) => // value: Array<Record<string, any>> | Record<string, any>
  void;
  detailParams: DetailQuery; // 详情查询字端
  // orderDecription?: Array<Record<string, string>>; //组件排序字端
  visible: boolean; // 侧边显示
  onCancel: () => void; // 关闭
}

export default function SiderBox(props: IProps) {
  const { parentOnChange, detailParams, visible, onCancel } = props;
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const formRef = useRef(null);
  const backParams = useRef(null);
  const [initValue, setInitValue] = useState<any>({});
  const [DynamicModule, setDynamicModule] = useState<any>('div');
  const [currentItem, setCurrentItem] = useState<any>({
    height: 600,
    name: '',
    moduleName: '',
  });
  const dataRef = useRef(null);
  const filterItem = (item, block, type) => {
    const validate = item?.moduleName || null;
    console.log(block, type);
    if (validate) {
      return item.moduleName === block;
    } else {
      return item.type === type;
    }
  };
  // 监听属性值变化，显示对应组件模版
  useEffect(() => {
    const { componentId = null, type, block, blockSort } = detailParams;
    // 筛选出符合条件的数据
    const item = buttondData.find((item) => filterItem(item, block, type));
    setDynamicModule(lazyload(() => import(`../${item.componentName}`)));
    setCurrentItem(item);

    // 如果是侧边是打开状态，则发起请求
    setInitValue({});

    if ((type === 8 && blockSort && visible) || (visible && componentId)) {
      setLoading(true);
      const params = { componentId: componentId, block, blockSort };
      selectComponentDetail({ ...params })
        .then((res: ResType) => {
          const { code } = res;
          if (code === '200') {
            if (type === 8) {
              setInitValue(res.data.data?.data || {});
            } else {
              setInitValue(res.data.data);
            }
            backParams.current = res?.data || {};
          }
        })
        .catch((err) => {
          Message.error('请求失败');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [detailParams, visible]);
  useEffect(() => {
    if (visible) {
      setLinkInfo({});
    }
  }, [visible]);
  const onChange = (value) => {
    dataRef.current = value;
  };
  // 保存信息
  const DrawerOk = async () => {
    try {
      let validate = true;
      const data = dataRef.current;
      validate = await formRef.current.validate();
      if (!validate) return;
      let current = formRef.current.getFieldsValue();
      // Features 组件含有重复逻辑统一处理
      if (detailParams.type === 8) {
        current = { ...initValue, ...current };
        const { block, blockSort } = detailParams;
        const moduleList = [
          'FS01_1',
          'FS02',
          'FS011',
          'FS13',
          'FS14',
          'FS15',
          'FS19',
          'FS20',
          'FS21_01',
          'FS21_02',
          'FS24_01',
        ];
        // 模态框不可编辑部分删除导致获取数据内容不完整需要处理
        const contentList = [
          'FS03',
          'FS03_1',
          'FS03_2',
          'FS05',
          'FS06',
          'FS07',
          'FS08',
          'FS11',
          'FS12_02',
          'FS16',
          'FS20',
          'FS24',
          'FS24_02',
        ];
        if (block === 'FS01') {
          const description = [{ ...data }.description];
          data.description = description;
          const fs01Data = {
            blockSort,
            block,
            data: {
              ...initValue,
              ...data,
            },
          };
          saveSiderBox(fs01Data);
        } else if (contentList.includes(block)) {
          const params = {
            block,
            blockSort,
            data: {
              ...initValue,
              ...current,
              content: initValue.content.map((item, index) => {
                return { ...item, ...current.content[index] };
              }),
            },
          };
          saveSiderBox(params);
        } else if (block === 'FS22') {
          const params = {
            block,
            blockSort,
            data: {
              ...initValue,
              ...current,
              tags: initValue.tags.map((item, index) => {
                return { ...item, ...current.tags[index] };
              }),
            },
          };
          saveSiderBox(params);
        } else if (block === 'FS09') {
          const params = {
            block,
            blockSort,
            data: {
              ...initValue,
              ...current,
              table: initValue.table.map((item, index) => {
                return { ...item, ...current.table[index] };
              }),
            },
          };
          saveSiderBox(params);
        } else if (block === 'FS24_01') {
          const params = {
            block,
            blockSort,
            data: {
              ...initValue,
              ...current,
              icons: initValue.icons.map((item, index) => {
                return { ...item, ...current.icons[index] };
              }),
            },
          };
          saveSiderBox(params);
        } else if (block === 'FS25') {
          const params = {
            block,
            blockSort,
            data: {
              ...initValue,
              ...current,
              content: initValue.content.map((item, index) => {
                return {
                  ...item,
                  ...current.content[index],
                  icon: item.icon.map((v, k) => {
                    return { ...v, ...current.content[index].icon[k] };
                  }),
                };
              }),
            },
          };
          saveSiderBox(params);
        } else {
          moduleList.includes(block) &&
            saveSiderBox({
              block,
              blockSort,
              data: {
                ...initValue,
                ...current,
              },
            });
        }
      } else if (detailParams.type === 3) {
        saveSiderBox({ ...initValue, ...current });
      } else {
        saveSiderBox({ ...initValue, ...dataRef.current });
      }
    } catch (error) {
      console.log(error);
      if (detailParams.type === 15) {
        Message.error('请将表单数据填写完整, 且文件名称不能为中文');
        return;
      }
      Message.error('请将表单数据填写完整');
    }
  };
  // 保存接口,data=表单数据,isHandle=是否需要处理数据结构
  const saveSiderBox = (data) => {
    setIsDisable(true);
    setLoading(true);
    saveComponent({ ...detailParams, productsId: detailParams.productId, data })
      .then((res: ResType) => {
        const { code } = res;
        if (code === '200') {
          Message.success('保存成功');
          if (backParams.current === null) {
            backParams.current = { ...detailParams };
          }
          parentOnChange(backParams.current); // 父组件回调，刷新页面
        } else {
          Message.error(res?.msg || '保存异常');
        }
      })
      .catch((err) => {
        Message.error('保存失败');
      })
      .finally(() => {
        setLoading(false);
        setIsDisable(false);
      });
  };
  const [linkInfo, setLinkInfo] = useState({});
  return (
    <>
      <Drawer
        width={currentItem?.height}
        wrapClassName={styles.detailRawer}
        title={
          <div>
            <span>{currentItem?.name}</span>
          </div>
        }
        headerStyle={{
          fontWeight: '400',
          fontSize: '20px',
          lineHeight: '28px',
          color: '#252733',
          height: '80px',
        }}
        bodyStyle={{
          padding: '26px 20px 10px 20px',
        }}
        okButtonProps={{
          style: { borderRadius: '30px' },
          disabled: isDisable,
        }}
        cancelButtonProps={{
          style: { borderRadius: '30px' },
        }}
        visible={visible}
        okText="保存"
        onOk={DrawerOk}
        onCancel={onCancel}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.28)',
        }}
        maskClosable={false}
        autoFocus={false}
        focusLock={false}
      >
        <Spin tip="loading....." loading={loading} style={{ display: 'block' }}>
          <DynamicModule
            initValue={initValue}
            onChange={onChange}
            formRef={formRef}
            moduleName={currentItem?.moduleName}
            linkInfo={linkInfo}
          />
        </Spin>
      </Drawer>
    </>
  );
}
