import request from '@/utils/request';
export interface ResType{
    code?: string;
    data?: any;
    msg?: string;
}

// 翻译查询列表
export function selectPageList(data) {
    return request({
      url: '/api/globalLink/selectPageList',
      method: 'post',
      data
    });
  }

// 组件保存接口
export function saveComponent(data) {
    return request({
      url: '/api/globalLink/saveComponent',
      method: 'post',
      data
    });
  }

// 预览页面接口
export function selectDetail(data) {
    return request({
      url: '/api/globalLink/selectDetail',
      method: 'post',
      data
    },true);
  }

// 组件编辑回显接口
export function selectComponentDetail(data) {
    return request({
      url: '/api/globalLink/selectComponentDetail',
      method: 'post',
      data
    });
  }

// 组件删除
export function deleteComponent(data) {
    return request({
      url: '/api/globalLink/deleteComponent',
      method: 'post',
      data
    });
  }

// 提交目标组件提交
export function submit(data) {
    return request({
      url: '/api/globalLink/submit',
      method: 'post',
      data
    },true);
  }

// 预览页面
export function selectSourceDetail(data) {
    return request({
      url: '/api/globalLink/selectSourceDetail',
      method: 'post',
      data
    },true);
}
