// feed管理
import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// GlobalLink
const routes: Route[] = [
  {
    name: "GlobalLink详情",
    router: "GlobalLink/Detail/:id",
    component: lazyload(() => import('@/pages/GlobalLink/Detail/GlComponent')),
  },
  {
    name: 'GlobalLink',
    router: 'GlobalLink',
    component: lazyload(() => import('@/pages/GlobalLink/index')),
  }
]
export default routes;
