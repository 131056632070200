import { createSlice } from "@reduxjs/toolkit"

export interface GlobalState {
    isFold: boolean
}

const initialState:GlobalState = {
    isFold:false
}

export const slice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
      changeFold: (state, action) => {
        state.isFold =  action.payload
      }
    }
})

export const { changeFold } = slice.actions;
export default slice.reducer;