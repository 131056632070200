import { RootState } from '@/store';
import lazyload from '@/utils/lazyload';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Nofond from '@/pages/exception/403';
// 类似路由守卫，可以监听路由状态变化
export default function (props) {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  // 判断是否有token
  const { token = null } = userInfo || {};
  if (token !== null) {
  return <>{props.children}</>;
  } else {
    return <Nofond />
  }
}
