import React from 'react';
import { Button, Cascader, Message, Modal } from '@arco-design/web-react';
import { getColor, tagColorList } from '../../constans';
import { IconClose } from '@arco-design/web-react/icon';
import { TagItem } from '../../types';
import useGetData from '../EditorForm/useGetData';
type AddTagProps = {
  tagVisible: boolean;
  setTagVisible: (visible: boolean) => void;
  onAddTag?: (tag: TagItem[]) => void;
};

const AddTag: React.FC<AddTagProps> = ({
  tagVisible,
  setTagVisible,
  onAddTag,
}) => {
  const [tagList, setTagList] = React.useState<TagItem[]>([]);
  const { tagList: tagListData } = useGetData();
  const handleChange = (value: string[], selectedOptions) => {
    console.log(selectedOptions);
    // 如果tagList中有该标签，则不添加
    if (tagList.some((item) => item.value === value[value.length - 1])) return;
    if (!selectedOptions || selectedOptions.length === 0) return;
    const newTag = selectedOptions[selectedOptions.length - 1];
    setTagList((pre) => {
      return [
        ...pre,
        {
          value: newTag.value,
          label: newTag.label,
        },
      ];
    });
  };
  const onOk = () => {
    if (tagList.length === 0) {
      Message.warning('请选择标签');
      return;
    }
    onAddTag && onAddTag(tagList);
    setTagVisible(false);
  };
  const handleDeleteTag = (index: number) => {
    setTagList((prev) => {
      return prev.filter((item, i) => i !== index);
    });
  };
  const tagRender = (item, index: number) => {
    const colorInfo = getColor(index, tagColorList);
    return (
      <div
        style={{
          color: colorInfo.textColor,
          backgroundColor: colorInfo.bgColor,
          padding: '2px 4px',
          marginRight: 7,
        }}
      >
        <span>{item.label}</span>
        <span
          style={{ marginLeft: 8, cursor: 'pointer' }}
          onClick={() => handleDeleteTag(index)}
        >
          <IconClose style={{ fontSize: 12 }} />
        </span>
      </div>
    );
  };
  return (
    <>
      <Modal
        autoFocus={false}
        focusLock={false}
        maskClosable={false}
        title={<div style={{ textAlign: 'left' }}>添加标签</div>}
        visible={tagVisible}
        onCancel={() => {
          setTagVisible(false);
        }}
        footer={
          <>
            <Button
              type="secondary"
              shape="round"
              onClick={() => {
                setTagVisible(false);
              }}
            >
              取消
            </Button>
            <Button shape="round" type="primary" onClick={onOk}>
              确定
            </Button>
          </>
        }
      >
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 20 }}>
            {tagList.map((item, index) => {
              return <div key={index}>{tagRender(item, index)}</div>;
            })}
          </div>
          <Cascader
            placeholder="标签名称/ID"
            options={tagListData}
            onChange={handleChange}
            allowClear
            showSearch
            fieldNames={{
              children: 'children',
              label: 'title',
              value: 'id',
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddTag;
