/*
 * @Author: ajay
 * @Date: 2022-08-24 14:56:34
 * @LastEditors: ajay
 * @LastEditTime: 2022-09-08 17:22:14
 * @Description: 请填写简介
 */
import { FilterParams } from '@/enum/FilterList';
import request from '@/utils/request';

// 产品编辑列表页--分类查询
export function selectTreeNodeList(data) {
  return request(
    {
      url: '/api/categories/selectTreeNodeList',
      method: 'post',
      data,
    }
    // true
  );
}

// 产品编辑列表页--产品列表查询/筛选
export interface showProductsData extends FilterParams {
  isCeCertification: number | string; // 是否是CE认证产品
  isImportant: number | string; // 产品类型
  parentId: number | string; // 筛选分类ID
  productsStatus: number | string; // 产品状态
  search: string; // 搜索关键字
  current: number; // 当前页
  size: number; // 每页条数
  submitStatus: string; // 详情页编辑状态
  translateStatus: number | string; // 翻译状态
}

export function showProducts(data: showProductsData) {
  return request(
    {
      url: '/api/products/selectPageList',
      method: 'post',
      data,
    },
    true
  );
}

// 产品编辑列表页--下载组件数据
export function downloadData(data) {
  return request(
    {
      url: '/api/platformTemplate/selectComponentTypeList',
      method: 'post',
      data,
    },
    true
  );
}

// 产品编辑列表页--下载对应ID的对应选中的组件文件
export interface downloadComponentsData {
  product_ids: Array<number>;
  component_ids: Array<number>;
  parentId: number | string;
  isCeCertification: number | string; // 是否是CE认证产品
  isImportant: number | string; // 产品类型
  productsStatus: number | string; // 产品状态
  submitStatus: string; // 详情页编辑状态
  search: string;
  cateName?: string;
}

export function downloadComponents(data: downloadComponentsData) {
  return request(
    {
      url: '/api/products/excelDownload',
      method: 'post',
      data,
      responseType: 'blob',
    },
    true
  );
}
// 产品详情空模版下载
export function downloadEmptyTemplateExcel() {
  return request(
    {
      url: '/api/products/downloadEmptyTemplateExcel',
      method: 'get',
      responseType: 'blob',
    },
    true
  );
}

// 新品详情下载
export function exportNewRelatedOldProduc(data) {
  return request(
    {
      url: '/api/products/exportNewRelatedOldProduct',
      method: 'post',
      data,
      responseType: 'blob',
    },
    true
  );
}

// 清除缓存
export function clearStashComponent(data) {
  return request(
    {
      url: '/api/products/clearStashComponent',
      method: 'post',
      data,
    },
    true
  );
}

//上传校验同型号的产品
export function uploadCheckSameProduct(data) {
  return request(
    {
      url: `/api/products/uploadCheck`,
      method: 'post',
      data,
    },
    true
  );
}

// 上传产品文档
export function uploadProductDocModel(data) {
  return request(
    {
      url: `/api/products/uploadProductInfo`,
      method: 'post',
      data,
    },
    true
  );
}

// 上传产品文档
export function uploadProductDocDetail(data) {
  return request(
    {
      url: `/api/products/excelUpload`,
      method: 'post',
      data,
    },
    true
  );
}
