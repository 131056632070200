import request from '@/utils/request';

// 产品管理列表
export function selectProductsManageList(data) {
  return request(
    {
      url: '/api/productsManage/selectProductsManageList',
      method: 'post',
      data,
    },
    true
  );
}

// 产品管理列表v2
export function selectProductsManageListV2(data) {
  return request(
    {
      url: '/api/product/manager/page',
      method: 'post',
      data,
    },
    true
  );
}

//产品管理数据看板统计
export function productsManageStatistics(data) {
  return request(
    {
      url: '/api/product/manager/statistics',
      method: 'post',
      data,
    },
    true
  );
}
// 产品管理数据看板下载
export function productManageDownload(data) {
  return request(
    {
      responseType: 'blob',
      url: '/api/product/manager/download',
      method: 'post',
      data,
    },
    true
  );
}

//当前登陆人小组信息
export function currentTeamInfo() {
  return request({
    url: '/api/product/manager/current/team',
    method: 'get',
  });
}

// 产品管理列表
export function selectProductsInfoList(data) {
  return request(
    {
      url: '/api/productsManage/selectProductsInfoList',
      method: 'post',
      data,
    },
    true
  );
}
// 产品管理列表v2
export function selectProductsInfoListV2(data) {
  return request(
    {
      url: '/api/product/manager/productPageList',
      method: 'post',
      data,
    },
    true
  );
}

// 批量创建事项
export function createProductsModelItem(data) {
  return request({
    url: '/api/productsManage/createProductsModelItem',
    method: 'post',
    data,
  });
}

// 编辑事项
export function saveProductsModelItem(data) {
  return request({
    url: '/api/productsManage/saveProductsModelItem',
    method: 'post',
    data,
  });
}

// 删除事项
export function deleteModelItem(id) {
  return request({
    url: `/api/productsManage/deleteModelItem/${id}`,
    method: 'get',
  });
}

// 事项列表
export function modelItemList(data) {
  return request(
    {
      url: '/api/productsManage/modelItemList',
      method: 'post',
      data,
    },
    true
  );
}

// 运营人员列表查询
export function selectAdminList(data) {
  return request({
    url: '/api/productsManage/selectAdminList',
    method: 'post',
    data,
  });
}

// 事件类型
export function selectItemType() {
  return request({
    url: '/api/productsManage/selectItemType',
    method: 'get',
  });
}

// 小组列表查询
export function selectGroupList(data) {
  return request({
    url: '/api/productsManage/selectGroupList',
    method: 'post',
    data,
  });
}

// 事件类型
export function selectCategoryToModelCount(data) {
  return request({
    url: '/api/productsManage/selectCategoryToModelCount',
    method: 'post',
    data,
  });
}

// 事件类型
export function selectModelName(category_id) {
  return request({
    url: `/api/productsManage/selectModelName/${category_id}`,
    method: 'get',
  });
}

// 翻译任务看板列表查询
export function selectPageList(data) {
  return request(
    {
      url: '/api/translateTask/selectPageList',
      method: 'post',
      data,
    },
    true
  );
}

// 页面类型列表查询
export function selectPageTypeList() {
  return request({
    url: '/api/translateTask/selectPageTypeList',
    method: 'post',
  });
}

// 修改翻译状态
export function updateTranslateStatus(data) {
  return request({
    url: '/api/translateTask/updateTranslateStatus',
    method: 'post',
    data,
  });
}

// 批量修改翻译状态
export function batchUpdateTranslateStatus(data) {
  return request({
    url: '/api/translateTask/updateTranslateStatusBatch',
    method: 'post',
    data,
  });
}
