import React, { useEffect, useState } from 'react';
import EditorHeader from '../EditorHeader';
import styles from './index.module.less';
import { IconPlayArrow } from '@arco-design/web-react/icon';
import EditorForm from '../EditorForm';
import FsEditor from '@/components/FsEditor';
import { createFeiShuDocument } from '@/api/resource/documentManager/documentManager';
import { Message } from '@arco-design/web-react';
import qs from 'query-string';
import { useHistory } from 'react-router';
import {
  addBlogArticle,
  editBlogArticle,
  getBlogArticleDetail,
} from '@/api/pageManager/Blog/BlogEditor';

function Editor() {
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [editorLoading, setEditorLoading] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [src, setSrc] = useState('');
  const [detailInfo, setDetailInfo] = useState<any>({});
  const search = qs.parseUrl(window.location.href);
  const { id } = search.query;
  const getDocument = () => {
    createFeiShuDocument().then((res: ResObject) => {
      console.log(res, 'res');

      if (res.code === '200') {
        setDocumentId(res.data);
        setSrc('https://mah2eds8ab.feishu.cn/docx/' + res.data);
      } else {
        Message.error(res.msg);
      }
    });
  };
  // 获取文章详情
  const getDetail = async (id) => {
    try {
      const res: any = await getBlogArticleDetail({ id });
      if (res?.code === '200') {
        const { documentId, ...rest } = res?.data ?? {};
        setDetailInfo(rest);
        setSrc('https://mah2eds8ab.feishu.cn/docx/' + documentId);
        setDocumentId(documentId);
      }
    } catch (error) {
      Message.error(error);
    }
  };
  //retractTheGas
  const retractTheGas = () => {
    setIsCollapsed(!isCollapsed);
  };
  // 新增
  const addArticle = async (params) => {
    setEditorLoading(true);
    try {
      const res: any = await addBlogArticle(params);
      if (res?.code === '200') {
        Message.success('添加成功');
        // 返回列表页
        history.push('/PageManager/BlogManager/BlogEditor');
      }
    } catch (error) {
      Message.error(error);
    } finally {
      setEditorLoading(false);
    }
  };
  // 编辑提交
  const editArticle = async (params) => {
    setEditorLoading(true);
    try {
      const res: any = await editBlogArticle(params);
      if (res?.code === '200') {
        Message.success('编辑成功');
        // 返回列表页
        history.push('/PageManager/BlogManager/BlogEditor');
      } else {
        Message.error(res?.msg);
      }
    } catch (error) {
      Message.error(error);
    } finally {
      setEditorLoading(false);
    }
  };
  const submitChange = (value) => {
    console.log(value);
    if (id) {
      // 编辑
      editArticle({
        ...value,
        id,
        documentId: src.replace('https://mah2eds8ab.feishu.cn/docx/', ''),
      });
    } else {
      //新增
      addArticle({
        ...value,
        documentId: src.replace('https://mah2eds8ab.feishu.cn/docx/', ''),
      });
    }
  };
  useEffect(() => {
    if (id) {
      //编辑
      getDetail(id);
    } else {
      //新增
      getDocument();
    }
  }, []);
  return (
    <div className={styles['blog-editor-container']}>
      <EditorHeader />
      <div className={styles['editor-content']}>
        <div className={styles['editor-left']}>
          <FsEditor src={src}></FsEditor>
        </div>
        <div
          className={[
            styles['editor-line'],
            isCollapsed ? '' : styles['collapse-status'],
          ].join(' ')}
        >
          <div
            className={[styles['editor-line-btn']].join(' ')}
            style={{
              left: isCollapsed ? -6 : -1,
            }}
            onClick={retractTheGas}
          >
            <IconPlayArrow
              style={{
                transform: isCollapsed
                  ? 'translateY(-50%) rotate(180deg) '
                  : 'translateY(-50%) rotate(0deg)',
              }}
            />
          </div>
        </div>
        <div
          className={styles['editor-right']}
          style={{
            width: isCollapsed ? 0 : 480,
          }}
        >
          <EditorForm
            onChange={submitChange}
            detailInfo={detailInfo}
            documentId={documentId}
            editorLoading={editorLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default Editor;
