import React, { useEffect, useState } from 'react';
import styles from './style/index.module.less';
import checkLogin from '@/utils/checkCode';
import qs from 'query-string';

function Login() {
  const [openStatus, setOpenStatus] = useState(false);
  useEffect(() => {
    document.body.setAttribute('arco-theme', 'light');
  }, []);
  const isLogin = checkLogin();
  const params = qs.parseUrl(window.location.href);
  if (isLogin) {
    const redirectUrl = `${window.location.href.split('login')[0]}preview`;
    const { code } = isLogin;
    const { route, language_id } = params.query;
    // 飞书内部执行函数，
    window.addEventListener('pageshow', () => {
      setOpenStatus(true);
      if (route) {
        window.location.href = `${redirectUrl}?route=${route}&code=${code}&language_id=${language_id}`;
      } else {
        window.location.href = `${redirectUrl}?code=${code}`;
      }
    });
    // 浏览器窗口打开
    // window.open(redirectUrl+'?code='+code);
    return (
      <div className={styles.container}>
        {openStatus ? '已在浏览器窗口打开' : '正在打开浏览器窗口'}
      </div>
    );
  } else {
    console.log(process.env);
    const APPID = process.env.REACT_APP_APP_ID;
    const url = window.location.href;
    window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${url}&app_id=${APPID}&state=${2}`;
  }
}
Login.displayName = 'LoginPage';

export default Login;
