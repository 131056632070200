import React, { useEffect } from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Popover,
  Select,
  Message,
} from '@arco-design/web-react';
import dayjs from 'dayjs';
import styles from './index.module.less';
import { IconPlus } from '@arco-design/web-react/icon';
import CustomUpload from './CustomUpload';
import CustomAddText from './CustomAddText';
import CustomTag from './CustomTag';
import CategoryFilter from '@/components/CatagoryFilter';
import CustomArticle from './CustomArticle';
import { getAuthorList } from '@/api/pageManager/Blog/BlogEditor';
import CustomImage from './CustomImage';
import { getPath } from '@/utils/utils';
import ConfirmModal from '../../../component/ConfirmModal';
type PropsType = {
  onChange: (data: any) => void;
  detailInfo: Record<string, any>;
  documentId: string;
  editorLoading: boolean;
};
const Item = Form.Item;
const TextArea = Input.TextArea;
const EditorForm: React.FC<PropsType> = ({
  onChange,
  detailInfo,
  documentId,
  editorLoading,
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [initData, setInitData] = React.useState<any>({
    status: 1,
  });
  const [authorList, setAuthorList] = React.useState<any>([]);
  const [categoryData, setCategoryData] = React.useState<any>([]);
  const getCategoryData = (data) => {
    setCategoryData(data);
  };
  useEffect(() => {
    if (detailInfo?.id && categoryData.length > 0) {
      //编辑
      console.log(detailInfo);
      setInitData(detailInfo);
      const blogCategoryIds = (detailInfo?.blogCategory || []).map((item) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      const tagIds = (detailInfo?.tags || []).map((item) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      const productsCategoryId = (detailInfo?.productsCategory || [])[0].id;
      const newProductsCategoryId = getPath(categoryData, productsCategoryId);
      console.log(newProductsCategoryId);

      const recommendArticleIds = (detailInfo?.recommendArticle || []).map(
        (item) => {
          return {
            itemValue: item.id,
            // label: item.title,
          };
        }
      );
      setAuthorList(detailInfo?.authorList || []);
      form.setFieldsValue({
        ...detailInfo,
        blogCategoryIds,
        tagIds,
        productsCategoryId: newProductsCategoryId,
        recommendArticleIds,
      });
    }
  }, [detailInfo, categoryData]);
  // 获取作者
  const getAuthor = async (idList) => {
    try {
      const res: any = await getAuthorList({ blogCategoryId: idList });
      console.log(res);
      if (res?.code === '200') {
        setAuthorList(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleArticleChange = (value) => {
    console.log(value);
    const idList = value.map((item) => item.value);
    getAuthor(idList);
  };
  const deleteOperation = () => {
    window.open(
      `${process.env.REACT_APP_FS_URL}/blog_preview.html?id=${documentId}`,
      '__blank'
    );
    setVisible(false);
  };
  const submit = async () => {
    const bottomAdvImg = form.getFieldValue('bottomAdvImg');
    if (bottomAdvImg) {
      const bottomAdvUrl = form.getFieldValue('bottomAdvUrl');
      if (!bottomAdvUrl) {
        Message.error('请输入底部广告链接');
        return;
      }
    }
    try {
      await form.validate();
      // 处理数据
      const blogCategoryIds = (form.getFieldValue('blogCategoryIds') || [])
        .map((item) => item.value)
        .join(',');
      const tagIds = (form.getFieldValue('tagIds') || [])
        .map((item) => item.value)
        .join(',');
      const recommendArticleIds = (
        form.getFieldValue('recommendArticleIds') || []
      )
        .map((item) => item.itemValue)
        .join(',');
      const productsCategoryId = form.getFieldValue('productsCategoryId');

      const data = {
        ...form.getFieldsValue(),
        blogCategoryIds,
        tagIds,
        recommendArticleIds,
        productsCategoryId: productsCategoryId[productsCategoryId.length - 1],
      };
      onChange && onChange(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // 获取分类数据
    console.log('editorLoading', editorLoading);
  }, [editorLoading]);

  const tipContent = () => {
    return (
      <div
        style={{
          // padding: '16px 20px',
          fontSize: 13,
          color: '#4E5969',
        }}
      >
        用于新链接与原文章链接进行关联绑定，301重定向
      </div>
    );
  };
  const oldLabel = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>原文章URL</span>
        <Popover position="tl" content={tipContent()}>
          <img
            className={styles['base-info-first-operation']}
            src="https://resource.fs.com/mall/generalImg/202407231030581b3zij.svg"
            style={{
              margin: '0 4px',
            }}
          />
        </Popover>
        <span>：</span>
      </div>
    );
  };
  return (
    <div className={styles['editor-form']}>
      <div className={styles['editor-form-top']}>
        <Form
          form={form}
          initialValues={initData}
          layout="vertical"
          size="large"
        >
          <Item
            label="Meta标题："
            rules={[{ required: true, message: '请输入Meta标题' }]}
            field="metaTitle"
          >
            <Input placeholder="请输入Meta标题" />
          </Item>
          <Item
            label="Meta描述："
            rules={[{ required: true, message: '请输入Meta描述' }]}
            field="metaDescription"
          >
            <TextArea placeholder="请输入Meta描述" style={{ minHeight: 104 }} />
          </Item>
          {/* meta图片上传 */}
          <Item
            label="Meta图片："
            field="metaImg"
            // rules={[{ required: true, message: '请上传Meta图片' }]}
          >
            <CustomUpload>
              <div className={styles['upload-btn']}>
                <span>
                  <IconPlus />
                </span>
                <div className={styles['desc']} style={{ marginTop: 8 }}>
                  上传Meta图片
                </div>
              </div>
            </CustomUpload>
            {/* <CustomImage /> */}
          </Item>
          <Item
            label="更换文章URL（填写格式为文章标题）："
            // rules={[{ required: true, message: '请输入文章标题' }]}
            field="updateUrl"
          >
            <Input placeholder="请输入文章标题" />
          </Item>
          {detailInfo?.id ? (
            <Item
              label="文章URL："
              rules={[{ required: true, message: '请输入文章URL' }]}
              field="url"
              disabled
            >
              <Input placeholder="请输入文章URL" />
            </Item>
          ) : null}
          <Item label={oldLabel()} field="oldUrl">
            <Input placeholder="请输入原文章URL，用于新链接与原文章链接进行关联绑定" />
          </Item>
          {/* 封面图 */}
          <Item
            label={
              <p>
                封面图
                <span style={{ fontSize: 13, color: '#9FA2B4' }}>
                  （上传尺寸需考虑社交媒体，统一上传用大图尺寸974*548px
                  (不用388*218px的2倍图，而是用2.5倍图)）
                </span>
              </p>
            }
            field="coverImg"
            rules={[{ required: true, message: '请上传封面图' }]}
          >
            <CustomImage
              fileType={['jpeg', 'png', 'PNG', 'jpg', 'Webp', 'webp']}
            />
          </Item>

          <Item
            label="文章概述："
            rules={[{ required: true, message: '请输入文章概述' }]}
            field="summaryDescription"
          >
            <TextArea placeholder="请输入文章概述" style={{ minHeight: 104 }} />
          </Item>
          <Divider style={{ margin: '4px 0 20px 0' }} />
          {/* 详情页广告图 */}
          <Item
            label={
              <p>
                详情页广告图
                <span style={{ fontSize: 13, color: '#9FA2B4' }}>
                  （支持格式png、jpeg，尺寸880*330px）
                </span>
              </p>
            }
            // rules={[{ required: true, message: '请上传详情页广告图' }]}
            field="bannerImg"
          >
            <CustomImage fileType={['jpeg', 'png', 'PNG', 'jpg']} />
          </Item>

          <Item label="文章副标题：" field="subtitle">
            <TextArea
              placeholder="请输入文章副标题"
              style={{ minHeight: 104 }}
            />
          </Item>
          {/* 文章分类，级联选择 */}
          <Item
            label="文章分类："
            rules={[{ required: true, message: '请选择文章分类' }]}
            field="blogCategoryIds"
          >
            <CustomArticle onChange={handleArticleChange} />
          </Item>
          {/* 标签 */}
          <Item
            label="标签："
            field="tagIds"
            rules={[{ required: true, message: '请选择标签' }]}
          >
            <CustomTag />
          </Item>
          <Item
            label="作者（虚拟作者）："
            rules={[{ required: true, message: '请选择作者' }]}
            field="author"
          >
            {/* <Input placeholder="请输入标签" /> */}
            <Select placeholder="请选择作者" options={authorList} allowClear />
          </Item>
          {/* 文章推荐 */}
          <Item
            label="文章推荐："
            field="recommendArticleIds"
            // rules={[{ required: true, message: '请选择文章推荐' }]}
          >
            <CustomAddText />
          </Item>
          {/* 产品推荐 */}
          <Item
            label={
              <p>
                产品推荐：
                <span style={{ fontSize: 13, color: '#9FA2B4' }}>
                  （多个请用”，“隔开，建议3个及以上）
                </span>
              </p>
            }
            field="recommendProducts"
            rules={[{ match: /^\d+(,\d+)*$/, message: '请检查' }]}
          >
            <Input placeholder="请输入产品ID" />
          </Item>
          {/* 底部广告推荐 */}
          <Item
            label={
              <p>
                底部广告推荐：
                <span style={{ fontSize: 13, color: '#9FA2B4' }}>
                  （尺寸880*121px）
                </span>
              </p>
            }
            // rules={[{ required: true, message: '请上传详情页广告图' }]}
            field="bottomAdvImg"
          >
            <CustomImage
              fileType={['jpeg', 'png', 'PNG', 'jpg', 'Webp', 'webp']}
            />
          </Item>
          <Item field="bottomAdvUrl">
            <Input placeholder="请输入广告图跳转链接" />
          </Item>

          <Divider style={{ margin: '4px 0 20px 0' }} />
          {/* 所属分类 */}
          <Item
            label="产品所属分类："
            rules={[{ required: true, message: '请选择产品所属分类' }]}
            field="productsCategoryId"
          >
            <CategoryFilter
              // onChange={handleChange}
              placeholder="请选择产品所属分类"
              getData={getCategoryData}
              expandTrigger={'click'}
              allowClear
              pushData={{
                value: -1,
                label: 'other',
              }}
            />
          </Item>
          {/* 备注 */}
          <Item label="备注：" field="remark">
            <Input placeholder="请输入备注" allowClear />
          </Item>
          <Divider style={{ margin: '4px 0 20px 0' }} />
          {/* 自定义文章日期 */}
          <Item label="自定义文章日期：" field="publishDate">
            <DatePicker
              style={{
                width: '100%',
              }}
              placeholder="请选择日期"
              allowClear
              disabledDate={(current) =>
                current.isAfter(dayjs().add(-1, 'day'))
              }
              onClear={() => {
                form.setFieldValue('publishDate', '');
              }}
            />
          </Item>
          {/* 发布状态 */}
          <Item
            label="发布状态："
            rules={[{ required: true, message: '请勾选发布状态' }]}
            field="status"
          >
            <Select
              placeholder="请选择发布状态"
              allowClear
              options={[
                {
                  value: 1,
                  label: '待发布',
                },
                {
                  value: 2,
                  label: '立即发布',
                },
                {
                  value: 3,
                  label: '定时发布',
                },
              ]}
            />
          </Item>
          <Item shouldUpdate noStyle>
            {(values) => {
              console.log('values', values.status);
              return values.status == 3 ? (
                <Item
                  rules={[{ required: true, message: '请选择发布时间' }]}
                  field="definiteDate"
                >
                  <DatePicker
                    showTime
                    style={{
                      width: '100%',
                    }}
                    placeholder="请选择发布日期"
                    disabledDate={(current) =>
                      current.isBefore(dayjs().startOf('day'))
                    }
                    shortcuts={[
                      {
                        text: '一分钟后',
                        value: () => dayjs().add(1, 'minute'),
                      },
                    ]}
                    // disabledTime={getDisabledTime}
                    allowClear
                    onClear={() => {
                      form.setFieldValue('definiteDate', '');
                    }}
                  />
                </Item>
              ) : null;
            }}
          </Item>
        </Form>
      </div>
      <Divider style={{ margin: '0 0 0 0' }} />
      <div className={styles['editor-form-footer']}>
        <Button
          shape="round"
          type="secondary"
          size="large"
          onClick={() => {
            setVisible(true);
          }}
        >
          预览
        </Button>
        <Button
          shape="round"
          type="primary"
          size="large"
          loading={editorLoading}
          onClick={submit}
        >
          保存
        </Button>
      </div>
      <ConfirmModal
        visible={visible}
        setVisible={setVisible}
        onOk={deleteOperation}
        title="提示框"
        content="预览链接为测试站，24小时有效，过后可再次点击‘预览’更新。"
        // style={{
        //   width: 340,
        // }}
      />
    </div>
  );
};

export default EditorForm;
