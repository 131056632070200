import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Switch, Route, Link, Redirect, useHistory } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Spin } from '@arco-design/web-react';
import { IconMessage } from '@arco-design/web-react/icon';
import { changeFold } from '@/store/menu/menuSlice';
import store from '@/store';
import cs from 'classnames';
import navData from '@/enum/NavData';
import {
  IconDashboard,
  IconTag,
  IconMenuFold,
  IconMenuUnfold,
} from '@arco-design/web-react/icon';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import NProgress from 'nprogress';
import Navbar from './components/NavBar';
import useRoute, { translateMenu, getRouteName, getbram } from '@/routes';
import { isArray } from './utils/is';
import getUrlParams from './utils/getUrlParams';
import lazyload from './utils/lazyload';
import { RootState } from './store';
import { setRouterTag } from './store/user/userSlice';
import styles from './style/layout.module.less';
import IconSvg from './components/IconSvg';

const MenuItem = Menu.Item;
const { SubMenu } = Menu;

const { Sider } = Layout;
const { Content } = Layout;

function getIconFromKey(key) {
  switch (key) {
    case 'home':
      return <IconSvg type="icon-fs_2022041407icon" />;
    case 'PageManager':
      return <IconSvg type="icon-fs_2022041408icon" />;
    // case 'OperationLog':
    //   return <IconSvg type="icon-fs_2022041404icon" />;
    case 'Resource':
      return <IconSvg type="icon-fs_2022041409icon" />;
    case 'AuthManager':
      return <IconSvg type="icon-fs_2022041405icon" />;
    case 'FlyOffice':
      return <IconSvg type="icon-a-ziyuan2" />;
    case 'GoodsManager':
      return <IconSvg type="icon-shangpin" />;
    case 'Feed':
      return <IconSvg type="icon-feed" />;
    case 'GlobalLink':
      return <IconSvg type="icon-Global" />;
    case 'filterItem':
      return <IconSvg type="icon-cebiandaohang" />;
    // case 'Message':
    //   return <IconSvg type="icon-icon-cebiandaohanglan" />;
    case 'SensitiveManage':
      return <IconSvg type="icon-words" />;
    case 'QRManager':
      return <IconSvg type="icon-a-cebianlandaohangQRma" />;
    case 'ShareLinkManage':
      return <IconSvg type="icon-a-fenxiang1x" />;
    case 'Update':
      return <IconSvg type="icon-a-gengxin1x" />;
    // case 'ClearCache':
    //   return <IconSvg type="icon-a-gengxin1x" />;
    default:
      return <div className={styles['icon-empty']} />;
  }
}

// 获取用户权限内的路由，生成路由表
const getUserPermission = (routes) => {
  const res = [];

  function travel(_routes) {
    _routes.forEach((route) => {
      if (route.key && !route.children) {
        if (!route.component) {
          route = {
            ...route,
            component: lazyload(() => import(`./pages/${route.key}`)),
          };
        }
        res.push(route);
      } else if (isArray(route.children) && route.children.length > 0) {
        travel(route.children);
      }
    });
  }

  if (routes.length > 0) {
    travel(routes);
  }
  return res;
};
// 扁平化菜单
const getFlatMenu = (routes) => {
  const res = [];

  function travel(_routes) {
    _routes.forEach((route) => {
      if (route.key && !route.children) {
        res.push(route);
      } else if (isArray(route.children) && route.children.length > 0) {
        travel(route.children);
      }
    });
  }

  if (routes.length > 0) {
    travel(routes);
  }
  return res;
};

function PageLayout() {
  const urlParams = getUrlParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const requestLoading = useSelector(
    (state: RootState) => state.user.requestLoading
  );
  const loading = useMemo(() => {
    return requestLoading;
  }, [requestLoading]);
  const routerTag = useSelector((state: RootState) => state.user.routerTag);
  const { pathname } = history.location;
  const currentComponent = qs.parseUrl(pathname).url.slice(1);
  const settings = useSelector((state: RootState) => {
    return state.user.settings;
  });
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [routes, defaultRoute] = useRoute(userInfo.permission);
  const defaultSelectedKeys = [currentComponent || defaultRoute];
  const paths = (currentComponent || defaultRoute).split('/');
  const defaultOpenKeys = paths.slice(0, paths.length - 1);

  const [breadcrumb, setBreadCrumb] = useState([]);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] =
    useState<string[]>(defaultSelectedKeys);

  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);

  const routeMap = useRef<Map<string, React.ReactNode[]>>(new Map());

  const navbarHeight = 80;
  const menuWidth = collapsed ? 54 : settings.menuWidth;

  const showNavbar = settings.navbar && urlParams.navbar !== false;
  const showMenu = settings.menu && urlParams.menu !== false;
  const getMenu = useMemo(() => {
    const menu = userInfo.permission || [];
    const data = translateMenu(menu);
    return data;
  }, [userInfo.permission, openKeys]);
  const flattenSelfRoutes = useMemo(() => {
    return getUserPermission(routes) || [];
  }, [routes]);

  function renderRoutes() {
    const nodes = [];
    routeMap.current.clear();

    function travel(_routes, level, parentNode = []) {
      return _routes.map((route) => {
        const { breadcrumb = true } = route;

        const iconDom = getIconFromKey(route.key);
        const titleDom = (
          <>
            {iconDom}
            <span className={styles['span-hide']}>{route.name}</span>
          </>
        );
        if (
          route.component &&
          (!isArray(route.children) ||
            (isArray(route.children) && !route.children.length))
        ) {
          routeMap.current.set(
            `/${route.key}`,
            breadcrumb ? [...parentNode, route.name] : []
          );
          if (level > 1) {
            return (
              <MenuItem
                style={{
                  paddingLeft: level > 2 ? level * 10 + 'px' : level * 5 + 'px',
                }}
                key={route.key}
              >
                {titleDom}
              </MenuItem>
            );
          }
          nodes.push(
            <MenuItem key={route.key}>
              <Link to={`/${route.key}`}>{titleDom}</Link>
            </MenuItem>
          );
        }
        if (isArray(route.children) && route.children.length) {
          const parentNode = [];
          if (iconDom.props.isIcon) {
            parentNode.push(iconDom);
          }

          if (level > 1) {
            return (
              <SubMenu key={route.key} title={titleDom}>
                {travel(route.children, level + 1, [...parentNode, route.name])}
              </SubMenu>
            );
          }
          nodes.push(
            <SubMenu key={route.key} title={titleDom}>
              {travel(route.children, level + 1, [...parentNode, route.name])}
            </SubMenu>
          );
        }
      });
    }

    travel(getMenu, 1);
    return nodes;
  }

  function onClickMenuItem(key) {
    console.log(key, 'key');

    const currentRoute = flattenSelfRoutes.find((r) => r.key === key);
    const flatMenu = getFlatMenu(getMenu);
    const currenTag = flatMenu.find((r) => r.key === key);
    const paddentData = routerTag.find((r) => r.key === key);
    // 排除已存在和首页的路由
    if (!paddentData && key !== 'home') {
      dispatch(setRouterTag({ ...currenTag }));
    }
    const { component } = currentRoute;
    const preload = component.preload();
    NProgress.start();
    preload.then(() => {
      setSelectedKeys([key]);
      history.push(currentRoute.path ? currentRoute.path : `/${key}`);
      NProgress.done();
    });
  }

  function toggleCollapse() {
    store.dispatch(changeFold(!collapsed));
    setCollapsed((collapsed) => !collapsed);
  }

  const paddingLeft = showMenu ? { paddingLeft: menuWidth } : {};
  const paddingTop = showNavbar ? { paddingTop: navbarHeight } : {};
  const paddingStyle = { ...paddingLeft, ...paddingTop };

  useEffect(() => {
    const menu = userInfo.permission || [];
    const routeConfig = routeMap.current.get(pathname);
    const data = getRouteName(pathname, menu);
    setSelectedKeys([pathname.substring(1, pathname.length)]);
    if (!routeConfig && data) {
      setBreadCrumb(() => {
        const crumbData = getbram([{ ...data }]);
        return crumbData;
      });
    } else {
      setBreadCrumb(routeConfig);
    }
  }, [pathname, userInfo.permission]);
  const hasBreadcrumb = useMemo(() => {
    return (
      breadcrumb &&
      breadcrumb.every((item) => {
        return !navData.includes(item);
      })
    );
  }, [breadcrumb]);
  // 刷新重置折叠状态
  useEffect(() => {
    store.dispatch(changeFold(false));
  }, []);
  return (
    <Spin loading={loading} style={{ display: 'block' }}>
      <Layout className={styles.layout}>
        <div
          className={cs(styles['layout-navbar'], {
            [styles['layout-navbar-hidden']]: !showNavbar,
          })}
        >
          <Navbar show={showNavbar} />
        </div>
        <Layout>
          {showMenu && (
            <Sider
              className={styles['layout-sider']}
              width={menuWidth}
              collapsed={collapsed}
              onCollapse={(value) => {
                store.dispatch(changeFold(value));
                setCollapsed(value);
              }}
              onBreakpoint={(value) => {
                console.log(value);
              }}
              trigger={null}
              collapsible
              breakpoint="xl"
              style={paddingTop}
              collapsedWidth={54}
            >
              <div className={styles['menu-wrapper']}>
                {/* {defaultOpenKeys && defaultOpenKeys.length ? ( */}
                <Menu
                  collapse={collapsed}
                  onClickMenuItem={onClickMenuItem}
                  selectedKeys={selectedKeys}
                  defaultSelectedKeys={['home']}
                  defaultOpenKeys={defaultOpenKeys}
                  // openKeys={openKeys}
                  onClickSubMenu={(_, openKeys) => {
                    setOpenKeys(openKeys);
                  }}
                  levelIndent={20}
                >
                  {renderRoutes()}
                </Menu>
                {/* ) : null} */}
              </div>
              <div className={styles['collapse-btn']} onClick={toggleCollapse}>
                {collapsed ? <IconMenuUnfold /> : <IconMenuFold />}
              </div>
            </Sider>
          )}
          <Layout className={styles['layout-content']} style={paddingStyle}>
            <div className={styles['layout-content-wrapper']}>
              {Array.isArray(breadcrumb) && breadcrumb.length > 0 ? (
                <div
                  className={`${styles['layout-breadcrumb']} ${
                    !hasBreadcrumb ? styles['layout-index-breadcrumb'] : ''
                  }`}
                >
                  {/* <Breadcrumb separator={<IconRight />}> */}
                  <Breadcrumb>
                    {breadcrumb.map((node, index) => (
                      <Breadcrumb.Item key={index}>{node}</Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </div>
              ) : null}
              <Content>
                <Switch>
                  {flattenSelfRoutes.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        path={`/${route.key}`}
                        component={route.component}
                      />
                    );
                  })}
                  <Route exact path="/">
                    <Redirect to={`/${defaultRoute}`} />
                  </Route>
                  <Route
                    path="*"
                    component={lazyload(() => import('./pages/exception/403'))}
                  />
                </Switch>
              </Content>
            </div>
            {/* {showFooter && <Footer />} */}
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  );
}

export default PageLayout;
