import request from '@/utils/request';

export function getGImageList(params) {
  return request({
    url: '/api/images/page/type',
    method: 'post',
    data: { ...params, pictureClassify: 3 },
  });
}
export function addGImage(data) {
  return request({
    url: `/api/images/classify/save`,
    method: 'post',
    data,
  });
}

export function gimageDetail(id) {
  return request({
    url: `/api/images/classify/preEdit/${id}`,
    method: 'post',
  });
}

export function gimageEdit(data) {
  return request({
    url: `/api/images/classify/edit`,
    method: 'post',
    data,
  });
}
