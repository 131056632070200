import React, { ReactNode, useEffect, useState } from 'react';
import CustomPreview from './CustomPreview';
import ImageSelect from '@/components/ImageSelect';

type PropsType = {
  onChange?: (fileList: string) => void;
  value?: string;
  children?: ReactNode;
};

const CustomUpload: React.FC<PropsType> = ({ onChange, value, children }) => {
  const [imageSelectVisible, setImageSelectVisible] = useState<boolean>(false);
  const [file, setFile] = useState({
    url: value,
  });
  const onDelete = () => {
    setFile({ url: '' });
    onChange && onChange(null);
  };
  const handleOk = (data: any) => {
    const imagePath =
      Array.isArray(data) && data.length > 0 && data[0].picturePath;
    setImageSelectVisible(false);
    onChange && onChange(imagePath);
  };
  const onCancel = () => {
    console.log('取消');
    setImageSelectVisible(false);
  };
  useEffect(() => {
    setFile({ url: value });
  }, [value]);
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setImageSelectVisible(true);
        }}
      >
        {value ? <CustomPreview onDelete={onDelete} url={value} /> : children}
      </div>
      <ImageSelect
        imageSelectVisible={imageSelectVisible}
        type={'image'}
        handleOk={handleOk}
        onCancel={onCancel}
        tableType={'radio'}
      />
    </>
  );
};

export default CustomUpload;
