import { Route } from '@/comonType/routerType';
import lazyload from '@/utils/lazyload';

// 分享链接管理路由
const routes: Route[] = [
  {
    name: '分享链接管理',
    router: 'ShareLinkManage',
    component: lazyload(() => import('@/pages/ShareLinkManage/index')),
  },
];

export default routes;
