import request from '@/utils/request';
export interface ResType {
  code?: string;
  data?: any;
  msg?: string;
}

//词条查询分页列表
export function getGlossaryList(data) {
  return request({
    url: '/api/glossary/list',
    method: 'post',
    data,
  });
}

//词条标签列表
export function getGlossaryTagList(keyword?: string) {
  return request({
    url: '/api/glossary/label/list',
    method: 'get',
    params: { keyword },
  });
}

// 删除词条
export function delGlossaryItem(data) {
  return request({
    url: `/api/glossary/delete?id=${data.id}`,
    method: 'get',
  });
}

//词条标签修改编辑删除
export function postGlossaryTagEdit(data) {
  return request({
    url: '/api/glossary/label/save',
    method: 'post',
    data,
  });
}

// 前台glossary详情
export function getGlossaryDetail(id) {
  return request({
    url: `/api/glossary/detail?id=${id}`,
    method: 'get',
  });
}

//新增 glossary详情
export function postAddGlossaryDetail(data) {
  return request({
    url: '/api/glossary/add',
    method: 'post',
    data,
  });
}

//编辑 glossary详情
export function postEditGlossaryDetail(data) {
  return request({
    url: '/api/glossary/edit',
    method: 'post',
    data,
  });
}

//列表 前台状态控制
export function postEditIsShow(data) {
  return request({
    url: '/api/glossary/show',
    method: 'post',
    data,
  });
}

//列表 topSearch控制
export function postEditIsTopSearch(data) {
  return request({
    url: '/api/glossary/topSearches',
    method: 'post',
    data,
  });
}

//添加时候获取TopSearches排序
export function getDefalutTopSearchesNum() {
  return request({
    url: '/api/glossary/add/getDefalutTopSearchesNum',
    method: 'get',
  });
}

//上传
export function uploadGlossaryList(data: FormData) {
  return request({
    url: '/api/glossary/upload',
    method: 'post',
    data,
  });
}

//下载
export function downloadGlossaryList(data) {
  return request({
    url: '/api/glossary/download',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
