// 文档模版编辑
import { Route } from '@/comonType/routerType';
import lazyLoad from '@/utils/lazyload';

//
const routes: Route[] = [
  {
    name: '模版详情',
    router: 'Resource/DocumentManager/PdfTranslateWeb',
    component: lazyLoad(
      () => import('@/pages/Resource/DocumentManager/PdfTranslateWeb')
    ),
  },
  {
    name: 'Datasheet编辑管理',
    router: 'Resource/DocumentManager/Datasheet',
    component: lazyLoad(
      () => import('@/pages/Resource/DocumentManager/Datasheet')
    ),
  },
];
export default routes;
