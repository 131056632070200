import React, { useEffect } from 'react';
import api from '@/api/resource';
import { Cascader } from '@arco-design/web-react';
type PropsType = React.ComponentProps<typeof Cascader>;
type CategoryItem = {
  params?: Record<string, any>; //列表请求参数
  onChange?: (value: any) => void;
  pushData?: Record<string, any>;
  getData?: (data: any) => void;
} & PropsType;
const CategoryFilter: React.FC<CategoryItem> = ({
  params = {
    categoriesType: 1,
  },
  onChange,
  pushData,
  getData,
  ...rest
}) => {
  const [categories, setCategories] = React.useState([]);
  useEffect(() => {
    api.selectTreeNodeList(params).then((res) => {
      if (pushData) {
        console.log([...res.data, pushData]);
        getData && getData([...res.data, pushData]);
        setCategories([...res.data, pushData]);
      } else {
        setCategories(res.data);
        getData && getData(res.data);
      }
    });
  }, []);
  return (
    <Cascader
      size="large"
      changeOnSelect
      allowClear
      expandTrigger="hover"
      options={categories}
      onChange={(value) => {
        onChange && onChange(value);
      }}
      {...rest}
    />
  );
};

export default CategoryFilter;
