import { FilterParams } from '@/enum/FilterList';
import request from '@/utils/request';

// 视频资源列表
export interface videoParamsData extends FilterParams {
  languageId: number | string; // 站点语言ID
  currentCategoryId: number; // 筛选分类ID
  searchParam?: string; //	查询参数
  current: number; // 当前页
  size: number; // 每页条数
  startTime: string;
  endTime: string;
  nickname: string;
  isPop?: number;
  tagId?: number;
  videoCategories?: number; //视频类型
  translateStatus?: number;
}
export function getVideoList(data) {
  return request({
    url: '/api/videos/list',
    method: 'post',
    data,
  });
}
export function addVideo(data) {
  return request({
    url: '/api/videos/create',
    method: 'post',
    data,
  });
}
export function editVideo(data) {
  return request({
    url: `/api/videos/update/${data.id}`,
    method: 'post',
    data,
  });
}
export function delVideo(data) {
  return request({
    url: `/api/videos/delete`,
    method: 'post',
    data,
  });
}
export function selectTreeNodeList(data) {
  return request({
    url: '/api/categories/selectTreeNodeList',
    method: 'post',
    data,
  });
}
export function getUserList() {
  return request({
    url: '/api/videos/user/lists',
    method: 'get',
  });
}
export function association(params) {
  return request({
    url: '/api/videos/association',
    method: 'get',
    params,
  });
}
export function checkExistMainPic(params) {
  return request({
    url: '/api/videos/checkExistMainPic',
    method: 'get',
    params,
  });
}

// 视频标签列表查询
export function selectVideoPageList(data) {
  return request(
    {
      url: '/api/newVideoTag/list',
      method: 'post',
      data,
    },
    true
  );
}

// 视频标签详情查询
export function selectDetail(params) {
  return request({
    url: '/api/newVideoTag/tagDetail',
    method: 'get',
    params,
  });
}

// 视频标签保存
export function tagSave(data) {
  return request({
    url: '/api/newVideoTag/save',
    method: 'post',
    data,
  });
}

// 视频标签删除
export function tagDelete(data) {
  return request({
    url: '/api/videoTag/delete',
    method: 'post',
    data,
  });
}

// 视频标签下拉列表
export function tagList() {
  return request({
    url: '/api/videoTag/list',
    method: 'post',
  });
}

//媒体中心主视频添加
// {
//   "createdUserId": 0,
//   "videos": []
// }
export function addVideoMain(data) {
  return request({
    url: '/api/mediaCenterMainVideo/create',
    method: 'post',
    data,
  });
}

//批量修改排序
// {
//   "sortVideos": [
//     {
//       "id": 0,
//       "sort": 0
//     }
//   ]
// }
export function updateSort(data) {
  return request({
    url: '/api/videos/batchModifySort',
    method: 'post',
    data,
  });
}

// 视频标签下拉数据
export function tagSelectList() {
  return request({
    url: '/api/newVideoTag/group/list',
    method: 'get',
  });
}

// 媒体中心主视频列表

export function mediaCenterMainVideoList() {
  return request({
    url: '/api/mediaCenterMainVideo/list',
    method: 'get',
  });
}

// 标签删除
export function deleteTag(data) {
  return request({
    url: `/api/newVideoTag/delete/${data.id}`,
    method: 'delete',
  });
}

// 批量删除
export function batchDeleteTag(data) {
  return request({
    url: '/api/newVideoTag/batch/delete',
    method: 'delete',
    data,
  });
}
