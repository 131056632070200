import request from '@/utils/request';

// blog分类新增
export function blogCategoryAdd(data) {
  return request({
    url: '/api/blog/blogCategory/add',
    method: 'post',
    data,
  });
}
// blog分类编辑
export function blogCategoryEdit(data) {
  return request({
    url: '/api/blog/blogCategory/edit',
    method: 'post',
    data,
  });
}
// 删除blog分类
export function blogCategoryDelete(data) {
  return request({
    url: '/api/blog/blogCategory/del',
    method: 'post',
    data,
  });
}
// 排序
export function blogCategorySort(data) {
  return request({
    url: '/api/blog/sort/edit',
    method: 'post',
    data,
  });
}

// 新增标签
export function blogTagAdd(data) {
  return request({
    url: '/api/blog/tag/add',
    method: 'post',
    data,
  });
}
// 编辑标签
export function blogTagEdit(data) {
  return request({
    url: '/api/blog/tag/edit',
    method: 'post',
    data,
  });
}
// 删除标签
export function blogTagDelete(data) {
  return request({
    url: '/api/blog/tag/del',
    method: 'post',
    data,
  });
}
// 文章下载
export function blogDownload(data) {
  return request(
    {
      responseType: 'blob',
      url: '/api/blog/article/downloadExcel',
      method: 'post',
      data,
    },
    true
  );
}

// 分类下载
export function blogCategoryDownload(data) {
  return request(
    {
      responseType: 'blob',
      url: '/api/blog/blogCategory/downloadExcel',
      method: 'post',
      data,
    },
    true
  );
}
// 标签下载
export function blogTagDownload(data) {
  return request(
    {
      responseType: 'blob',
      url: '/api/blog/tag/downloadExcel',
      method: 'post',
      data,
    },
    true
  );
}

// 上传标签excel
export function blogTagUpload(data) {
  return request(
    {
      url: '/api/blog/tag/uoloadTagExcel',
      method: 'post',
      data,
    },
    true
  );
}

// 批量删除blog标签
export function blogTagBatchDelete(data) {
  return request({
    url: '/api/blog/tag/delAll',
    method: 'post',
    data,
  });
}
